import {
  Button,
  FormRow,
  FormSet,
  InfoCard,
  Input,
  Link,
  P,
} from '@dnb/eufemia';
import { Field } from '@dnb/eufemia/extensions/forms';
import { log_in_medium as LogInMediumIcon } from '@dnb/eufemia/icons';
import {
  useEufemiaForm,
  useLocalStorage,
} from '@portals/shared-frontend/hooks';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { login, type User } from '@/auth/cognito';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import ModalPage from '@/components/ModalPage';
import useAuth from '@/hooks/useAuth';
import useReturnTo from '@/hooks/useReturnTo';

import VerifyMfa from './VerifyMfa';
import VerifyRecoveryCode from './VerifyRecoveryCode';

const loginCredentialsFormSchema = z.object({
  username: z.string().email(),
  password: z.string().min(1),
});

const loginMessagePaths = [
  '/profile',
  '/team',
  '/application',
  '/subscriptions/manage',
  '/documentation',
];

const publicApiPaths = [
  '/documentation/currencies',
  '/documentation/psd2-accounts',
  '/documentation/psd2-payments',
  '/documentation/psd2-fallback',
];

const isPublicDocPath = (returnToPath: string) => {
  return publicApiPaths.some((publicPath) => returnToPath.includes(publicPath));
};

export default function Login(): JSX.Element {
  const [lastLoggedInEmail, setLastLoggedInEmail] = useLocalStorage(
    `last-logged-in-internal-user-email`,
    '',
  );

  const navigate = useNavigate();
  const [returnTo, , clearReturnTo] = useReturnTo();

  const isRedirectedFromAuthenticatedPage =
    returnTo &&
    returnTo !== '/' &&
    loginMessagePaths.some(
      (path) => !isPublicDocPath(returnTo) && returnTo.startsWith(path),
    );

  const [ssoLogin, setSsoLogin] = useState<boolean>(false);

  const [user, setUser] = useState<User>();
  const [verificationStep, setVerificationStep] = useState<
    'login' | 'recovery-code' | 'authenticator'
  >('login');

  const {
    controller,
    controller: { setFormError, formError, setValue },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(loginCredentialsFormSchema, {
    username: lastLoggedInEmail,
  });

  useEffect(() => {
    if (controller.values.username?.endsWith('@dnb.no')) {
      // TODO: Check if AD is enabled for the different domains
      setSsoLogin(true);
      return;
    }
    setSsoLogin(false);
  }, [controller.values.username]);

  const onSubmit = handleSubmit(
    useCallback(
      async ({ username, password }) => {
        try {
          setLastLoggedInEmail('');
          const user: User = await login({ username, password });

          if (user.challengeName === 'CUSTOM_CHALLENGE') {
            setUser(user);
            setVerificationStep('authenticator');
            return;
          }

          navigate(returnTo ?? '/');
          clearReturnTo();
        } catch (error) {
          if (!hasOwnProperty(error, 'code')) {
            throw error;
          }

          switch (error.code) {
            case 'NotAuthorizedException': {
              setFormError('Invalid email or password');
              break;
            }
            default: {
              throw error;
            }
          }
        }
      },
      [navigate, returnTo, clearReturnTo, setFormError],
    ),
  );

  const onClose = () => {
    if (isRedirectedFromAuthenticatedPage) {
      navigate('/');
      return clearReturnTo();
    }
    navigate(returnTo && returnTo !== '/login' ? returnTo : '/');
  };

  const { isSignedIn } = useAuth();

  useEffect(() => {
    if (isSignedIn) {
      navigate(returnTo ?? '/');
    }
  }, [isSignedIn, navigate, returnTo]);

  if (verificationStep === 'authenticator') {
    return (
      <VerifyMfa
        cognitoUser={user}
        toggleView={() => setVerificationStep('recovery-code')}
      />
    );
  }

  if (verificationStep === 'recovery-code') {
    return (
      <VerifyRecoveryCode
        cognitoUser={user}
        toggleView={() => setVerificationStep('authenticator')}
      />
    );
  }

  const title = ssoLogin
    ? 'Email address please'
    : 'Email address & password please';

  return (
    <ModalPage onClose={onClose} subtitle="Log in" title={title}>
      {submitting && <LoadingModal />}
      <Container size="small" top="small">
        {isRedirectedFromAuthenticatedPage && (
          <InfoCard bottom="medium" text="You need to login to see this page" />
        )}
        <FormSet on_submit={onSubmit} vertical>
          <FormRow>
            <Input
              autoFocus
              label="Email address"
              placeholder="example@mail.com"
              size="large"
              stretch
              {...register.input('username')}
            />
          </FormRow>
          {ssoLogin ? (
            <FormRow top="medium">
              <Button
                disabled={submitting}
                icon={LogInMediumIcon}
                icon_position="left"
                icon_size="medium"
                on_click={() => navigate('/sso')}
                size="large"
                status={formError}
                type="submit"
              >
                Log in with DNB account
              </Button>
            </FormRow>
          ) : (
            <>
              <FormRow top="medium">
                <Field.Password
                  label="Password"
                  onChange={(value) => setValue('password', value)}
                  size="large"
                  width="stretch"
                />
              </FormRow>

              <P top="medium">
                Forgot password?{' '}
                <Link element={RouterLink} to="/login/forgotten-password">
                  Get a new one here
                </Link>
              </P>

              <FormRow top="large">
                <Button
                  disabled={submitting}
                  icon={LogInMediumIcon}
                  icon_position="left"
                  icon_size="medium"
                  size="large"
                  status={formError}
                  type="submit"
                >
                  Log in
                </Button>
              </FormRow>

              <P top="medium">
                Do not have an account?{' '}
                <Link element={RouterLink} to="/sign-up">
                  Sign up
                </Link>
              </P>
            </>
          )}
        </FormSet>
      </Container>
    </ModalPage>
  );
}
