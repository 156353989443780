import { isSignedInWithAzure, logoutWithAzure } from './azure';
import { logoutCognito } from './cognito';

export async function logout() {
  if (isSignedInWithAzure()) {
    logoutWithAzure();
    return;
  } else {
    await logoutCognito();
  }
  window.location.href = '/';
}
