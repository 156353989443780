/* eslint-disable max-lines */
import {
  Dialog,
  Div,
  Drawer,
  FormStatus,
  H2,
  P,
  Space,
  Span,
} from '@dnb/eufemia';
import { edit as EditIcon, trash as TrashIcon } from '@dnb/eufemia/icons';
import { CurrentApiAndScopeEntitlementsDto } from '@portals/shared/portal/AppApiEntitlementDto';
import type {
  CiamClientDto,
  ClientDto,
} from '@portals/shared/portal/ClientDto';

import Card from '@/components/Card';

import ClientEdit from '../ClientEdit';

import style from './index.module.css';

export type ClientProps = {
  isAdmin: boolean;
  client: ClientDto;
  ciamClient?: CiamClientDto;
  onDeleteClicked: () => void;
  onDeleteFromPortalClicked?: (() => void) | null;
  currentApiAndScopeEntitlements: CurrentApiAndScopeEntitlementsDto[];
  ciamClientsLoading: boolean;
  onClientUpdated: () => void;
};

export default function Client({
  isAdmin,
  client,
  onDeleteFromPortalClicked,
  ciamClient,
  currentApiAndScopeEntitlements,
  ciamClientsLoading,
  onClientUpdated,
}: ClientProps): JSX.Element | null {
  const totalScopes = currentApiAndScopeEntitlements.reduce(
    (acc, curr) =>
      acc +
      curr.scopeEntitlements.filter(
        (scopeEnt) => scopeEnt.liveMode === client.liveMode,
      ).length,
    0,
  );

  return (
    <Card className={style['ClientTile']} key={client.clientId}>
      <Space className={style['ClientTileContent']}>
        <Space>
          <P bottom="x-small">{client.name || 'Client ID'}</P>
          <Span className={style['Subtext']}>{client.clientId}</Span>{' '}
        </Space>
      </Space>
      <Div className={style['ClientTileContent']}>
        {ciamClient?.scopes && totalScopes !== ciamClient?.scopes.length && (
          <FormStatus
            bottom="0"
            state="warn"
            text={`${ciamClient?.scopes.length} of ${totalScopes} scopes selected`}
            top="0"
          />
        )}

        <Drawer
          triggerAttributes={{
            text: 'Edit',
            icon: EditIcon,
            variant: 'tertiary',
            icon_position: 'left',
          }}
        >
          <Drawer.Header>
            <H2>{client.name}</H2>
            <P>{client.clientId}</P>
            <P>{client.description}</P>
            {isAdmin && (
              <Div className={style['ClientTileContent']} top="small">
                {onDeleteFromPortalClicked && (
                  <Dialog
                    confirmText="Delete from portal"
                    confirmType="warning"
                    declineText="Cancel"
                    description="This action will not impact client in ciam."
                    icon={TrashIcon}
                    onConfirm={({ close }) => {
                      close();
                      onDeleteFromPortalClicked();
                    }}
                    title="Are you sure you want to delete this client from portal? It will still keep the client in ciam system."
                    triggerAttributes={{
                      text: 'Delete from portal',
                      icon: TrashIcon,
                      variant: 'tertiary',
                    }}
                    variant="confirmation"
                  />
                )}
              </Div>
            )}
          </Drawer.Header>
          <Drawer.Body>
            <ClientEdit
              ciamClient={ciamClient}
              ciamClientsLoading={ciamClientsLoading}
              client={client}
              currentApiAndScopeEntitlements={currentApiAndScopeEntitlements}
              isAdmin={isAdmin}
              onClientUpdated={() => onClientUpdated()}
            />
          </Drawer.Body>
        </Drawer>
      </Div>
    </Card>
  );
}
