import { H1, P, Section, Skeleton, Space } from '@dnb/eufemia';
import type { SectionSpacing } from '@dnb/eufemia/components/Section';
import classNames from 'classnames';
import { type PropsWithChildren, type ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useReturnTo from '@/hooks/useReturnTo';

import Container from '../Container';
import Footer from '../Footer';
import MenuBar from '../MenuBar';

import style from './index.module.css';

type PageProps = PropsWithChildren<{
  title: ReactNode;
  subtitle?: ReactNode;
  illustration?: ReactNode;
  description?: ReactNode;
  component?: ReactNode;
  skeleton?: boolean;
  noContainer?: boolean;
  styleType?: string;
  spacing?: SectionSpacing;
}>;

export default function Page({
  title,
  subtitle,
  illustration,
  description,
  children,
  component,
  skeleton = false,
  noContainer = false,
  styleType = 'white',
  spacing = 'xx-large',
}: PageProps): JSX.Element {
  const location = useLocation();
  const setReturnTo = useReturnTo()[1];

  useEffect(() => {
    setReturnTo(location.pathname);
  }, [location.pathname, setReturnTo]);

  return (
    <div
      className={classNames({
        [style['Page']]: true,
        [style['Page--illustrated']]: !!illustration,
      })}
    >
      <Section style_type="mint-green-25">
        <MenuBar />
      </Section>
      <Skeleton className={style['SkeletonWrapper']} show={skeleton}>
        <Section spacing="xx-large" style_type="mint-green-25">
          <Container centered className={style['HeaderContent']}>
            <div className={style['HeaderText']}>
              <H1>
                {subtitle && (
                  <span className={style['Subtitle']}>{subtitle}</span>
                )}
                {title}
              </H1>
              {description &&
                (typeof description === 'string' ? (
                  <P top="large">{description}</P>
                ) : (
                  <Space top="large">{description}</Space>
                ))}
              {component}
            </div>
            <div className={style['HeaderIllustration']}>{illustration}</div>
          </Container>
        </Section>
        <Section
          className={style['Content']}
          spacing={spacing}
          style_type={styleType}
        >
          {noContainer ? children : <Container centered>{children}</Container>}
        </Section>
      </Skeleton>
      <Footer />
    </div>
  );
}
