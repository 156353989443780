import { GlobalError } from '@dnb/eufemia';
import { exclamation_triangle_medium as ExclamationTriangleMediumIcon } from '@dnb/eufemia/icons';
import type { UserDetailsDto } from '@portals/shared/portal/UserDto';
import { ErrorContext } from '@portals/shared-frontend/hooks';
import { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { postUserLogin } from './api/user';
import AlertBanner from './components/AlertBanner';
import OrganizationNotificationAlert from './components/OrganizationNotificationAlert';
import TermsOfUseAlert from './components/TermsOfUseAlert';
import { CELEBRUS_LOGIN_STATE_KEY, PORTAL_LOGIN_STATE_KEY } from './constants';
import useAuth from './hooks/useAuth';
import useFeatureFlags from './hooks/useFeatureFlags';
import UserContext from './hooks/UserContext';
import { request } from './request';

function pushUserLoginToCelebrus(userId?: string) {
  window.dnbDataLayer?.push({
    event: 'GenericEvent',
    event_type: 'Status',
    event_action: 'User State Change',
    event_name: 'Logged in (authenticated)',
    event_value: window.location.pathname,
  });

  window.dnbDataLayer?.push({
    event: 'UserAuthenticated',
    user: {
      user_id: `dnb:devportal:${userId}}`,
      user_consent: '1',
      profile: {
        profile_id: `dnb:devportal:${userId}}`,
        role: 'personal',
      },
    },
  });
}

export default function App(): JSX.Element {
  const { isSignedIn, waiting: sessionLoading } = useAuth();
  const { pathname } = useLocation();
  const [error, setError] = useState<unknown>();
  const [user, setUser] = useState<UserDetailsDto>();
  const [userLoading, setUserLoading] = useState(true);
  const { featureFlags, isLoading: loadingFlags } = useFeatureFlags();

  const showOrganizationNotificationAlert =
    !!user &&
    !!user.organizationName &&
    !user.organizationAcknowledged &&
    !pathname.includes('/help-center');

  const showTermsOfUsePopup =
    user?.latestTermsOfUseAccepted === false && pathname !== '/terms-of-use';
  const location = useLocation();

  useEffect(() => {
    window.dnbDataLayer ??= [];
    window?.dnbDataLayer?.push({
      event: 'PageLoaded',
      page: {},
    });
  }, [location]);

  const loadUser = useCallback(() => {
    if (isSignedIn) {
      setUserLoading(true);
      request<UserDetailsDto>('/user', 'get')
        .then(async (user) => {
          setUser(user);
          setUserLoading(false);

          const hasPushedLoginToCelebrus = sessionStorage.getItem(
            CELEBRUS_LOGIN_STATE_KEY,
          );
          const hasPushedLoginToPortal = sessionStorage.getItem(
            PORTAL_LOGIN_STATE_KEY,
          );
          if (!hasPushedLoginToCelebrus) {
            pushUserLoginToCelebrus(user.id);
            sessionStorage.setItem(CELEBRUS_LOGIN_STATE_KEY, 'true');
          }
          if (!hasPushedLoginToPortal) {
            await postUserLogin();
            sessionStorage.setItem(PORTAL_LOGIN_STATE_KEY, 'true');
          }
          return;
        })
        .catch(setError);
    } else {
      setUserLoading(false);
      setUser(undefined);
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (!sessionLoading) {
      loadUser();
    }
  }, [loadUser, sessionLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setError(null);
  }, [pathname]);

  if (error) {
    return <GlobalError status="500" />;
  }

  return (
    <ErrorContext.Provider value={[error, setError]}>
      <UserContext.Provider
        value={{
          user,
          isLoading: sessionLoading || userLoading,
          reload: loadUser,
        }}
      >
        {showOrganizationNotificationAlert && <OrganizationNotificationAlert />}
        {showTermsOfUsePopup && <TermsOfUseAlert />}
        {featureFlags.ENABLE_READ_ONLY_MODE && !loadingFlags && (
          <AlertBanner icon={ExclamationTriangleMediumIcon}>
            Oops! We are in the middle of migration right now and will be done
            very soon! In the meantime you can browse the portal in read-only
            mode.
          </AlertBanner>
        )}
        <Outlet />
      </UserContext.Provider>
    </ErrorContext.Provider>
  );
}
