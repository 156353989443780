import { Avatar, Button, Card, Flex, HeightAnimation, P } from '@dnb/eufemia';
import type { SubscriberDetailsDto } from '@portals/shared/portal/SubscriptionDto';
import { useState } from 'react';

import style from './index.module.css';

type SubscribersContainerProps = {
  subscribers: SubscriberDetailsDto[];
  title: string;
};

export function SubscribersContainer({
  subscribers,
  title,
}: SubscribersContainerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const chunk1 = subscribers.length > 6 ? subscribers.slice(0, 6) : subscribers;
  const chunk2 = subscribers.length > 6 ? subscribers.slice(6) : null;

  return (
    <Card className={style['Card']} stack>
      <P bold>{title}</P>
      <>
        <Flex.Container rowGap>
          {chunk1.length > 0 ? (
            chunk1.map(({ email, id }) => (
              <Flex.Item
                key={id}
                size={
                  chunk1.length < 4 // Eufemia does not handle spacing well with less than 4 items
                    ? undefined
                    : { small: 12, medium: 6, large: 4 }
                }
              >
                <Avatar right="x-small">@</Avatar>
                {email}
              </Flex.Item>
            ))
          ) : (
            <P>No one is subscribed to new releases of this API.</P>
          )}
        </Flex.Container>
        {chunk2 && chunk2.length > 0 && (
          <HeightAnimation open={isOpen}>
            <Flex.Container rowGap>
              {chunk2.map(({ email, id }) => (
                <Flex.Item
                  key={id}
                  size={{
                    small: 12,
                    medium: 6,
                    large: 4,
                  }}
                >
                  <Avatar right="x-small">@</Avatar>
                  {email}
                </Flex.Item>
              ))}
            </Flex.Container>
          </HeightAnimation>
        )}
        {chunk2 && (
          <Button
            className={style['ExpandButton']}
            icon={isOpen ? 'chevron-up' : 'chevron-down'}
            on_click={() => setIsOpen(!isOpen)}
            size="default"
            title="Expand subscribers"
          />
        )}
      </>
    </Card>
  );
}
