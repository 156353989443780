import ApiPage from '../../components/ApiPage';
import { API_EXPLORER_TABS } from '../../content';

export default function ReviewApis() {
  return (
    <ApiPage
      apiType={API_EXPLORER_TABS.Review}
      showApiCategoryFilter
      title="Review APIs"
    />
  );
}
