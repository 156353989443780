import { Button, Dialog, Div, Heading, Li, Space } from '@dnb/eufemia';
import { Skeleton } from '@dnb/eufemia';
import {
  close_medium as CloseMediumIcon,
  hamburger_medium as HamburgerMediumIcon,
  log_in_medium as LogInMediumIcon,
  log_out_medium as LogOutMediumIcon,
  profile_medium as ProfileMediumIcon,
} from '@dnb/eufemia/icons';
import classNames from 'classnames';
import { type PropsWithChildren, useMemo } from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { logout } from '@/auth/helpers';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useReturnTo from '@/hooks/useReturnTo';
import { useUser } from '@/hooks/useUser';

import Container from '../Container';
import { DeveloperOriginalLogo } from '../DeveloperLogo';
import NavigationBar from '../NavigationBar';

import style from './index.module.css';

interface MenuLink {
  title: string;
  to: string;
  underline?: boolean;
}

interface MenuSectionProps {
  title: string;
  links: MenuLink[];
}

function MenuSection({
  title,
  links,
  close,
}: PropsWithChildren<MenuSectionProps> & { close: () => void }): JSX.Element {
  const navigate = useNavigate();
  return (
    <Div top="large">
      <Heading size="medium">{title}</Heading>
      <ul className="dnb-ul dnb-unstyled-list">
        {links.map((link) => (
          <Li key={link.to} top="small">
            <a
              className={classNames({
                'dnb-anchor': true,
                'dnb-anchor--no-underline': !link.underline,
              })}
              onClick={(event) => {
                event.preventDefault();
                close();
                navigate(link.to);
              }}
            >
              {link.title}
            </a>
          </Li>
        ))}
      </ul>
    </Div>
  );
}

function MenuHeader(): JSX.Element {
  const { user } = useUser();
  const [, setReturnTo] = useReturnTo();

  if (!user) {
    return (
      <div className={style['MenuHeader']}>
        <Heading size="medium">Start creating apps by logging in</Heading>
        <Button
          element={RouterLink}
          icon={LogInMediumIcon}
          icon_position="left"
          icon_size="medium"
          on_click={() => {
            setReturnTo(location.pathname);
          }}
          size="large"
          to="/login"
          top="medium"
        >
          Log in
        </Button>
      </div>
    );
  }

  return (
    <div className={style['MenuHeader']}>
      <Heading size="medium">
        {user.firstName} {user.lastName}
      </Heading>
      <Button
        element={RouterLink}
        icon={ProfileMediumIcon}
        icon_position="left"
        icon_size="medium"
        right="small"
        size="large"
        to="/profile"
        top="medium"
      >
        My profile
      </Button>
      <Button
        icon={LogOutMediumIcon}
        icon_position="left"
        icon_size="medium"
        on_click={logout}
        size="large"
        top="medium"
        variant="secondary"
      >
        Log out
      </Button>
    </div>
  );
}

function Menu(): JSX.Element {
  const [open, setOpen] = useState(false);
  const closeMenu = () => {
    setOpen(false);
  };
  const { featureFlags } = useFeatureFlags();

  const sections: MenuSectionProps[] = useMemo(() => {
    const DNB_Developer = {
      title: 'DNB Developer',
      links: [
        {
          title: 'Getting started guide',
          to: '/getting-started',
        },
        {
          title: 'API explorer',
          to: '/explorer/apis',
        },
        {
          title: 'News',
          to: '/news',
        },
        {
          title: 'Digital channel statistics',
          to: '/channel-statistics',
        },
      ],
    };

    const moreLinks = [];
    if (featureFlags.ENABLE_COMMON_REPO_API) {
      moreLinks.push({
        title: 'API contracts',
        to: '/api-contracts',
      });
    }
    if (featureFlags.ENABLE_SCOPE_OVERVIEW) {
      moreLinks.push({
        title: 'Scope overview',
        to: '/scope-overview',
      });
    }

    if (moreLinks.length > 0) {
      DNB_Developer.links.splice(2, 0, ...moreLinks);
    }

    const Documentation = {
      title: 'Documentation',
      links: [
        {
          title: 'Account Information Service',
          to: '/documentation/psd2-accounts/@default/@latest',
        },
        {
          title: 'Payment Initiation Service',
          to: '/documentation/psd2-payments/@default/@latest',
        },
        {
          title: 'Currencies',
          to: '/documentation/currencies/@default/@latest',
        },
        {
          title: 'More APIs',
          to: '/explorer/apis',
          underline: true,
        },
      ],
    };
    const Support = {
      title: 'Support',
      links: [
        {
          title: 'API status',
          to: '/status',
        },
        {
          title: 'Help Center',
          to: '/help-center',
        },
        {
          title: 'Faq',
          to: '/help-center/faq',
        },
      ],
    };
    return [DNB_Developer, Documentation, Support];
  }, [featureFlags]);

  return (
    <Dialog
      fullscreen
      headerContent={
        <Container centered>
          <Button
            icon={CloseMediumIcon}
            icon_position="top"
            icon_size={24}
            on_click={closeMenu}
            size="large"
            top="medium"
            variant="tertiary"
          >
            Close
          </Button>
        </Container>
      }
      hideCloseButton
      modalContent={
        <Space top="large">
          <Container centered>
            <MenuHeader />
            <Div className={style['MenuLinks']} top="medium">
              {sections.map((section) => (
                <MenuSection
                  close={closeMenu}
                  key={section.title}
                  {...section}
                />
              ))}
            </Div>
          </Container>
        </Space>
      }
      noAnimation
      openState={open}
      trigger={() => (
        <Button
          icon={HamburgerMediumIcon}
          icon_position="top"
          icon_size={24}
          on_click={() => setOpen(true)}
          size="large"
          variant="tertiary"
        >
          Menu
        </Button>
      )}
    />
  );
}

export default function MenuBar() {
  const { isLoading, isLoggedIn } = useUser();
  const [, setReturnTo] = useReturnTo();

  const { featureFlags, isLoading: featureFlagIsLoading } = useFeatureFlags();

  if (featureFlags.ENABLE_MENU_REDESIGN) return <NavigationBar />;

  return (
    <Skeleton show={featureFlagIsLoading}>
      <div className={style['MenuBar']}>
        <Menu />
        <RouterLink className="dnb-anchor--no-style" to="/">
          <DeveloperOriginalLogo />
        </RouterLink>
        {isLoggedIn ? (
          <Button
            icon={LogOutMediumIcon}
            icon_position="top"
            on_click={logout}
            size="large"
            skeleton={isLoading || featureFlagIsLoading}
            variant="tertiary"
          >
            Log out
          </Button>
        ) : (
          <Button
            element={RouterLink}
            icon={LogInMediumIcon}
            icon_position="top"
            on_click={() => {
              setReturnTo(location.pathname);
            }}
            size="large"
            skeleton={isLoading || featureFlagIsLoading}
            to="/login"
            variant="tertiary"
          >
            Log in
          </Button>
        )}
      </div>
    </Skeleton>
  );
}
