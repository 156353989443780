import { Button, Dialog, FormRow, FormSet, Input, P } from '@dnb/eufemia';
import { email_medium as EmailMediumIcon } from '@dnb/eufemia/icons';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { createSubscriptions } from '@/api/subscriptions';
import Container from '@/components/Container';
import ModalPage from '@/components/ModalPage';
import { useUser } from '@/hooks/useUser';

const newsSubscriptionsFormSchema = z.object({
  email: z.string().email(),
});

export default function NewsSubscriptions(): JSX.Element {
  const { user } = useUser();
  const {
    register,
    controller: { setValue, getValue, formError },
    submitting,
    handleSubmit,
  } = useEufemiaForm(newsSubscriptionsFormSchema, {});
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (user?.email) {
      setValue('email', user.email);
    }
  }, [setValue, user?.email]);

  const onSubmit = handleSubmit(
    useCallback(async (data) => {
      await createSubscriptions({
        email: data.email,
        posts: true,
      });
      setOpen(true);
    }, []),
  );
  return (
    <ModalPage
      onClose={() => navigate('/news')}
      subtitle="Subscriptions"
      title="News"
    >
      <Container size="small">
        <FormSet on_submit={onSubmit} vertical>
          <FormRow>
            <P>
              Please fill in your email below to receive updates on new
              features, APIs and other updates.
            </P>
            <Input
              icon={EmailMediumIcon}
              placeholder="Email address"
              size="large"
              stretch
              top="medium"
              {...register.input('email')}
            />
          </FormRow>
          <FormRow top="small">
            <Button
              disabled={submitting}
              size="large"
              status={formError}
              type="submit"
            >
              Confirm
            </Button>
          </FormRow>
        </FormSet>
      </Container>
      <Dialog
        confirmText="Got it!"
        description={
          <P>
            We&apos;ve sent a confirmation email to {getValue('email')}. If you
            didn&apos;t receive it, check your spam filter.
          </P>
        }
        hideDecline
        onConfirm={() => navigate('/news')}
        openState={open}
        title="Success! An email is on its way"
        variant="confirmation"
      />
    </ModalPage>
  );
}
