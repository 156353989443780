import { Skeleton, Space } from '@dnb/eufemia';
import type { ContentDto } from '@portals/shared/portal/ContentDto';
import useSWR from 'swr';

import Container from '@/components/Container';
import Markdown from '@/components/Markdown';

type PageMarkdownContentType = {
  path?: string;
};

export default function PageMarkdownContent({ path }: PageMarkdownContentType) {
  const { data: content, isValidating: loading } = useSWR<ContentDto[]>(
    path || `/content${window.location.pathname}`,
  );

  return (
    <Skeleton show={!content && loading}>
      <Container>
        {content?.map((c) => (
          <Space bottom="large" key={c.id}>
            <Markdown>{c.blob.content}</Markdown>
          </Space>
        ))}
      </Container>
    </Skeleton>
  );
}
