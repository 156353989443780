export const enableApiStatus = (apiSlug: string, appId: string) => `
\`\`\`bash
curl --location \n \
--request PATCH 'https://api.prod.devportal.tech-03.net/v1/apis/${apiSlug}' \n \
--header 'X-DNBAPI-Trace-Id: ${appId}' \n \
--header 'Content-Type: application/json' \n \
--header 'x-app-id: ${appId}' \n \
--header 'x-api-key: {{api-key}}' \n \
--data '{
    "enableStatus": true
    }'
\`\`\`
`;

export const enableApiFaq = (apiSlug: string, appId: string) => `
\`\`\`bash
curl --location \n \
--request POST 'https://api.prod.devportal.tech-03.net/v1/faq/${apiSlug}/enable' \n \
--header 'X-DNBAPI-Trace-Id: ${appId}' \n \
--header 'x-app-id: ${appId}' \n \
--header 'x-api-key: {{api-key}}'
\`\`\`
`;
export const enableChangelogs = (
  apiSlug: string,
  appId: string,
  environmentSlug: string,
  version: string,
) => `
\`\`\`bash
curl --location --request PUT 'https://api.prod.devportal.tech-03.net/v1/apis/${apiSlug}/environments/${environmentSlug}/releases/${version}/changelog' \n \
--header 'X-DNBAPI-Trace-Id: ${appId}' \n \
--header 'Content-Type: application/json' \n \
--header 'x-app-id: ${appId}' \n \
--header 'x-api-key: {{apiKey}}' \n \
--data '{
    "added": ["<markdown>"],
    "changed": ["<markdown>"],
    "deprecated": ["<markdown>"],
    "removed": ["<markdown>"],
    "fixed": ["<markdown>"],
    "security": ["<markdown>"]
}'
\`\`\`
`;
