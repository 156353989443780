import { Icon } from '@dnb/eufemia';
import {
  check_medium as CheckMedium,
  copy_medium as CopyMedium,
} from '@dnb/eufemia/icons';
import { useCallback, useEffect, useRef, useState } from 'react';

import { CopyButton } from '@/components/Markdown/CopyButton/copybutton.styles';

interface CopyProps {
  code: string;
}

const CopyCode = ({ code = '' }: CopyProps) => {
  const [copied, setCopied] = useState(false);
  const copyTimeout = useRef<number | undefined>(undefined);

  const handleCopyCode = useCallback(() => {
    navigator.clipboard.writeText(code);

    setCopied(true);
    copyTimeout.current = window.setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [code]);

  useEffect(() => () => window.clearTimeout(copyTimeout.current), []);

  return (
    <CopyButton
      className={`copy${copied ? ' Copied' : ''}`}
      onClick={handleCopyCode}
    >
      <Icon
        className="copyIcon"
        color="var(--color-black-55)"
        icon={CopyMedium}
      />
      <Icon
        className="copySuccessIcon"
        color="green"
        icon={CheckMedium}
        size="large"
      />
    </CopyButton>
  );
};

export default CopyCode;
