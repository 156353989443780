import {
  Div,
  Dropdown,
  FormStatus,
  GlobalError,
  P,
  Section,
} from '@dnb/eufemia';
import type {
  AggregationProvider,
  AggregationProviderResponseDto,
  Channel,
  Config,
} from '@portals/shared/portal/AggregationProviderDto';
import type { ApiDto } from '@portals/shared/portal/ApiDto';
import type { ApiEnvironmentDto } from '@portals/shared/portal/ApiEnvironmentDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import { getApiMetadata } from '@/api/apiMetadata';
import AuthTemplate from '@/components/AuthTemplate';
import Container from '@/components/Container';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingModal from '@/components/LoadingModal';
import Page from '@/components/Page';
import { useUser } from '@/hooks/useUser';

import style from './AggregationProviders.module.css';

const EXTERNAL_ACCOUNTS_SLUG = 'external-accounts';
type DropdownOption<T> = { selected_key: T; content: string };

const channelsAggregation: DropdownOption<Channel>[] = [
  { selected_key: 'MOBILEBANK', content: 'Mobilebank' },
  { selected_key: 'SPARE', content: 'Spare' },
  { selected_key: 'PULS', content: 'Puls' },
  { selected_key: 'SBANKEN', content: 'Sbanken' },
];

const configsAggregation: DropdownOption<Config>[] = [
  { selected_key: 'LIVE', content: 'Live' },
  { selected_key: 'INTERNAL', content: 'Internal' },
  { selected_key: 'BETA', content: 'Beta' },
];

interface ProviderRow {
  id: string;
  name: string;
  country: string;
  paymentEnabled: string;
  domesticPaymentMethods: string;
  internationalPaymentMethods: string;
  operationalStatus: string;
}

const COLUMNS: Column<ProviderRow>[] = [
  { header: 'Name', attribute: 'name' },
  { header: 'Id', attribute: 'id' },
  { header: 'Country', attribute: 'country' },
  { header: 'Payments Enabled', attribute: 'paymentEnabled' },
  { header: 'Domestic Payment Methods', attribute: 'domesticPaymentMethods' },
  {
    header: 'International Payment Methods',
    attribute: 'internationalPaymentMethods',
  },
  { header: 'Operational Status', attribute: 'operationalStatus' },
];

export default function AggregationProviders(): JSX.Element {
  const [searchParams, setSearchParam] = useSearchParams();
  const { user, isLoading: userIsLoading, isLoggedIn } = useUser();

  const { data: apis, isValidating: loadingApis } =
    useSWR<ApiDto[]>('/api') ?? null;
  const [error, setError] = useState('');

  const [environments, setEnvironments] = useState<DropdownOption<string>[]>();
  const [unavailableEnvironments, setUnavailableEnvironments] = useState<
    string[]
  >([]);

  const [activeEnvironmnet, setActiveEnvironmnet] = useState(
    searchParams.get('environment') ?? 'prod',
  );

  const [activeChannel, setActiveChannel] = useState(
    searchParams.get('channel') ?? channelsAggregation[0].selected_key,
  );
  const [activeConfig, setActiveConfig] = useState(
    searchParams.get('config') ?? configsAggregation[0].selected_key,
  );

  const [activeProviders, setActiveProviders] = useState<{
    [key: string]: AggregationProvider[];
  }>();
  const [providers, setProviders] = useState<{
    [value: string]: AggregationProviderResponseDto;
  }>();

  useEffect(() => {
    if (apis && !environments) {
      const api = apis.find(({ slug }) => slug === EXTERNAL_ACCOUNTS_SLUG);

      const _environments = api?.environments.map(
        (environment: ApiEnvironmentDto) => ({
          selected_key: environment.slug,
          content: environment.name,
        }),
      );
      setEnvironments(_environments);

      if (!searchParams.get('environment')) {
        const defaultEnvironment =
          api?.environments.find(({ isDefault }) => isDefault)?.slug ||
          api?.environments[0].slug;

        searchParams.set('environment', defaultEnvironment || 'prod');
      }

      if (!searchParams.get('channel')) {
        searchParams.append('channel', channelsAggregation[0].selected_key);
      }

      if (!searchParams.get('config')) {
        searchParams.append('config', configsAggregation[0].selected_key);
      }
      setSearchParam(searchParams);
    }
  }, [apis, environments, searchParams, setSearchParam]);

  useEffect(() => {
    const environmentKeys = environments?.map(
      ({ selected_key }) => selected_key,
    );

    if (
      providers !== undefined &&
      environmentKeys?.includes(activeEnvironmnet)
    ) {
      setActiveProviders(providers?.[activeEnvironmnet][activeChannel]);
    }
  }, [activeChannel, activeEnvironmnet, environments, providers]);

  function onChange(key: 'environment' | 'channel' | 'config', value: string) {
    switch (key) {
      case 'environment': {
        setActiveEnvironmnet(value);
        break;
      }
      case 'channel': {
        setActiveChannel(value);
        break;
      }
      case 'config': {
        setActiveConfig(value);
        break;
      }
    }
    searchParams.set(key, value);
    setSearchParam(searchParams);
  }

  const { execute: fetchMetadata, waiting: loadingMetadata } =
    useAsync(async () => {
      if (environments !== undefined) {
        const metadata = await Promise.all(
          environments?.map(async (environment) => {
            try {
              const response =
                await getApiMetadata<AggregationProviderResponseDto>(
                  EXTERNAL_ACCOUNTS_SLUG,
                  environment.selected_key,
                );
              return {
                [environment.selected_key]: response,
              };
            } catch {
              setUnavailableEnvironments([
                ...unavailableEnvironments,
                environment.content,
              ]);
              return {};
            }
          }),
        );

        const providers = metadata.reduce(
          (accumulator, currentValue) =>
            Object.assign(accumulator, currentValue),
          {},
        );

        if (Object.keys(providers).length === 0) {
          setError('Unable to fetch provider information');
          return;
        }

        setEnvironments(
          environments.filter(
            (environment) =>
              !unavailableEnvironments.includes(environment.content),
          ),
        );
        setProviders(providers);
      }
    }, [environments, unavailableEnvironments]);

  useEffect(() => {
    if (!providers && environments && !loadingMetadata) {
      fetchMetadata();
    }
  }, [environments, fetchMetadata, providers, loadingMetadata]);

  const rows = useMemo(() => {
    if (!activeProviders || !activeProviders[activeConfig]) {
      return [];
    }
    return activeProviders[activeConfig]?.map<ProviderRow>(
      ({ countryCode, id, name, paymentMethods, paymentEnabled, status }) => ({
        id,
        name,
        country: countryCode,
        paymentEnabled: paymentEnabled ? 'Yes' : 'No',
        domesticPaymentMethods:
          paymentMethods?.domestic?.executionTypes.join(',') ?? '',
        internationalPaymentMethods:
          paymentMethods?.international?.executionTypes.join(',') ?? '',
        operationalStatus:
          status.syncMode === 'Full'
            ? 'Fully operational'
            : 'Partially operational',
      }),
    );
  }, [activeConfig, activeProviders]);

  if (loadingApis || loadingMetadata || userIsLoading) {
    return <LoadingModal />;
  }

  if (isLoggedIn && user?.organizationName !== 'DNB') {
    return <GlobalError status="404" />;
  }

  return (
    <AuthTemplate>
      <Page
        description={
          <Container size="small">
            <P>
              Provider Config is a list of banks supported by Aggregation Data.
              This list is specific to the selected service and environment.
            </P>
            <P top="small">
              For each provider, we supply information about the type of
              payments that the provider supports, both for domestic as well as
              international. The various payments methods are Normal, Instant
              and SpecificDate.
            </P>
            <P top="small">
              If Sync is full, it means that aggregation can perform unattended
              account details requests on a user&apos;s behalf, without needing
              strong costumer authentication.
            </P>
          </Container>
        }
        subtitle="Providers"
        title="Supported bank providers"
      >
        <Container centered>
          <Section style_type="white">
            <DataTable
              barContent={
                <Div className={style['Filters']}>
                  <Dropdown
                    className={style['dnb-dropdown']}
                    data={channelsAggregation}
                    on_change={({ data: selectedDataItem }) =>
                      onChange('channel', selectedDataItem.selected_key)
                    }
                    title="Select Channel"
                    value={activeChannel}
                  />

                  <Dropdown
                    className={style['dnb-dropdown']}
                    data={configsAggregation}
                    on_change={({ data: selectedDataItem }) =>
                      onChange('config', selectedDataItem.selected_key)
                    }
                    title="Select Config"
                    value={activeConfig}
                  />

                  <Dropdown
                    className={style['dnb-dropdown']}
                    data={environments}
                    on_change={({ data: selectedDataItem }) =>
                      onChange('environment', selectedDataItem.selected_key)
                    }
                    title="Select environment"
                    value={activeEnvironmnet}
                  />
                </Div>
              }
              columns={COLUMNS}
              data={rows}
              defaultSortKey="name"
              filterBy={['name', 'id', 'country']}
            />
          </Section>

          {error && <FormStatus text={error} />}
        </Container>
      </Page>
    </AuthTemplate>
  );
}
