import type { DeepPath, DeepValue, JoinedPathTuple, SplitPath } from './types';

/**
 * Debounce given function
 */

export function debounce<T extends unknown[]>(
  callback: (...args: T) => void,
  delay: number,
): typeof callback {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: T) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback(...args), delay);
  };
}

/**
 * Throttle given function
 */

export function throttle<T extends unknown[]>(
  callback: (...args: T) => void,
  delay: number,
): typeof callback {
  let timeout: ReturnType<typeof setTimeout>;
  const previous = 0;

  return (...args: T) => {
    const now = Date.now();

    clearTimeout(timeout);

    if (now - previous > delay) {
      callback(...args);
    } else {
      timeout = setTimeout(() => callback(...args), delay - (now - previous));
    }
  };
}

/**
 * Key by
 */

export function keyBy<T, Key extends keyof T>(
  array: T[],
  key: Key,
): Record<string, T> {
  const map: Record<string, T> = {};

  for (const value of array) {
    if (value[key] != null) {
      map[String(value[key])] = value;
    }
  }

  return map;
}

/**
 * Key by and map
 */

export function keyByAndMap<T, K extends keyof T, M extends keyof T>(
  array: T[],
  key: K,
  mapping: M,
): Record<string, T[M]> {
  const map: Record<string, T[M]> = {};

  for (const value of array) {
    if (value[key] != null) {
      map[String(value[key])] = value[mapping];
    }
  }

  return map;
}

/**
 * Group by
 */

export function groupBy<T, Key extends keyof T>(
  array: T[],
  key: Key,
): Record<string, T[]> {
  const map: Record<string, T[]> = {};

  for (const value of array) {
    if (value[key] != null) {
      const prop = String(value[key]);
      map[prop] ??= [];
      map[prop]?.push(value);
    }
  }

  return map;
}

/**
 * Safe Object#hasOwnProperty
 */

export function hasOwnProperty<K extends PropertyKey>(
  object: unknown,
  prop: K,
): object is Record<K, unknown> {
  return Object.prototype.hasOwnProperty.call(object, prop);
}

/**
 * Check if object
 */

export function isObject(
  object: unknown,
): object is Record<PropertyKey, unknown> {
  return Object.prototype.toString.call(object) === '[object Object]';
}

/**
 * Check if property can be safely set
 */

export function isSafeProperty(
  object: unknown,
  prop: string | number,
): object is Record<PropertyKey, unknown> | Array<unknown> {
  if (isObject(object)) {
    return object[prop] === undefined || hasOwnProperty(object, prop);
  }

  if (Array.isArray(object)) {
    return typeof prop === 'number' || !Number.isNaN(Number.parseInt(prop, 10));
  }

  return false;
}

/**
 * Deep clone object or array
 */

export function deepClone<T>(value: T): T;

export function deepClone(value: unknown): unknown {
  if (Array.isArray(value)) {
    return value.map((item) => deepClone(item));
  }

  if (isObject(value)) {
    const entries = Object.entries(value).map(([key, val]) => [
      key,
      deepClone(val),
    ]);

    return Object.fromEntries(entries);
  }

  return value;
}

/**
 * Get path segments from given path
 */

export function getPathSegments<P extends string>(path: P): SplitPath<P>;

export function getPathSegments(path: string) {
  return path.split('.');
}

/**
 * Merge path segments
 *
 * TODO: Handle non-tuples
 */

export function mergePathSegments<T extends string[]>(
  segments: T,
): JoinedPathTuple<T>;

export function mergePathSegments(segments: string[]) {
  return segments.join('.');
}

/**
 * Get value in object at given path
 */

export function deepGet<T, Path extends string>(
  object: T,
  path: DeepPath<T, Path>,
): DeepValue<T, Path>;

//export function deepGet(object: unknown, path: string): unknown;

export function deepGet(object: unknown, path: string): unknown {
  const segments = getPathSegments(path);
  let current: unknown = object;

  for (const segment of segments) {
    if (!isSafeProperty(current, segment)) {
      return;
    }

    if (Array.isArray(current) && /\d+/.test(segment)) {
      current = current[Number.parseInt(segment)];
    } else if (isObject(current) && typeof segment === 'string') {
      current = current[segment];
    } else {
      return;
    }
  }

  return current;
}

/**
 * Set deep value in object at given path
 */

export function deepSet<T, K extends string, V>(
  object: T,
  path: K,
  value: V,
): void {
  const segments = getPathSegments(path);
  const prop = segments.pop();
  let current: unknown = object;

  if (!prop) {
    return;
  }

  for (const segment of segments) {
    if (!isSafeProperty(current, segment)) {
      return;
    }

    if (/\d+/.test(segment) && Array.isArray(current)) {
      current = current[Number.parseInt(segment)] ??= [];
    } else if (typeof segment === 'string' && isObject(current)) {
      current = current[segment] ??= {};
    } else {
      return;
    }
  }

  if (isSafeProperty(current, prop)) {
    if (/\d+/.test(prop) && Array.isArray(current)) {
      current[Number.parseInt(prop)] = value;
    } else if (typeof prop === 'string' && isObject(current)) {
      current[prop] = value;
    }
  }
}

export function isInternalUser(email?: string) {
  const regex = new RegExp(`^[\\w%+.-]+@dnb\\.[A-Za-z]{2,}$`, 'i');
  return email ? regex.test(email) : false;
}

export function anyTrue(...items: boolean[]) {
  return items.some(Boolean);
}

export function noneEmptyValues<T>(
  value: T | null | undefined | '',
): value is T {
  return !!value;
}
