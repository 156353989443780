import MDEditor from '@uiw/react-md-editor';

import Markdown from '../Markdown';

import './index.css';

interface MarkdownEditorProps {
  onChange: (value?: string) => void;
  value?: string;
  mode?: 'light' | 'dark';
  height?: number;
}

export default function MarkdownEditor({
  onChange,
  value,
  height = 300,
  mode = 'light',
}: MarkdownEditorProps) {
  return (
    <div data-color-mode={mode}>
      <MDEditor
        components={{
          preview: (source) => <Markdown>{source}</Markdown>,
        }}
        height={height}
        highlightEnable={false}
        onChange={(_value) => onChange(_value)}
        value={value}
      />
    </div>
  );
}
