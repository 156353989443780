import { Button, FormLabel, FormRow, Input, ToggleButton } from '@dnb/eufemia';
import ToggleButtonGroup from '@dnb/eufemia/components/toggle-button/ToggleButtonGroup';
import { PUBLISHED_STATUS } from '@portals/shared/admin/ContentDto';
import {
  type Controller,
  useEufemiaProps,
} from '@portals/shared-frontend/hooks';

import Divider from '@/components/Divider';
import MarkdownEditor from '@/components/MarkdownEditor';

import { schema, VISIBLE_TO } from '../constants';

type Props = {
  controller: Controller<typeof schema>;
  hideDescription?: boolean;
};
export default function AddPageForm({
  controller,
  hideDescription = false,
}: Props) {
  const register = useEufemiaProps(controller);

  return (
    <>
      <FormRow bottom="medium">
        <FormLabel>Page title</FormLabel>
        <Input placeholder="Page title" stretch {...register.input('title')} />
      </FormRow>
      {!hideDescription && (
        <MarkdownEditor
          height={600}
          onChange={(value) => controller.setValue('content', value)}
          value={controller.values.content}
        />
      )}

      <FormRow bottom="medium" top="medium">
        <FormLabel>Status</FormLabel>
        <ToggleButtonGroup
          {...register.toggleButtonGroup('publishedStatus')}
          on_change={({ value }) =>
            controller.setValue('publishedStatus', Number.parseInt(value))
          }
          value={controller.values.publishedStatus?.toString()}
        >
          <ToggleButton
            value={PUBLISHED_STATUS.DRAFT.toString()}
            variant="radio"
          >
            Draft
          </ToggleButton>
          <ToggleButton
            value={PUBLISHED_STATUS.PUBLISHED.toString()}
            variant="radio"
          >
            Published
          </ToggleButton>
        </ToggleButtonGroup>
      </FormRow>
      <Divider />
      <FormRow top="small">
        <FormLabel>Visible to</FormLabel>
        <ToggleButtonGroup
          {...register.toggleButtonGroup('visibleTo')}
          on_change={({ value }) => controller.setValue('visibleTo', value)}
          value={controller.values.visibleTo}
          variant="radio"
        >
          <ToggleButton value={VISIBLE_TO.TEAM.toString()}>Team</ToggleButton>
          <ToggleButton value={VISIBLE_TO.DNB.toString()}>DNB</ToggleButton>
          <ToggleButton value={VISIBLE_TO.PUBLIC.toString()}>
            Public
          </ToggleButton>
        </ToggleButtonGroup>
      </FormRow>
      <FormRow top="large">
        <Button type="submit">Save</Button>
      </FormRow>
    </>
  );
}
