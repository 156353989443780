import { ProgressIndicator } from '@dnb/eufemia';

import style from './index.module.css';

export default function CenteredProgressIndicator(): JSX.Element {
  return (
    <div className={style['ProgressPosition']}>
      <ProgressIndicator
        label="Loading..."
        label_direction="vertical"
        show_label
        size="large"
        type="circular"
      />
    </div>
  );
}
