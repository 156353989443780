import { cloud, play } from '@dnb/eufemia/icons';

import Tile from '@/components/Tile';

import ServicePage from '../../components/ServicePage';

export default function ServiceExplorer() {
  return (
    <ServicePage title="Service Explorer">
      <Tile
        description="See all APIs that you can connect to"
        icon={cloud}
        linkTo="/explorer/apis"
        title="Apis"
      />
      {/**Remove conditional rendering when events are going to show */}
      {false && (
        <Tile
          description="See and connect to events"
          icon={play}
          linkTo="/explorer/events"
          title="Events"
        />
      )}
    </ServicePage>
  );
}
