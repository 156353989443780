import mermaid from 'mermaid';
import { ReactNode, useEffect } from 'react';

mermaid.initialize({
  theme: 'neutral',
  fontFamily: 'Dnb, DnbMono',
  themeVariables: {
    primaryColor: '#007272',
    secondaryColor: '#008484',
    tertiaryColor: '#A5E1D2',
    primaryBorderColor: '#00343E',
    secondaryBorderColor: '#757575',
    tertiaryBorderColor: '#333333',
    noteBorderColor: '#CCCCCC',
    secondaryTextColor: '#00343E',
    tertiaryTextColor: '#00343E',
    lineColor: '#00343E',
    textColor: '#333333',
    nodeBkg: '#A5E1D2',
    mainBkg: '#D2F0E9',
    nodeBorder: '#A5E1D2',
    clusterBkg: '#EBFFFA',
    clusterBorder: '#14555A',
    defaultLinkColor: '#00343E',
    titleColor: '#333333',
    edgeLabelBackground: '#A5E1D2',
    nodeTextColor: '#00343',
    actorBorder: '#A5E1D2',
    actorBkg: '#D2F0E9',
    actorTextColor: '#000000',
    actorLineColor: '#737373',
    labelBoxBkgColor: '#14555A',
    signalColor: '#333333',
    signalTextColor: '#333333',
    labelBoxBorderColor: '#14555A',
    labelTextColor: '#000000',
    loopTextColor: '#000000',
    activationBorderColor: '#757575',
    activationBkgColor: '#F8F8F8',
    sequenceNumberColor: '#FFFFFF',
    sectionBkgColor: '#28B482',
    altSectionBkgColor: '#FFFFFF',
    sectionBkgColor2: '#28B482',
    taskBorderColor: '#14555A',
    taskBkgColor: '#008484',
    activeTaskBorderColor: '#14555A',
    activeTaskBkgColor: '#A5E1D2',
    gridColor: '#CCCCCC',
    doneTaskBkgColor: '#CCCCCC',
    doneTaskBorderColor: '#737373',
    critBorderColor: '#FF5400',
    critBkgColor: '#FDEEEE',
    todayLineColor: '#FF5400',
    taskTextLightColor: '#FFFFFF',
    taskTextColor: '#FFFFFF',
    taskTextDarkColor: '#000000',
    taskTextOutsideColor: '#000000',
    taskTextClickableColor: '#23195A',
    labelColor: '#000000',
    altBackground: '#FAFAFA',
    errorBkgColor: '#FDEEEE',
    errorTextColor: '#FF5400',
    fillType0: '#007272',
    fillType1: '#008484',
    fillType2: '#A5E1D2',
    fillType3: '#14555A',
    fillType4: '#B3D5D5',
    fillType5: '#D2F0E9',
    fillType6: '#E9F8F4',
    fillType7: '#EBFFFA',
  },
});

export default function MermaidDiagram({ content }: { content: ReactNode }) {
  useEffect(() => {
    mermaid.contentLoaded();
  }, [content]);

  return <pre className="mermaid">{content}</pre>;
}
