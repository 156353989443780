import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useFeatureFlags from '@/hooks/useFeatureFlags';
import PageTimelineContent from '@/pages/explorer/apis/page/regulatory-apis/PageTimelineContent';

import ApiPage from '../../components/ApiPage';
import { API_EXPLORER_TABS } from '../../content';

export default function RegulatoryApis() {
  const { featureFlags, isLoading } = useFeatureFlags();
  const navigate = useNavigate();

  //TODO: remove this when featureflag is disabled and is set as default
  useEffect(() => {
    if (!featureFlags.ENABLE_NEW_REDESIGN && !isLoading) {
      navigate('/explorer/apis', { replace: true });
    }
  }, [featureFlags.ENABLE_NEW_REDESIGN, navigate, isLoading]);

  return (
    <ApiPage apiType={API_EXPLORER_TABS.Regulatory} title="Regulatory APIs">
      <PageTimelineContent />
    </ApiPage>
  );
}
