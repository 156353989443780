import type { ReactNode } from 'react';

import InfoStat from './Components/InfoStat';
import PieStat from './Components/PieStat';

export interface StatsType {
  type: string;
  data: {
    title: string;
    text?: string;
    icon?: ReactNode | string | unknown;
    chart?: {
      series: number[];
      labels: string[];
    };
    progress?: {
      type?: 'process' | 'number';
      text?: string;
      progress: number;
    };
  };
}

const Stats = ({ type = 'info', data }: StatsType) => {
  switch (type) {
    case 'info': {
      return (data?.title && <InfoStat data={data} type={type} />) || null;
    }
    case 'pie': {
      return <PieStat data={data} type={type} />;
    }
  }

  return null;
};

export default Stats;
