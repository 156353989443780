import type { ScrollMenuElement } from '@/components/ScrollMenu';

export const MENU_ITEMS: ScrollMenuElement[] = [
  { id: 'introduction', title: 'Introduction' },
  { id: 'use-of-developer-portal', title: 'Developer Portal' },
  {
    id: 'apis-not-regulated-under-psd2',
    title: 'APIs not regulated under PSD2',
  },
  { id: 'use-of-psd2', title: 'PSD2 APIs and Sandbox' },
];
