import { H2, Img, Link, P, Skeleton, Space } from '@dnb/eufemia';
import type { SpacingElementProps } from '@dnb/eufemia/shared/types';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import style from './index.module.css';

import downImageUrl from './Down.svg?url';
import operationalImageUrl from './Operational.svg?url';
import unstableImageUrl from './Unstable.svg?url';

interface StatusWidgetProps extends SpacingElementProps {
  severity: number;
  headings?: string[];
  content?: React.ReactNode[];
  skeleton?: boolean;
}

const HEADINGS = [
  'All APIs are operational',
  'Some APIs may have instabilities',
  'Some APIs have reduced service',
];

const CONTENT = [
  <>
    Please visit the{' '}
    <Link element={RouterLink} to="/status">
      status page
    </Link>{' '}
    for updates.
  </>,
  <>
    We are looking into it. Please visit the{' '}
    <Link element={RouterLink} to="/status">
      status page
    </Link>{' '}
    for updates.
  </>,
  <>
    We are looking into it. Please visit the{' '}
    <Link element={RouterLink} to="/status">
      status page
    </Link>{' '}
    for updates.
  </>,
];

const ILLUSTRATION = [operationalImageUrl, unstableImageUrl, downImageUrl];

export default function StatusWidget({
  severity,
  headings = HEADINGS,
  content = CONTENT,
  skeleton,
  ...props
}: StatusWidgetProps) {
  return (
    <Skeleton show={skeleton}>
      <Space
        className={classNames([
          style['StatusWidget'],
          style[`StatusWidget--severity-${severity}`],
          'dnb-drop-shadow',
        ])}
        {...props}
      >
        <Img
          alt={headings[severity]}
          src={ILLUSTRATION[severity]}
          width={160}
        />
        <div className={style['StatusWidget-text']}>
          <H2 className={style['StatusWidget-header']} size="medium">
            {headings[severity]}
          </H2>
          <P top="small">{content[severity]}</P>
        </div>
      </Space>
    </Skeleton>
  );
}
