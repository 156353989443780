import { z } from 'zod';

export const dataset = z.object({
  label: z.string(),
  data: z.array(z.number()),
});

export enum TitlePosition {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
}

export enum Type {
  pie = 'pie',
  doughnut = 'doughnut',
  bar = 'bar',
  info = 'info',
  line = 'line',
}

export const gridArray = z.tuple([
  z.number().min(1).max(12),
  z.union([z.number().min(1).max(12), z.number().min(1).max(12)]),
]);

export const gridSchema = z.object({
  small: gridArray,
  medium: gridArray,
  large: gridArray,
});

export const pieChartSchema = z.object({
  type: z.union([z.literal(Type.pie), z.literal(Type.doughnut)]),
  labels: z.array(z.string()),
  datasets: z.array(dataset),
  title: z.string(),
});

export const barChartSchema = z.object({
  type: z.literal(Type.bar),
  labels: z.array(z.string()),
  datasets: z.array(dataset),
  title: z.string(),
  titlePosition: z.nativeEnum(TitlePosition),
});

export const lineChartSchema = z.object({
  type: z.literal(Type.line),
  labels: z.array(z.string()),
  datasets: z.array(dataset),
  title: z.string(),
});

export const statsDataSchema = z.object({
  type: z.literal(Type.info),
  title: z.string(),
  text: z.string().optional(),
  icon: z.union([z.string(), z.unknown()]),
});

const dataTypeSchema = z.union([
  statsDataSchema,
  pieChartSchema,
  barChartSchema,
  lineChartSchema,
]);

export const dashboardItemSchema = z.object({
  title: z.string().optional(),
  small: gridArray.optional(),
  medium: gridArray.optional(),
  large: gridArray.optional(),
  data: dataTypeSchema.nullable().optional(),
});

export type PieChart = z.infer<typeof pieChartSchema>;
export type BarChart = z.infer<typeof barChartSchema>;
export type LineChart = z.infer<typeof lineChartSchema>;
export type DashboardItem = z.infer<typeof dashboardItemSchema>;
export type StatsData = z.infer<typeof statsDataSchema>;
export type DataType = z.infer<typeof dataTypeSchema>;
export type Grid = z.infer<typeof gridSchema>;
