import { Button, FormRow, FormSet, Input, P } from '@dnb/eufemia';
import { check_medium as CheckMediumIcon } from '@dnb/eufemia/icons';
import { ATLEAST_N } from '@portals/shared/common/messages';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { type User, verifyMfaLogin } from '@/auth/cognito';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import ModalPage from '@/components/ModalPage';
import useReturnTo from '@/hooks/useReturnTo';

type VerifyMfaProps = {
  cognitoUser?: User;
  toggleView: () => void;
};

const mfaFormSchema = z.object({
  code: z.string().min(1, ATLEAST_N(1)),
});

export default function VerifyRecoveryCode({
  cognitoUser,
  toggleView,
}: VerifyMfaProps): JSX.Element {
  const [returnTo, , clearReturnTo] = useReturnTo();
  const navigate = useNavigate();

  const {
    controller: { setError, setFormError, formError },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(mfaFormSchema, {});

  const onSubmit = handleSubmit(
    useCallback(
      async ({ code }) => {
        try {
          if (cognitoUser) {
            const user = await verifyMfaLogin({
              user: cognitoUser,
              code: code,
              type: 'RECOVERY_CODE',
            });

            if (user?.challengeName === 'CUSTOM_CHALLENGE') {
              setError('code', 'The recovery code is incorrect');
              return;
            }

            navigate(returnTo ?? '/');
            clearReturnTo();
          }
        } catch (error) {
          if (!hasOwnProperty(error, 'code')) {
            throw error;
          }

          switch (error.code) {
            case 'NotAuthorizedException': {
              setFormError(
                'You have reached the maximum number of allowed attempts. Please start the log-in process again.',
              );
              break;
            }
            default: {
              throw error;
            }
          }
        }
      },
      [clearReturnTo, cognitoUser, navigate, returnTo, setError, setFormError],
    ),
  );

  return (
    <ModalPage
      description="Please fill in one of the recovery codes you received when activating multi-factor authentication. Each code can only be used once."
      subtitle="Log in"
      title="Fill in one of the recovery codes"
    >
      {submitting && <LoadingModal />}
      <Container size="small">
        <FormSet on_submit={onSubmit} vertical>
          <FormRow>
            <Input
              autoFocus
              label="Recovery code"
              placeholder="xxxxxx"
              size="large"
              stretch
              {...register.input('code')}
            />
          </FormRow>

          <FormRow top="large">
            <Button
              disabled={submitting}
              icon={CheckMediumIcon}
              icon_position="left"
              icon_size="medium"
              size="large"
              status={formError}
              type="submit"
            >
              Verify
            </Button>
          </FormRow>

          <FormRow top="medium">
            <P>Use your authenticatior app?</P>
            <Button
              on_click={toggleView}
              size="small"
              top="x-small"
              variant="tertiary"
            >
              Verify with your authenticator app
            </Button>
          </FormRow>
        </FormSet>
      </Container>
    </ModalPage>
  );
}
