import { FormStatus, P, Space, Timeline } from '@dnb/eufemia';
import {
  hourglass as HourglassIcon,
  stop as StopIcon,
} from '@dnb/eufemia/icons';
import {
  Decision,
  type EntitlementRequestWithScopeDto,
} from '@portals/shared/portal/AppApiEntitlementDto';

import style from './index.module.css';

type RequestTimelineProps = {
  request: EntitlementRequestWithScopeDto;
};

const texts = {
  accessProduction: 'Access to the live environment',
  accessTestProd: 'Access to the test and live environments',
  reviewed: 'Request has been sent for review',
};

function PreSubmit({ request }: RequestTimelineProps): JSX.Element {
  return (
    <>
      <Timeline.Item
        icon={<HourglassIcon />}
        infoMessage="Sit back and relax while your request is being processed."
        state="current"
        title="Request is being processed"
      />
      <Timeline.Item state="upcoming" title={texts.reviewed} />
      <Timeline.Item
        state="upcoming"
        title={
          request.approvalStage === 'test'
            ? texts.accessTestProd
            : texts.accessProduction
        }
      />
    </>
  );
}

function Pending({ request }: RequestTimelineProps): JSX.Element {
  return (
    <>
      {request.externalTicketId && (
        <Timeline.Item
          icon={<HourglassIcon />}
          state="completed"
          title="Request has been processed in Service Now"
        />
      )}
      <Timeline.Item
        infoMessage={
          <>
            <P size="small">
              Sit back and relax while your request is being reviewed.
            </P>
            {request.externalTicketId && (
              <P size="small">
                <b>Ticket Id: </b>
                {request.externalTicketId}
              </P>
            )}
          </>
        }
        state="current"
        title="Request has been sent for review"
      />
      <Timeline.Item
        state="upcoming"
        title={
          request.approvalStage === 'test'
            ? texts.accessTestProd
            : texts.accessProduction
        }
      />
    </>
  );
}

function Level1Approved({ request }: RequestTimelineProps) {
  return (
    <>
      <Timeline.Item
        state="completed"
        title="Request has been processed in Service Now"
      />
      <Timeline.Item
        infoMessage={
          request.externalTicketId && (
            <P size="small">
              <b>Ticket Id: </b>
              {request.externalTicketId}
            </P>
          )
        }
        state="completed"
        title="Request has been approved by API provider"
      />
      <Timeline.Item
        icon={HourglassIcon}
        state="current"
        title="The Portals team are finalizing the request"
      />
      <Timeline.Item
        state="upcoming"
        title={
          request.approvalStage === 'test'
            ? texts.accessTestProd
            : texts.accessProduction
        }
      />
    </>
  );
}

function Denied({ request }: RequestTimelineProps) {
  const toLocalDateString = (date: string) => new Date(date).toLocaleString();

  return (
    <>
      <Timeline.Item
        state="completed"
        subtitle={
          request.decisionMadeAt
            ? toLocalDateString(request.decisionMadeAt)
            : ''
        }
        title="Request reviewed"
      />

      <Timeline.Item
        icon={<StopIcon />}
        state="current"
        title={
          request.approvalStage === 'test'
            ? texts.accessTestProd
            : texts.accessProduction
        }
      />
    </>
  );
}

export default function RequestTimeline({
  request,
}: RequestTimelineProps): JSX.Element {
  const toLocalDateString = (date: string) => new Date(date).toLocaleString();

  function timelineComponent(decision: Decision) {
    switch (decision) {
      case Decision.PRESUBMIT: {
        return <PreSubmit request={request} />;
      }
      case Decision.PENDING: {
        return <Pending request={request} />;
      }
      case Decision.LEVEL1_APPROVED: {
        return <Level1Approved request={request} />;
      }
      case Decision.DENIED: {
        return <Denied request={request} />;
      }
      default: {
        return <Pending request={request} />;
      }
    }
  }

  return (
    <>
      <Timeline bottom="small" top="small">
        <Timeline.Item
          state="completed"
          subtitle={toLocalDateString(request.createdAt)}
          title="Access request sent"
        />
        {timelineComponent(request.decision)}
      </Timeline>

      {request.decision === Decision.DENIED && (
        <Space bottom="medium" className={style['FormStatus']} left="x-large">
          <FormStatus
            text={
              <P>
                Request for live access was rejected with the following reason:{' '}
                <i>
                  {request.externalTicketComment || request.decisionComment}
                </i>
                {request.externalTicketId && (
                  <P size="small">
                    <b>Ticket Id: </b>
                    {request.externalTicketId}
                  </P>
                )}
              </P>
            }
          />
        </Space>
      )}
    </>
  );
}
