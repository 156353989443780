export const INVALID_PASSWORD =
  'The password must contain a minimum of 8 characters, one uppercase and one lowercase letter. You also need a number and a special character (!#$%&*?@^-).';
export const INVALID_URL = 'Must start with a forward slash (/)';
export const INVALID_KEY =
  'Must match 1 or more character in the set (a-z) or (_)';
export const INVALID_UUID = 'Must be a valid UUID';
export const INVALID_DATE = 'Must be a valid date';
export const INVALUD_TIME = 'Must be a valid time';
export const INVALID_ISO_DATE = 'Must be a ISO 8601 date string';
export const INVALID_REQUEST_ID =
  'The Request ID should be a valid UUID (e.g., "123e4567-e89b-12d3-a456-426614174000").';
export const TERMS_AGREEMENT = 'Please agree to the terms of use';
export const INVALID_REDIRECT_URIS = 'For live clients, HTTPS uri is required';

export const ATLEAST_N = (n: number) =>
  `Field must contain at least ${n} ${n > 1 ? 'characters' : 'character'}.`;
export const ATMOST_N = (n: number) =>
  `Field cannot contain more than ${n} ${n > 1 ? 'characters' : 'character'}.`;
export const REQUIRED = 'Required';
