import { H2, H3, Link, P } from '@dnb/eufemia';
import { Link as RouterLink } from 'react-router-dom';

import Page from '@/components/Page';
import { ScrollMenu } from '@/components/ScrollMenu';

import { MENU_ITEMS } from './content';

import style from './TermsOfUse.module.css';

export default function TermsOfUse(): JSX.Element {
  return (
    <Page subtitle="Terms of Use" title="General DNB API Terms of Use">
      <div className={style['TermsOfUse']}>
        <aside className={style['ScrollMenu']}>
          <ScrollMenu elements={MENU_ITEMS} />
        </aside>

        <div>
          <H2 id="introduction">Introduction</H2>

          <P top="medium">
            These terms («Terms») apply between DNB Bank ASA («DNB») and the
            person or legal entity («you») that uses DNB&apos;s API in test or
            live mode or provides DNB with feedback. Any use of DNB&apos;s APIs
            require acceptance of these terms. Additional terms may apply to
            specific API services. The DNB privacy policy, as updated from time
            to time, is incorporated by reference.
          </P>

          <P top="medium">
            The information in this portal and website is provided without any
            endorsement by DNB of your product or services. DNB is the owner of
            the website and holds all intellectual property rights related to
            the website and content produced by DNB. It is not permitted for
            commercial players or for commercial use to reproduce content from
            the website without stating that DNB is the source of the material.
          </P>

          <H2 id="use-of-developer-portal" top="large">
            Use of DNB Developer Portal
          </H2>
          <P top="medium">
            You must ensure that DNB at all times have your correct e-mail
            address. Further, DNB may communicate with you through the API
            portal. Your feedback, input or suggestions may be used by DNB to
            improve and develop DNB services.
          </P>
          <P top="medium">
            You must keep access passwords and other authorization information
            (the «Credentials») confidential. You may not disclose your
            Credentials to any other person or for any other purpose. If you
            become aware or if you suspect that the confidentiality of Your
            Credentials has been compromised, you are obliged to inform DNB
            without undue delay.
          </P>
          <P top="medium">
            You are responsible for all activities that occur under usage of
            your Credentials, regardless whether undertaken by you or someone
            else.
          </P>

          <P top="medium">
            You are responsible for protecting your personal computer, mobile
            phone and other electronic equipment you use to access the websites
            of DNB, including for following DNB&apos;s security advice.
          </P>

          <P top="medium">
            The website uses encryption to protect any electronic trails. This
            includes all information sent via the Internet between your browser
            and our servers.
          </P>

          <P top="medium">
            To the extent that it is possible to leave the website via links to
            external web pages which have less protection, DNB assumes no
            responsibility for the webpages linked from our website.
          </P>

          <P top="medium">
            The information and content on DNB&apos;s website may contain
            insufficient or imprecise information and this cannot be claimed as
            a basis for claims of any kind.
          </P>
          <P top="medium">
            All use of this website is governed by Norwegian law. Any event
            which may arise between DNB and the website users is governed by
            Norwegian legislation. In the event of a dispute between the Parties
            arising out of or in connection with these Terms, the dispute shall
            be finally settled
          </P>
          <P top="medium">
            a. if you are Norwegian registered legal entity or a Norwegian
            citizen, by the Oslo District Court, Norway;
          </P>
          <P top="medium">
            b. otherwise by arbitration in accordance with the UNCITRAL
            Arbitration Rules as at present in force. The place of arbitration
            shall be Oslo, Norway. The appointing authority shall be Oslo
            District Court, Norway. The language to be used in the arbitral
            processing shall be English. Norwegian law shall govern the
            proceedings.
          </P>

          <H2 id="apis-not-regulated-under-psd2" top="large">
            Use of APIs not regulated under PSD2 (Directive (EU) 2015/2366)
          </H2>
          <H3 top="medium">Test mode</H3>
          <P top="medium">
            If DNB grants you access to a test mode API service, you receive a
            limited, non-exclusive, revocable license to use the API service and
            API documentation for the sole purpose of testing and developing
            your Program, provided that you comply with these Terms. In the Test
            mode you will only receive access to synthetic test data, not
            personal data.
          </P>
          <H3 top="medium">Live mode</H3>
          <P top="medium">
            If DNB grants you access to a live mode API service, you receive a
            limited, non-exclusive, revocable license to use the API service and
            API documentation for production purposes, provided that you comply
            with these Terms. In the live mode you will receive access to
            production data.
          </P>

          <H3 top="medium">Both Test and Live mode</H3>
          <P top="medium">
            DNB reserves the right to not approve or revoke approval of your
            access to an API service and the API portal based on DNB&apos;s sole
            discretion. DNB may impose limits on API usage.
          </P>

          <P top="medium">
            DNB may on DNB&apos;s sole discretion alter the APIs and API
            documentation. DNB may alter the APIs and API documentation in order
            to correct errors, enhance or discontinue APIs. When DNB makes an
            alteration to an API, or discontinues an API, DNB will inform you in
            the API portal. DNB will generally endeavor to give such information
            at least three (3) months before an API is discontinued or a version
            of the API ceases to be available. Shorter period of notice may be
            stated in the provided information. You must use the latest API
            version, or cease using the API within the deadline.
          </P>

          <P top="medium">
            Your program must authorize itself towards DNB in the manner
            described in the API documentation. If you have forgotten your
            credentials you can generate new credentials in the API portal.
          </P>

          <P top="medium">
            You must not attempt to circumvent or modify any security mechanism
            used by DNB in connection with the API services. You must not
            attempt unauthorized access to, or use of, DNB&apos;s systems.
          </P>

          <P top="medium">
            Unless required by applicable law or agreed to in writing, you may
            not reverse engineer, disassemble, decompile, or reconstruct any DNB
            system.
          </P>

          <P top="medium">
            The API services and API data may not be used for Programs that
            enables or contributes to
          </P>
          <P top="medium">a) fraud,</P>
          <P top="medium">b) money laundering,</P>
          <P top="medium">c) terrorist financing,</P>
          <P top="medium">
            d) betting or gambling, except when explicitly accepted in writing
            by DNB,
          </P>
          <P top="medium">
            e) other illegal activity noncompliant with the laws of Norway or
            other relevant jurisdiction,
          </P>
          <P top="medium">
            f) actions contrary to order of a court or requirement of any
            relevant regulatory or governmental authority, body or agency, or
          </P>
          <P top="medium">
            g) actions that are offensive or that harms or can reasonably be
            expected to harm any person or entity, whether or not such action is
            prohibited by law.
          </P>

          <P top="medium">
            You must keep access passwords and other authorization information
            (the «Credentials») confidential. You may not disclose your
            Credentials to any other person or for any other purpose. If you
            become aware or if you suspect that the confidentiality of Your
            Credentials has been compromised, you are obliged to inform DNB
            without undue delay.
          </P>

          <P top="medium">
            You are responsible for all activities that occur under usage of
            your Credentials, regardless whether undertaken by you or someone
            else.
          </P>

          <H3 top="medium">Disclaimer of Warranty and Uptime</H3>
          <P top="medium">
            The API services, API documentation, API data and API portal are
            delivered «as is» and with all faults they may have, without
            warranties of any kind. API services and API portal are delivered
            «as available» and without any uptime warranty.
          </P>

          <H3 top="medium">Termination</H3>
          <P top="medium">
            DNB may additionally terminate these Terms for its convenience by
            providing thirty (30) days written notice to you. In cases of
            material or repeated breach of these terms, DNB may terminate these
            Terms and your access to the API service immediately. Any (i)
            non-compliance with applicable law, including breach of privacy law;
            (ii) non-compliance with DNB anti-money laundering rules or DNB Code
            of Conduct, as updated from time to time; (iii) any attempt at
            circumventing limitations on use; (iv) activates related to you,
            your Program or your access to the API, which may jeopardize the
            data security of DNB, our Customers or partners; or (v) failure to
            remedy any breach within the notice period set by DNB shall be
            deemed a material breach.
          </P>

          <H3 top="medium">Indemnity and Disclaimer of Liability</H3>
          <P top="medium">
            If the cause for the loss is within your control, you shall
            indemnify DNB for any loss incurred by DNB or the DNB&apos;s
            customer. Losses related to errors, weaknesses or faults of your
            Program, or your systems, are always be considered as being within
            your control.
          </P>
          <P top="medium">
            DNB is not liable for losses due to circumstances beyond DNB&apos;s
            control nor for indirect losses (including but not limited to loss
            of anticipated profits or of data). In no event shall DNB&apos;s
            total liability to you for all damages (other than as may be
            required by applicable law) exceed the amount of ten thousand
            Norwegian kroner (NOK 10 000).
          </P>

          <H3 top="medium">Suggestions to Product Improvements</H3>
          <P top="medium">
            You grant DNB a non-exclusive, sublicensable, irrevocable and
            royalty-free worldwide license to use your input or suggestions in
            relation to DNB products and services.
          </P>

          <H3 top="medium">Miscellaneous</H3>
          <P top="medium">
            DNB may amend these Terms. If you use APIs which are subject to
            updated terms after the notice period determined by DNB, you accept
            the updated terms.
          </P>

          <H2 id="use-of-psd2" top="large">
            Use of PSD2 API Sandbox and APIs mandated by PSD2 (Directive (EU)
            2015/2366)
          </H2>
          <P top="medium">
            The information in this portal and website is intended for the use
            of authorized third party service providers under the revised
            Payment Services Directive (PSD2) as implemented by Norwegian
            legislator. Access and use of the website and its content is
            governed by Norwegian laws and regulations. DNB reserves all rights
            to regulate access according to Norwegian laws and regulations,
            including security measures necessary to prevent unauthorized access
            or misuse of data.
          </P>
          <P top="medium">
            <a
              className="dnb-anchor"
              href="https://www.dnb.no/en/about-us/protection-of-personal-privacy.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              DNB&apos;s rules on the processing of personal data
            </a>{' '}
            provides information on how DNB processes personal data. In addition{' '}
            <a
              className="dnb-anchor"
              href="https://www.dnb.no/en/global/generelle-vilkar.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              General terms
            </a>{' '}
            of use of DNB&apos;s websites apply.
          </P>
          <P top="medium">
            The information and content for the use of PSD2 APIs is provided «as
            is» without warranties of any kind. DNB may on DNB&apos;s sole
            discretion alter the information and content, including APIs and API
            documentation.
          </P>
          <P top="medium">
            In order to access our PSD2 test APIs (Sandbox), you must provide
            registration data, including first name, last name, e-mail address,
            company name and common name in order to generate test certificates.
            DNB will register generation and use of test certificate.
          </P>
          <P top="medium">
            DNB may use the e-mail address you have provided to communicate with
            you and send notices regarding updates, errors or other relevant
            technical information about our PSD2 Sandbox.
          </P>
          <P top="medium">
            Please contact us through our{' '}
            <Link element={RouterLink} to="/help-center">
              support page
            </Link>{' '}
            if you have any questions or comments.
          </P>
        </div>
      </div>
    </Page>
  );
}
