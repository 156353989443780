import styled from '@emotion/styled';

export const Cards = styled.div`
  background-color: blue;
`;

type CardProps = {
  type: string | null;
};

export const Card = styled.div<CardProps>`
  background-color: ${(props) =>
    props.type ? `var(--color-${props.type})` : ''};
  padding: ${(props) => (props.type ? 'var(--spacing-small)' : '')};
  color: ${(props) => (props.type ? 'white' : 'black')};
  border-radius: ${(props) => (props.type ? '4px' : '')};
  button {
    margin-top: var(--spacing-small);
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

export const CodeWrapper = styled.div`
  position: relative;

  .title {
    background: #272822;
    position: absolute;
    border-bottom: 1px solid gray;
    width: 100%;
    padding: var(--spacing-x-small);
    padding-top: 12px;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    color: var(--color-black-20);
    padding-left: var(--spacing-small);
    font-size: var(--font-size-x-small);
  }

  .dnb-pre.has-title {
    padding-top: 58px;
  }
`;
export const AccordionSection = styled.div`
  .dnb-accordion__header,
  .dnb-accordion__header:hover {
    z-index: 2;
  }
  .dnb-accordion__content__inner {
    background-color: white;
    z-index: 1;
    padding: var(--spacing-medium);
    margin-top: -2px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    .dnb-code {
      background-color: var(--color-ocean-green);
    }
  }

  &.contrast {
    .dnb-accordion__header,
    .dnb-accordion__header:hover {
      background-color: #007272 !important;
      color: white !important;
      z-index: auto;
      box-shadow: 0 0 0 var(--border-width) white !important;

      svg {
        color: white !important;
      }
    }
    .dnb-accordion__content__inner {
      background-color: rgb(0, 114, 114);
      color: white;
      padding: var(--spacing-medium);
      padding-top: 0;
      margin-top: -2px;
      z-index: auto;

      .dnb-code {
        background-color: var(--color-ocean-green);
      }
    }
  }
`;

export const TabsSection = styled.div`
  overflow: hidden;
  .dnb-tabs__content__inner {
    > *:first-child {
      margin-top: var(--spacing-x-small);
    }
  }
`;

export const Alert = styled.div`
  color: black;
  border-radius: 4px;
  padding: var(--spacing-small) var(--spacing-medium) 0 var(--spacing-medium);
  margin: var(--spacing-small) 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  box-shadow: var(--shadow-default);
  position: relative;

  &:after {
    border: 1px solid var(--color-black-8);
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }

  &.tip {
    background-color: var(--color-pistachio);
    color: #0a3622;
    h4 {
      color: rgb(0, 114, 114);
    }

    &:after {
      border-color: rgba(0, 114, 114, 0.1);
    }
  }

  &.note {
    background-color: var(--color-lavender);
    border-color: #8b939c;
    color: #495057;
  }

  &.info {
    background-color: var(--color-white);
    border-color: #5bc0de;
    color: #052c65;
    h4 {
      color: #46b8da;
    }
  }

  &.warning {
    background-color: #fcf8f2;
    color: #664d03;

    &:after {
      border-color: rgba(240, 173, 78, 0.2);
    }

    h4 {
      color: #eea236;
    }
  }

  &.danger {
    background-color: #fdf7f7;
    border-color: #d9534f;
    color: #58151c;
    border-width: 0;
    border-left-width: 6px;

    h4 {
      color: #d43f3a;
    }

    :after {
      border-color: rgba(88, 21, 28, 0.1);
    }
  }

  .text {
    margin-left: var(--spacing-small);
    display: inline-block;
    width: 100%;
  }

  h1:first-child,
  h2:first-child,
  h3:first-child {
    margin-top: 0;
    margin-bottom: var(--spacing-x-small);
  }
`;
