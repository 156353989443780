type MobileProps = {
  mobileMenu: boolean;
  setMobileMenu: (mobileMenu: boolean) => void;
};
const MobileMenu = ({ mobileMenu, setMobileMenu }: MobileProps) => (
  <button
    className={`${mobileMenu ? 'active ' : ''}mobile-menu`}
    onClick={() => setMobileMenu(!mobileMenu)}
  >
    <span />
    <span />
    <span />
  </button>
);

export default MobileMenu;
