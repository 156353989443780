import {
  InteractionRequiredAuthError,
  InteractionType,
} from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { useLocalStorage } from '@portals/shared-frontend/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { loginRequest } from '@/auth/azure';
import CenteredProgressIndicator from '@/components/ProgressIndicator';
import useReturnTo from '@/hooks/useReturnTo';

export default function SsoAzureLogin() {
  const [, setLastLoggedInEmail] = useLocalStorage(
    `last-logged-in-internal-user-email`,
    '',
  );
  const navigate = useNavigate();
  const [returnTo] = useReturnTo();

  // Attempt silent authentication. If interaction is required, an error will be thrown
  const { login, error } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest,
  );

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      (async () => {
        try {
          // Attempt to log in with redirect interaction type e.g. if token is expired
          login(InteractionType.Redirect, loginRequest);
        } catch {
          navigate('/login/failure');
        }
      })();
    }
  }, [error, login, navigate]);

  // Get the accounts from the MSAL context
  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      setLastLoggedInEmail(accounts[0].username);
      navigate(returnTo ?? '/');
    }
  }, [accounts, navigate, returnTo, setLastLoggedInEmail]);

  return <CenteredProgressIndicator />;
}
