import type { ErrorDto } from '@portals/shared/common/ErrorDto';
import { hasOwnProperty } from '@portals/shared-frontend/utils';

export class ApiError extends Error {
  constructor(public status: number, public body: ErrorDto) {
    super(body.message);
  }

  static isApiError(error: unknown): error is ApiError {
    return hasOwnProperty(error, 'body') && hasOwnProperty(error, 'status');
  }
}
