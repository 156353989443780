import type {
  CreateSubscriptionsInputDto,
  SubscriberDto,
  UpdateSubscriptionsInputDto,
} from '@portals/shared/portal/SubscriptionDto';

import { request } from '@/request';

export function updateSubscriptions(
  data: UpdateSubscriptionsInputDto,
  token?: string,
): Promise<SubscriberDto> {
  const url = token ? `/subscriptions/${token}` : '/subscriptions';
  return request(url, 'put', data);
}

export function createSubscriptions(
  data: CreateSubscriptionsInputDto,
): Promise<SubscriberDto> {
  return request('/subscriptions', 'post', data);
}

export async function requestEditSubscriptionsEmail(
  email: string,
): Promise<void> {
  const url = '/subscriptions/link';
  await request(url, 'post', { email });
}

export async function deleteSubscriptions(token?: string): Promise<void> {
  const url = token ? `/subscriptions/${token}` : '/subscriptions';
  await request(url, 'delete');
}
