import { P } from '@dnb/eufemia';

import style from './index.module.css';

interface Props {
  name?: string;
  required?: boolean;
}

export default function NameAndRequired({ name, required }: Props) {
  return (
    <div>
      {name && (
        <P>
          <strong>{name}</strong>
        </P>
      )}
      {required && <P className={style['required']}>required</P>}
    </div>
  );
}
