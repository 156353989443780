import { Link } from '@dnb/eufemia';
import { Link as RouterLink } from 'react-router-dom';

import aisIllustrationUrl from '@/illustrations/AIS.svg?url';
import checkListIllustrationURL from '@/illustrations/ChecklistWhite.svg?url';
import currenciesIllustrationUrl from '@/illustrations/Currencies.svg?url';
import flyingRocketIllustrationURL from '@/illustrations/FlyingRocket.svg?url';
import girlCodingIllustrationURL from '@/illustrations/GirlCoding.svg?url';
import mobileWithFloatingAppIllustrationURL from '@/illustrations/MobileWithFloatingApp.svg?url';
import personRelaxingIllustrationURL from '@/illustrations/PersonRelaxing.svg?url';
import pisIllustrationUrl from '@/illustrations/PIS.svg?url';
import signUpScreenIllustrationURL from '@/illustrations/SignUpScreen.svg?url';

export const API_DEFINITIONS = [
  {
    name: 'Currencies',
    description: (
      <>
        Get indicative conversion rates for 50+ currencies wherever you want it.
      </>
    ),
    productPageUrl: '/currencies',
    documentationUrl: '/documentation/currencies/@default/@latest',
    illustration: (
      <img
        alt="People taking out EUR and USD"
        src={currenciesIllustrationUrl}
      />
    ),
  },
  {
    name: 'Account Information Service',
    description: (
      <>
        Get an overview of the accounts for individual and corporate customers
        along with available balances and transactions. Applies for accounts
        from where a payment can be made.
      </>
    ),
    productPageUrl: '/accounts',
    documentationUrl: '/documentation/psd2-accounts/@default/@latest',
    illustration: (
      <img alt="Money in the piggy bank" src={aisIllustrationUrl} />
    ),
  },
  {
    name: 'Payment Initiation Service',
    description: (
      <>
        Initiate domestic and international payments for individuals and
        corporate customers&apos; accounts. Delete future payments along with
        initiation of standing orders.
      </>
    ),
    productPageUrl: '/payments',
    documentationUrl: '/documentation/psd2-payments/@default/@latest',
    illustration: <img alt="Money in the air" src={pisIllustrationUrl} />,
  },
];

export const GETTING_STARTED_STEPS = [
  {
    title: 'Sign up',
    illustration: signUpScreenIllustrationURL,
    content: (
      <>
        Sign up for an account using your email address. It&apos;s free, no
        strings attached. You will need to verify your account with the
        verification code sent to your email.
      </>
    ),
  },

  {
    title: 'Create an app',
    illustration: mobileWithFloatingAppIllustrationURL,
    content: (
      <>
        Click on Create app in My profile page or generate PSD2 test certificate
        if you want to test out{' '}
        <Link element={RouterLink} to="/accounts">
          Account Information
        </Link>{' '}
        or{' '}
        <Link element={RouterLink} to="/payments">
          Payment Initiation
        </Link>{' '}
        services in sandbox. Read more about it here.
      </>
    ),
  },

  {
    title: 'Test APIs',
    illustration: girlCodingIllustrationURL,
    content: (
      <>
        To get access to an API, you need to attach the API to your app. You
        will be prompted to select which APIs you want to attach when you are
        creating your app. Once the app is created, keys are generated and your
        test keys will be automatically authorised to fetch data from sandbox
        environment. To{' '}
        <Link element={RouterLink} to="/getting-started">
          access the PSD2 sandbox
        </Link>{' '}
        use your newly generated PSD2 test certificate.
      </>
    ),
  },

  {
    title: 'Request live mode',
    illustration: checkListIllustrationURL,
    content: (
      <>
        When you feel ready, live mode is the next step to get an access to real
        data. Navigate to the app you want You can apply for access by going to
        the app page of the app you want access for. Scroll down to the bottom
        of page, find the API you want live access to and click on the{' '}
        <b>Request live access</b> -button. Remember to enable MFA for your
        account before you request access to live mode data. For PSD2 APIs,
        please follow{' '}
        <Link element={RouterLink} to="/getting-started">
          this guide
        </Link>
        .
      </>
    ),
  },
  {
    title: 'Wait for approval',
    illustration: personRelaxingIllustrationURL,
    content: (
      <>
        After all necessary information is provided, and your request has been
        submitted, you can sit back and relax. Once your access request has been
        approved, you&apos;re ready to make requests to the live API using your
        live mode credentials.
      </>
    ),
  },
  {
    title: 'Go live',
    illustration: flyingRocketIllustrationURL,
    content: (
      <>
        Easy as that, you are now ready to go live with your new app! Want to
        know more? Feel free to{' '}
        <Link element={RouterLink} to="/help-center">
          get in touch
        </Link>{' '}
        with us.
      </>
    ),
  },
];
