import { Div, Grid, H3, Img, P } from '@dnb/eufemia';
import GridItem from '@dnb/eufemia/components/grid/Item';
import styled from '@emotion/styled';

import documents from '../../../../../../illustrations/Documents.svg?url';

const TimelineWrapper = styled(Div)`
  background-color: var(--color-pistachio);
  margin-bottom: 1rem;
  border-radius: 4px;
  padding: 0.5rem;
`;

const InformationWrapper = styled(Div)`
  padding: 0rem 1rem 1rem 0;
`;

export default function PageTimelineContent() {
  return (
    <TimelineWrapper>
      <Grid.Container columnGap="small" columns={2}>
        <GridItem span={[1, 6]}>
          <Img
            alt="Illustration of documents"
            space={{ top: '2rem' }}
            src={documents}
            width={95}
          />
        </GridItem>
        <GridItem span={[7, 12]}>
          <InformationWrapper>
            <H3 space={{ bottom: 'small' }}>
              Before you get started with our regulatory APIs
            </H3>
            <P>
              PSD2 dedicated interface can only be used by licensed third party
              providers (TPPs) possessing a PSD2 compliant Qualified Certificate
              for Website Authentication (QWAC)
            </P>
          </InformationWrapper>
        </GridItem>
      </Grid.Container>
    </TimelineWrapper>
  );
}
