import {
  InteractionRequiredAuthError,
  PublicClientApplication,
  type RedirectRequest,
} from '@azure/msal-browser';
import { hasOwnProperty } from '@portals/shared-frontend/utils';

export const loginRequest: RedirectRequest = {
  scopes: ['openid', 'profile', 'email'],
  domainHint: 'dnb.no',
};

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${AZURE_TENANT}`,
    redirectUri: AZURE_REDIRECT_URI,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
});

export async function signInWithAzure(): Promise<void> {
  localStorage.setItem('internalUser', 'true');
  return msalInstance.loginRedirect(loginRequest);
}

export async function logoutWithAzure(): Promise<void> {
  localStorage.removeItem('aad_token'); // TODO: Remove when old frontend is deleted
  return msalInstance.logoutRedirect();
}

export async function getAzureToken(): Promise<string | null> {
  const [account] = msalInstance.getAllAccounts();

  if (!account) return null;

  const claims = account.idTokenClaims;
  let forceRefresh = false;

  if (
    hasOwnProperty(claims, 'exp') &&
    typeof claims.exp === 'number' &&
    claims.exp * 1000 - 60_000 < Date.now()
  ) {
    // Force refresh if token expires in less than 60 seconds
    // TODO: Remove this hack by using an access token with a custom scope
    // The ID token is not renewed because of a bug in MSAL, but an access token is preferred anyways.
    // Bug: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206
    forceRefresh = true;
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
      forceRefresh,
    });
    return response.idToken;
  } catch (error) {
    // Catch interaction_required errors and call interactive method to resolve
    if (error instanceof InteractionRequiredAuthError) {
      try {
        await msalInstance.acquireTokenRedirect({
          ...loginRequest,
          account,
        });
      } catch (error_) {
        // eslint-disable-next-line no-console
        console.log(error_);
      }
    } else {
      //TODO check for better ways
      localStorage.clear();
      location.reload();
    }

    return null;
  }
}

export function isSignedInWithAzure(): boolean {
  return msalInstance.getAllAccounts().length > 0;
}
