export interface ApiFromCommonRepoDto {
  slug: string;
  description: string | null;
  title: string;
  classification: string;
  versions: VersionFromCommonRepoDto[];
}

export interface VersionFromCommonRepoDto {
  apiLink: string;
  version: string | null;
  isLatest: boolean;
  environment: string;
}

//Follow the sequence ['design', 'development', 'testing', 'production']. ie keep the important environment at last. this is just of handling default envs and data inconsistencies (if any) from gitlab repo
export const COMMON_REPO_ENVIRONMENTS = [
  'design',
  'development',
  'testing',
  'production',
];
