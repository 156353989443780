import { Grid, InfoCard } from '@dnb/eufemia';
import {
  type DashboardItem as DashboardItemProps,
  type DataType,
  Type,
} from '@portals/shared/admin/DashboardDto';

import BarChart from '../Charts/BarChart';
import LineChart from '../Charts/LineChart';
import PieChart from '../Charts/PieChart';
import Stats from '../Stats';

type DashboardProps = {
  data: DashboardItemProps[];
};

type ItemComponentProps = {
  data?: DataType | null;
};

function ItemComponent({ data }: ItemComponentProps) {
  if (!data) {
    return (
      <InfoCard text="Whops, data provided does not match any structure we know" />
    );
  }
  const { type } = data;
  switch (type) {
    case Type.line: {
      return (
        <LineChart
          datasets={data.datasets}
          labels={data.labels}
          title={data.title}
          type={Type.line}
        />
      );
    }
    case Type.pie:
    case Type.doughnut: {
      return (
        <PieChart
          datasets={data.datasets}
          labels={data.labels}
          title={data.title}
          type={data.type}
        />
      );
    }
    case Type.bar: {
      return (
        <BarChart
          datasets={data.datasets}
          labels={data.labels}
          title={data.title}
          titlePosition={data.titlePosition}
          type={Type.bar}
        />
      );
    }
    case Type.info: {
      return <Stats data={{ ...data }} type={data.type} />;
    }
    default: {
      return (
        <InfoCard text="Whops, data provided does not match any structure we know" />
      );
    }
  }
}

function DashboardItem({ small, medium, large, data }: DashboardItemProps) {
  return (
    <Grid.Item span={{ large, medium, small }}>
      <ItemComponent data={data} />
    </Grid.Item>
  );
}

export default function Dashboard({ data }: DashboardProps) {
  return (
    <Grid.Container bottom="small" columnGap rowGap>
      {data.map((child, index) => (
        <DashboardItem
          data={child.data}
          key={index}
          large={child.large}
          medium={child.medium}
          small={child.small}
          title={child.title}
        />
      ))}
    </Grid.Container>
  );
}
