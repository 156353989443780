import { Button, Flex, Icon, Link, P } from '@dnb/eufemia';
import { information_bubble_medium } from '@dnb/eufemia/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Container from '@/components/Container';
import HeroPage from '@/components/HeroPage';
import InformationBox from '@/components/InformationBox/InformationBox';
import PageMarkdownContent from '@/components/PageMarkdownContent';

import style from './index.module.css';

import Inbox from '@/illustrations/ManSearching.svg?url';

export default function LandingNew(): JSX.Element {
  const navigate = useNavigate();

  const informationDescription = (
    <P>
      Check out our{' '}
      <Link element={RouterLink} to="/help-center/faq/psd2">
        FAQ
      </Link>{' '}
      or{' '}
      <Link element={RouterLink} to="/help-center">
        get in touch
      </Link>{' '}
      with us
    </P>
  );

  return (
    <HeroPage
      heroIllustration={require('@/illustrations/DNB_Supergraphics_front_page.avif?url')}
      noContainer
      subtitle="Follow these steps to start consuming our APIs"
      title="Getting started"
    >
      <Container centered>
        <Container className={style['InformationBox']}>
          <Flex.Horizontal align="center" gap="small" justify="space-around">
            <Icon icon={information_bubble_medium} />
            <P className={style['InformationParagraph']}>
              This getting started guide is not relevant if you are looking to
              consume PSD2 APIs.For information on PSD2 APIs, see{' '}
              <Link element={RouterLink} to="/explorer/apis/regulatory">
                Regulatory APIs
              </Link>
            </P>
          </Flex.Horizontal>
        </Container>
        <Container centered>
          <PageMarkdownContent
            path={`/content/tpp/${DEVELOPER_PORTAL_TEAM_ID}/getting-started`}
          />
        </Container>
        <Container className={style['HelpBox']}>
          <Flex.Horizontal align="center" gap="small" justify="space-around">
            <Icon icon={information_bubble_medium} />
            <P className={style['InformationParagraph']}>
              For more detailed information about a particular API, please visit
              the APIs documentatation
            </P>
          </Flex.Horizontal>
          <Flex.Horizontal align="flex-start" space="small">
            <Button
              onClick={() => navigate('/explorer/apis/regulatory')}
              variant="secondary"
            >
              Regulatory APIs
            </Button>
            <Button
              onClick={() => navigate('/explorer/apis/commercial')}
              variant="secondary"
            >
              Commercial APIs
            </Button>
          </Flex.Horizontal>
        </Container>

        <InformationBox
          description={informationDescription}
          illustration={Inbox}
          title="Still lost?"
        />
      </Container>
    </HeroPage>
  );
}
