import { Button, Flex, P } from '@dnb/eufemia';

import Page from '@/components/Page';

export default function DebugDuckyLoginPrompt() {
  return (
    <Page title="Debug Ducky">
      <P>To view this page you must be logged in as a DNB user.</P>
      <Flex.Container top={1}>
        <Flex.Item>
          <Button href="/sso">Login as DNB user</Button>
        </Flex.Item>
        <Flex.Item>
          <Button href="https://jobb.dnb.no">Apply for a job in DNB</Button>
        </Flex.Item>
      </Flex.Container>
    </Page>
  );
}
