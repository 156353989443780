import { z } from 'zod';

export interface ContentDto {
  id: string;
  slug: string;
  templateId: string;
  title: string;
  blob: FaqContentBlob | BasicContentBlob;
  parent: string;
  path: string;
  isPublic: boolean;
  publishedStatus: PUBLISHED_STATUS;
  createdBy: string | null;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string;
  teams: string[];
  organizations: string[];
  sortIndex: number;
  parentId: string | null;
}
export type ContentMetadataDto = Pick<
  ContentDto,
  | 'id'
  | 'slug'
  | 'templateId'
  | 'title'
  | 'parent'
  | 'path'
  | 'isPublic'
  | 'publishedStatus'
  | 'createdBy'
  | 'updatedBy'
  | 'createdAt'
  | 'updatedAt'
  | 'teams'
  | 'organizations'
  | 'sortIndex'
  | 'parentId'
>;

export interface ContentWithChildrenDto extends ContentDto {
  children: ContentDto[];
}

export enum PUBLISHED_STATUS {
  UNPUBLISHED = 0,
  DRAFT = 1,
  PUBLISHED = 2,
}

export const faqContentBlobSchema = z.object({
  content: z.string().optional(),
});

export const defaultContentBlobSchema = z.object({
  content: z.string().optional(),
});

export type BasicContentBlob = z.infer<typeof defaultContentBlobSchema>;
export type FaqContentBlob = z.infer<typeof faqContentBlobSchema>;

export const defaultContentFormInputSchema = z.object({
  id: z.string().uuid().optional(),
  title: z.string().min(1),
  slug: z.string().min(1),
  path: z.string().min(1),
  parent: z.string().min(1),
  isPublic: z.boolean(),
  blob: z.union([faqContentBlobSchema, defaultContentBlobSchema]),
  publishedStatus: z.nativeEnum(PUBLISHED_STATUS),
  templateId: z.string().uuid().optional(),
  teams: z.string().array(),
  organizations: z.string().array(),
  sortIndex: z.number(),
  apiId: z.string().uuid().nullable(),
  teamId: z.string().uuid().nullable(),
  parentId: z.string().uuid().nullable(),
});

export type CreateDefaultContentFormDto = z.infer<
  typeof defaultContentFormInputSchema
>;

export const deleteContentSchema = z.object({
  id: z.string().min(1),
});

export const defaultContentRecoverySchema = z.object({
  id: z.string().uuid(),
  title: z.string().min(1),
  slug: z.string().min(1),
  path: z.string().min(1),
  parent: z.string().min(1),
  isPublic: z.number(),
  blob: z.union([faqContentBlobSchema, defaultContentBlobSchema]),
  publishedStatus: z.nativeEnum(PUBLISHED_STATUS),
  templateId: z.string().uuid(),
  teams: z.string().array(),
  organizations: z.string().array(),
  createdBy: z.string().min(1),
  updatedBy: z.string().min(1),
  apiId: z.string().uuid().nullable(),
  teamId: z.string().uuid().nullable(),
  sortIndex: z.number(),
  parentId: z.string().uuid().nullable(),
});

export type DefaultContentRecoveryInput = z.infer<
  typeof defaultContentRecoverySchema
>;

export const advancedContentFormSchema = z.object({
  id: z.string().uuid().optional(),
  title: z.string().min(1),
  slug: z.string().min(1),
  path: z.string().min(1),
  parent: z.string().min(1),
  isPublic: z.boolean(),
  publishedStatus: z.nativeEnum(PUBLISHED_STATUS),
  blob: z.unknown(),
  templateId: z.string().uuid().optional(),
  teams: z.string().array(),
  organizations: z.string().array(),
  sortIndex: z.number(),
  teamId: z.string().uuid().nullable(),
  apiId: z.string().uuid().nullable(),
  parentId: z.string().uuid().nullable(),
});

export type AdvancedContentFormData = z.output<
  typeof advancedContentFormSchema
>;
