/* eslint-disable max-lines */
import { H1 } from '@dnb/eufemia';
import { useMedia } from '@dnb/eufemia/shared';
import { dereference } from '@portals/shared/common/open-api/resolver';
import type { OpenAPIV3 } from 'openapi-types';
import { useEffect, useMemo, useState } from 'react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import Markdown from '@/components/Markdown';
import { useScrollSpy } from '@/hooks/useScrollSpy';
import type {
  LinkType,
  SearchItem,
  SelectionType,
  TagType,
} from '@/pages/api-documentation/constants/types';
import Search from '@/pages/api-documentation/sections/reference/components/Search';

import Item from './components/Item';
import Menu from './components/Menu';
import MobileMenu from './components/MobileMenu';
import SecuritySchema from './components/SecuritySchema';

import { Page } from '@/pages/api-documentation/styles/Api.Reference.styles';

const ReferenceView = ({
  reference,
  compact = false,
}: {
  reference: OpenAPIV3.Document;
  compact?: boolean;
}) => {
  const {
    paths,
    tags: refTags,
    components,
    servers,
  } = useMemo(() => (reference && dereference(reference)) || {}, [reference]);

  const navigate = useNavigate();
  const [items, setItems] = useState<string[]>([]);
  const { isLarge } = useMedia();
  const [selected, setSelected] = useState<SelectionType>();
  const [menuItems, setMenuItems] = useState<Record<string, LinkType[]>>();
  const [mobileMenu, setMobileMenu] = useState(false);
  const selectedItem = useScrollSpy(items);
  const [tags, setTags] = useState<TagType[] | undefined>();
  const [search, setSearch] = useState<string[] | undefined>();
  const [searchItems, setSearchItems] = useState<SearchItem[] | undefined>();

  // JUST REMOVE THE MOBILE MENU IF WE HIT LARGE
  useEffect(() => {
    isLarge && setMobileMenu(false);
  }, [isLarge]);

  function navigateTo(hash = '', replace = true, jump = false) {
    navigate(window.location.pathname + hash, { replace: replace });
    if (jump) {
      document
        .getElementById(hash.replace('#', ''))
        ?.scrollIntoView({ behavior: 'auto' });
    }
    setMobileMenu(false);
  }

  useEffect(() => {
    if (selectedItem !== window.location.hash && selectedItem) {
      const splitUp = selectedItem?.split('/operation/');

      if (selectedItem.includes('tags')) {
        if (splitUp.length >= 2) {
          setSelected({
            category: splitUp[0].split('/')[1],
            operation: splitUp[1],
          });
        } else {
          setSelected({ category: splitUp[0].split('/')[1], operation: null });
        }
      } else {
        // USED FOR INTRO SECURITY SCHEMES
        setSelected({
          category: splitUp[0].split('/')[0],
          operation: splitUp[0].split('/')[1],
        });
      }
      navigateTo('#' + selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (items.length === 0) {
      const allItems: string[] = [];
      const allTags: TagType[] =
        (refTags &&
          refTags.map((tag) => {
            return {
              name: tag.name,
              href: tag.name.replaceAll(' ', '-'),
              description: tag?.description,
            };
          })) ||
        [];
      const allMenuItems: Record<string, LinkType[]> = {};
      const allSearchItems: SearchItem[] = [];

      // ADD ITEMS TO THE DISCOVERY LIST.
      allItems.push('introduction');
      if (components?.securitySchemes) {
        allItems.push('security-schemes');
        Object.keys(components?.securitySchemes).map((schema) => {
          allItems.push(`security-schemes/${schema}`);
        });
      }

      Object.entries(paths).forEach((path) => {
        const newMenuItem: LinkType[] = [];
        let newMenuItemKey = '';

        Object.entries(path[1] ?? {}).forEach((action) => {
          if (action[0] !== 'parameters') {
            const operationObject = action[1] as OpenAPIV3.OperationObject;
            const operationHref =
              (operationObject.tags &&
                operationObject?.tags[0].replaceAll(' ', '-')) ||
              '';
            const key = `tags/${
              operationHref ? operationHref + '/' : ''
            }operation/${action[0]}${path[0]}`
              .replaceAll('{', '')
              .replaceAll('}', '');

            if (
              operationObject &&
              operationObject.tags &&
              operationObject.tags.length > 0
            ) {
              !allTags.some(
                (verify) =>
                  operationObject.tags &&
                  verify.name === operationObject.tags[0],
              ) &&
                allTags.push({
                  name: operationObject?.tags[0],
                  href: operationHref,
                });
            }
            newMenuItemKey =
              (operationObject.tags &&
                operationObject.tags[0].replaceAll(' ', '-')) ||
              '';

            allItems.push(
              'tags' +
                (newMenuItemKey
                  ? '/' + newMenuItemKey.replaceAll(' ', '-')
                  : ''),
            );
            allItems.push(key);
            allSearchItems.push({
              name: `${action[0]}${path[0]}`
                .replaceAll('{', '')
                .replaceAll('}', ''),
              description:
                operationObject.summary + ' ' + operationObject.description,
            });
            newMenuItem.push({
              path: `${action[0]}${path[0]}`
                .replaceAll('{', '')
                .replaceAll('}', ''),
              operator: action[0],
              description: operationObject.description ?? '',
              name: operationObject.summary ?? '',
            });
          }
        });

        if (Object.keys(allMenuItems).includes(newMenuItemKey)) {
          allMenuItems[newMenuItemKey] = [
            ...allMenuItems[newMenuItemKey],
            ...newMenuItem,
          ];
        } else {
          allMenuItems[newMenuItemKey] = newMenuItem;
        }
      });
      setTags(allTags);
      setSearchItems(allSearchItems);
      setMenuItems(allMenuItems);
      setItems(allItems);

      window.setTimeout(() => {
        navigateTo(window.location.hash);
      }, 500);
    }
  }, [tags, components?.securitySchemes, items, paths, refTags]);

  return (
    <Page>
      <div className={`__left${mobileMenu ? ' active' : ''}`}>
        <div className="sticker">
          <Search items={searchItems} searchResult={setSearch} />

          <div className="scroll-content">
            {tags !== undefined && (
              <Menu
                compact={compact}
                components={components}
                menuItems={menuItems}
                mobile={!isLarge}
                navigateTo={navigateTo}
                paths={paths}
                search={search}
                selected={selected}
                tags={tags}
              />
            )}
          </div>
        </div>
      </div>
      <div className="content">
        {reference?.info?.description && (
          <section id="introduction">
            <Markdown>{reference?.info?.description}</Markdown>
          </section>
        )}
        {components?.securitySchemes && (
          <SecuritySchema data={components?.securitySchemes} />
        )}
        {(Boolean(tags !== undefined && tags?.length !== 0) && (
          <>
            {tags?.map((link) => (
              <Fragment key={`tags/${link.href}`}>
                <H1 id={`tags/${link.href}`}>{link.name}</H1>
                {link?.description && <Markdown>{link.description}</Markdown>}

                {Object.entries(paths || {}).map((path) => {
                  const actions = path[1] as OpenAPIV3.PathsObject;

                  return (
                    <>
                      {Object.entries(actions).map((action) => {
                        const item = action[1] as OpenAPIV3.OperationObject;

                        if (
                          item &&
                          action[0] !== 'parameters' &&
                          item.tags &&
                          item.tags[0] === link.name
                        ) {
                          return (
                            <Item
                              action={action[0]}
                              compact={compact}
                              data={item}
                              parameters={
                                actions
                                  ? (actions[
                                      'parameters'
                                    ] as OpenAPIV3.ParameterObject[])
                                  : undefined
                              }
                              path={path[0]}
                              security={{
                                security: reference?.security,
                                schemas: components?.securitySchemes,
                              }}
                              servers={servers}
                            />
                          );
                        } else {
                          return;
                        }
                      })}
                    </>
                  );
                })}
              </Fragment>
            ))}

            {Object.entries(paths || {}).map((path) => {
              const actions = path[1] as OpenAPIV3.PathsObject;

              return (
                <>
                  {Object.entries(actions).map((action) => {
                    const item = action[1] as OpenAPIV3.OperationObject;

                    if (
                      item &&
                      action[0] !== 'parameters' &&
                      !Object.keys(item).includes('tags')
                    ) {
                      return (
                        <Item
                          action={action[0]}
                          compact={compact}
                          data={item}
                          parameters={
                            actions
                              ? (actions[
                                  'parameters'
                                ] as OpenAPIV3.ParameterObject[])
                              : undefined
                          }
                          path={path[0]}
                          security={{
                            security: reference?.security,
                            schemas: components?.securitySchemes,
                          }}
                          servers={servers}
                        />
                      );
                    } else {
                      return;
                    }
                  })}
                </>
              );
            })}
          </>
        )) ||
          (paths && (
            <>
              {Object.keys(paths).map((path) => {
                const actions = paths[path] as OpenAPIV3.PathsObject;

                return (
                  <>
                    {Object.keys(actions).map((action) => {
                      if (action === 'parameters') {
                        return;
                      } else {
                        const pathItem = actions[
                          action
                        ] as OpenAPIV3.PathItemObject;

                        return (
                          <div>
                            <Item
                              action={action}
                              compact={compact}
                              data={pathItem}
                              parameters={
                                Object.keys(actions).includes('parameters')
                                  ? (actions[
                                      'parameters'
                                    ] as OpenAPIV3.ParameterObject[])
                                  : pathItem.parameters
                              }
                              path={path}
                              security={{
                                security: reference?.security,
                                schemas: components?.securitySchemes,
                              }}
                              servers={servers}
                            />
                          </div>
                        );
                      }
                    })}
                  </>
                );
              })}
            </>
          ))}
      </div>
      <MobileMenu mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
    </Page>
  );
};

export default ReferenceView;
