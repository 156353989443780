import type {
  UserSignUpConfirmationInputDto,
  UserSignUpInputDto,
  UserUpdateNameDto,
} from '@portals/shared/portal/UserDto';

import { request } from '@/request';

export async function createUser(data: UserSignUpInputDto): Promise<void> {
  return request('/user', 'post', data);
}

export async function verifyEmail(
  data: UserSignUpConfirmationInputDto,
): Promise<void> {
  return request('/confirmation', 'post', data);
}

export async function enableMfa(): Promise<string> {
  return request('/mfa/enable', 'post');
}

export function verifyMfa(token: string): Promise<void> {
  return request('/mfa/verify', 'post', { token });
}

export function disableMfa(): Promise<void> {
  return request('/mfa/disable', 'patch');
}

export function generateRecoveryCodes(): Promise<string[]> {
  return request<string[]>('/mfa/regenerate-codes', 'post');
}

export function updateUser(
  data: UserUpdateNameDto,
): Promise<UserUpdateNameDto> {
  return request<UserUpdateNameDto>('/user/edit-name', 'patch', data);
}

export async function deleteUser(): Promise<void> {
  return request('/user', 'delete');
}

export async function acknowledgeOrganization(): Promise<boolean> {
  const response = await request<{
    organizationAcknowledged: boolean;
  }>('/user/acknowledge-org', 'patch');

  return response.organizationAcknowledged;
}

export function acceptTermsOfUse(): Promise<{
  termsOfUseAcceptedAt: Date;
}> {
  return request<{ termsOfUseAcceptedAt: Date }>('/user/toc', 'patch');
}

export function postUserLogin(): Promise<void> {
  return request('/user/post-logged-in', 'patch');
}

// /apis/:apiId/favourite'

export function createApiFavourite(apiId: string): Promise<void> {
  return request(`/apis/${apiId}/favourite`, 'post');
}

export function deleteApiFavourite(apiId: string): Promise<void> {
  return request(`/apis/${apiId}/favourite`, 'delete');
}
