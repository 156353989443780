import { Card, H3, Space } from '@dnb/eufemia';
import { type PieChart, Type } from '@portals/shared/admin/DashboardDto';
import { Doughnut, Pie } from 'react-chartjs-2';

import { pickColors } from '../colors';

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PieChart({
  labels,
  datasets,
  type = Type.pie,
  title,
}: PieChart) {
  const { selectedColors } = pickColors(labels?.length);
  const data = datasets?.map((dataset) => ({
    ...dataset,
    backgroundColor: selectedColors,
    borderColor: 'white',
    borderWidth: 2,
  }));

  return (
    <Card>
      <Space bottom="small">
        <H3>{title}</H3>
      </Space>

      {type === Type.pie ? (
        <Pie
          data={{
            labels,
            datasets: data,
          }}
        />
      ) : (
        <Doughnut
          data={{
            labels,
            datasets: data,
          }}
        />
      )}
    </Card>
  );
}
