import styled from '@emotion/styled';

import { colors } from '@/pages/api-documentation/styles/Api.global.styles';

export const ServerLink = styled.div`
  background-color: ${(props) => props?.theme?.colors.backgroundCard};
  width: 100%;
  padding: var(--spacing-small);
  border: 1px solid var(--color-black-8);
  border-radius: 4px;
  margin-top: var(--spacing-small);

  .desc {
    width: 90%;
  }

  .wrapper {
    justify-content: space-between;
    align-items: center;
    display: flex;

    .link {
      font-size: var(--font-size-basis);
      width: 80%;

      em {
        margin-right: 4px;
        text-transform: uppercase;
        font-style: normal;
        font-weight: var(--font-weight-medium);
        color: ${colors['get']};

        &.post {
          color: ${colors['post']};
        }

        &.patch,
        &.put {
          color: ${colors['put']};
        }

        &.delete {
          color: ${colors['delete']};
        }
      }

      span {
        font-size: var(--font-size-x-small);
        color: var(--color-black-55);
      }
    }
  }

  .btn {
    &.copied {
      button {
        --border-color: var(--color-summer-green);
        color: var(--color-summer-green);
        &:hover {
          color: var(--color-summer-green);
          box-shadow: 0 0 0 0.125rem var(--color-summer-green);
        }
      }
    }
    &.error {
      button {
        --border-color: var(--color-fire-red);
        color: var(--color-fire-red);
        &:hover {
          color: var(--color-fire-red);
          box-shadow: 0 0 0 0.125rem var(--color-fire-red);
        }
      }
    }
  }
`;
