import styled from '@emotion/styled';

export const ResponseHeaderList = styled.ul`
  padding: 0;
  margin: 0;
  border: 1px solid var(--color-black-8);
  background-color: ${(props) => props?.theme?.colors.backgroundCard};
  list-style: none;

  li:nth-of-type(even) {
    background-color: ${(props) => props?.theme?.colors.backgroundCard2};
  }
`;
