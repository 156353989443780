import { type DependencyList, useCallback, useContext, useRef } from 'react';

import ErrorContext from './ErrorContext';
import useSafeState from './useSafeState';

/**
 * Hook that wraps async functions and exposes runtime information as state
 * Uses global error context to report errors
 */

type AnyAsyncFunction<T> = (...params: never[]) => Promise<T>;
type PromiseType<F extends AnyAsyncFunction<unknown>> = Awaited<ReturnType<F>>;

interface AsyncHook<T, F extends AnyAsyncFunction<T>> {
  execute(...params: Parameters<F>): Promise<void>;
  waiting: boolean;
  error: unknown;
  result: T | undefined;
}

export default function useAsync<
  F extends AnyAsyncFunction<T>,
  T = PromiseType<F>,
>(callback: F, dependencies: DependencyList): AsyncHook<T, F> {
  const called = useRef(0);
  const [, setGlobalError] = useContext(ErrorContext);
  const [error, setError] = useSafeState<unknown>();
  const [waiting, setWaiting] = useSafeState(false);
  const [result, setResult] = useSafeState<T>();

  const execute = useCallback(
    async (...params: Parameters<F>) => {
      try {
        called.current++;
        setWaiting(true);
        const result = await callback(...params);
        setResult(result);
      } catch (error) {
        setGlobalError(error);
        setError(error);
      } finally {
        if (--called.current === 0) {
          setWaiting(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...dependencies],
  );

  return {
    execute,
    result,
    waiting,
    error,
  };
}
