import { useIsAuthenticated } from '@azure/msal-react';
import { ErrorContext, useSafeState } from '@portals/shared-frontend/hooks';
import { useContext, useEffect, useRef, useState } from 'react';

import { isLoggedInWithCognito, pool } from '@/auth/cognito';
import { CELEBRUS_LOGIN_STATE_KEY } from '@/constants';

export default function useAuth() {
  const hasCognitoUser = !!pool.getCurrentUser();
  const isAzure = useIsAuthenticated();
  const [isCognito, setIsCognito] = useSafeState(false);
  const loadCount = useRef(0);
  const [waiting, setWaiting] = useState(true);
  const [, setError] = useContext(ErrorContext);

  useEffect(() => {
    if (!isAzure && hasCognitoUser) {
      loadCount.current++;
      isLoggedInWithCognito()
        .then(setIsCognito)
        .then(() => loadCount.current--)
        .then(() => setWaiting(loadCount.current !== 0))
        .catch(setError);
    } else if (loadCount.current === 0) {
      setWaiting(false);
    }
  }, [hasCognitoUser, setError, isAzure, setIsCognito]);

  useEffect(() => {
    if (!isCognito && !isAzure && loadCount.current === 0) {
      sessionStorage.removeItem(CELEBRUS_LOGIN_STATE_KEY);
    }
  }, [isAzure, isCognito]);

  return {
    isSignedIn: isAzure || isCognito,
    waiting,
  };
}
