import { Button, Div, Flex, Img, P } from '@dnb/eufemia';
import { chevron_right } from '@dnb/eufemia/icons';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import style from './InformationBox.module.css';

interface InformationBoxProps {
  title: string;
  description: string | ReactNode;
  illustration: string;
  buttonText?: string;
  reverseWrap?: boolean;
}

export default function InformationBox({
  title,
  description,
  buttonText,
  reverseWrap,
  illustration,
}: InformationBoxProps) {
  const navigate = useNavigate();
  return (
    <div className={style['InformationBoxContainer']}>
      <Flex.Horizontal gap="small" justify="center">
        <Flex.Horizontal
          align="center"
          className={
            reverseWrap
              ? classNames(style['InformationSection'], style['ReverseWrap'])
              : style['InformationSection']
          }
          justify="space-between"
        >
          <Div className={style['InformationContent']}>
            <P modifier="medium" style={{ marginBottom: '0.5rem' }}>
              {title}
            </P>
            <Div style={{ marginBottom: '1rem' }}>{description}</Div>
            {buttonText && (
              <Button
                icon={chevron_right}
                onClick={() => navigate('/news')}
                variant="secondary"
              >
                {buttonText}
              </Button>
            )}
          </Div>
          <Img alt="Image of Inbox" src={illustration} width={143} />
        </Flex.Horizontal>
      </Flex.Horizontal>
    </div>
  );
}
