import { Input } from '@dnb/eufemia';
import { debounce } from '@portals/shared-frontend/utils';
import { useEffect, useRef } from 'react';

import type { SearchItem } from '@/pages/api-documentation/constants/types';

import Fuse from 'fuse.js';

type FuseResult<T> = Fuse.FuseResult<T>;

type SearchProps = {
  items: SearchItem[] | undefined;
  searchResult: (query: string[] | undefined) => void;
};

const Search = ({ items, searchResult }: SearchProps) => {
  const fuseRef = useRef<Fuse<SearchItem> | null>(null); // Create a persistent ref for the 'fuse' object

  useEffect(() => {
    if (!fuseRef.current && items?.length !== 0) {
      fuseRef.current = new Fuse(items || [], {
        keys: ['name', 'description'],
        threshold: 0.3,
        ignoreLocation: true,
        shouldSort: false,
        useExtendedSearch: true,
      });
    }
  }, [items]);

  const handleChange = debounce(({ value }) => {
    if (value.length >= 3 && fuseRef.current) {
      const search: FuseResult<{ name: string; description: string }>[] =
        fuseRef.current.search(value);

      searchResult(search?.map((itemValue) => itemValue?.item.name));
    } else {
      searchResult(undefined);
    }
  }, 300); // Set the debounce time (in milliseconds) as per your requirement

  return (
    <div className="search-bar">
      <Input
        clear
        on_change={handleChange}
        placeholder="Search"
        type="search"
      />
    </div>
  );
};
export default Search;
