import { Button, Dialog, FormRow, FormSet, Input, P } from '@dnb/eufemia';
import { email_medium as EmailMediumIcon } from '@dnb/eufemia/icons';
import { useEufemiaForm } from '@portals/shared-frontend/hooks/useEufemiaForm';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { requestEditSubscriptionsEmail } from '@/api/subscriptions';
import Container from '@/components/Container';
import ModalPage from '@/components/ModalPage';
import useReturnTo from '@/hooks/useReturnTo';
import { useUser } from '@/hooks/useUser';

const unsubscribeFormSchema = z.object({
  email: z.string().email(),
});

export default function Unsubscribe(): JSX.Element {
  const { user, isLoggedIn } = useUser();
  const {
    submitting,
    register,
    handleSubmit,
    controller: { formError, getValue },
  } = useEufemiaForm(unsubscribeFormSchema, {
    email: isLoggedIn ? user?.email : '',
  });

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [returnTo, , clearReturnTo] = useReturnTo();

  const onSubmit = handleSubmit(
    useCallback(async (data) => {
      await requestEditSubscriptionsEmail(data.email);
      setOpen(true);
    }, []),
  );

  return (
    <ModalPage
      onClose={() => {
        navigate(returnTo ?? '/');
        clearReturnTo();
      }}
      subtitle="Unsubscribe"
      title="Subscriptions"
    >
      <Container size="small">
        <FormSet on_submit={onSubmit} vertical>
          <FormRow>
            <P>
              Enter the email that you subscribed with. We&apos;ll send you an
              email containing a link you can use to unsubscribe.
            </P>
            <Input
              icon={EmailMediumIcon}
              placeholder="Email address"
              size="large"
              stretch
              top="small"
              {...register.input('email')}
            />
          </FormRow>
          <FormRow>
            <Button
              disabled={submitting}
              size="large"
              status={formError}
              top="small"
              type="submit"
            >
              Send me a link
            </Button>
          </FormRow>
        </FormSet>
      </Container>
      <Dialog
        confirmText="Got it!"
        description={
          <P>
            We&apos;ve sent a confirmation email to {getValue('email')}. If you
            didn&apos;t receive it, check your spam filter.
          </P>
        }
        hideDecline
        onConfirm={() => {
          navigate(returnTo ?? '/');
          clearReturnTo();
        }}
        openState={open}
        title="Success! An email is on its way"
        variant="confirmation"
      />
    </ModalPage>
  );
}
