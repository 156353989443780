import styled from '@emotion/styled';

export const AuthenticationList = styled.ul`
  margin: 0 0 var(--spacing-small) 0;
  padding: 0;
  border: 1px solid var(--color-black-8);
  background-color: ${(props) => props?.theme?.colors.backgroundCard};

  list-style: none;

  li:nth-of-type(even) {
    background-color: ${(props) => props?.theme?.colors.backgroundCard2};
  }

  li {
    .schema-item {
      display: grid;
      grid-gap: var(--spacing-small);
      grid-template: auto/ 1fr 2fr;
      justify-items: flex-start;
      padding: var(--spacing-small);

      @media (max-width: 60em) {
        grid-template: auto/ 100%;
      }

      strong {
        font-family: DnbMono, Courier New, Courier, monospace;
        font-weight: var(--font-weight-bold);
      }
    }
  }
`;
