import Code from '@dnb/eufemia/elements/code/Code';

export const InlineCodeElementsWithLabel = ({
  label,
  content,
  className,
}: {
  label: string;
  content: string | string[];
  className?: string;
}) => {
  const contentArray = Array.isArray(content) ? content : [content];

  return (
    <div className={className}>
      <strong>{label}:</strong>
      {contentArray.map((item, index) => (
        <Code key={index} left="x-small">
          {item}
        </Code>
      ))}
    </div>
  );
};
