export const mq: Record<string, string> = {
  small: '@media(min-width: 40em)',
  medium: '@media(min-width: 60em)',
  large: '@media(min-width: 72em)',
  'x-large': '@media(min-width: 80em)',
  'xx-large': '@media(min-width: 90em)',
};

export const colors: Record<string, string> = {
  get: 'var(--color-summer-green)',
  post: 'var(--color-violet)',
  put: 'var(--color-sky-blue)',
  patch: 'var(--color-sky-blue)',
  delete: 'var(--color-fire-red)',
};
