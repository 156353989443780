import {
  Button,
  Checkbox,
  Dialog,
  FormLabel,
  FormRow,
  FormSet,
  FormStatus,
  HelpButton,
  Input,
  P,
  Textarea,
} from '@dnb/eufemia';
import { send as SendIcon } from '@dnb/eufemia/icons';
import { ATMOST_N } from '@portals/shared/common/messages';
import type { CurrentApiAndScopeEntitlementsDto } from '@portals/shared/portal/AppApiEntitlementDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { z } from 'zod';

import { requestLiveMode } from '@/api/app';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import ModalPage from '@/components/ModalPage';
import RequestSentSuccessModal from '@/pages/profile/apps/application/components/RequestSentSuccessModal';

const CURRENCIES_SLUG = 'currencies';

type RequestProductionAccessModalProps = {
  onClose: () => void;
  entitlement: CurrentApiAndScopeEntitlementsDto;
};

interface OrganizationType {
  kode: string;
  beskrivelse: string;
  _links: {
    self: {
      href: string;
    };
  };
}

interface Address {
  land: string;
  landkode: string;
  postnummer: string;
  poststed: string;
  adresse: string[];
  kommune: string;
  kommunenummer: string;
}

interface BrregResponse {
  organisasjonsnummer: string;
  navn: string;
  organisasjonsform: OrganizationType;
  postadresse: Address;
  registreringsdatoEnhetsregisteret: string;
  registrertIMvaregisteret: boolean;
  naeringskode1: {
    beskrivelse: string;
    kode: string;
  };
  antallAnsatte: number;
  forretningsadresse: Address;
  stiftelsesdato: string;
  registrertIForetaksregisteret: boolean;
  registrertIStiftelsesregisteret: boolean;
  registrertIFrivillighetsregisteret: boolean;
  konkurs: boolean;
  underAvvikling: boolean;
  underTvangsavviklingEllerTvangsopplosning: boolean;
  maalform: string;
  _links: {
    self: {
      href: string;
    };
  };
}

async function fetchOrganizationData(
  id: string,
): Promise<BrregResponse | false> {
  try {
    const response = await fetch(
      `https://data.brreg.no/enhetsregisteret/api/enheter/${id}`,
    );

    if (
      !response.ok ||
      !response.headers.get('content-type')?.includes('application/json')
    ) {
      return false;
    }
    const data = await response.json();
    return data;
  } catch {
    return false;
  }
}

const appApiEntitlementRequestSchema = z.object({
  useCase: z
    .string()
    .max(500, ATMOST_N(500))
    .min(1, 'A proper use case is required to get access to this API.'),
  termsOfUseAccepted: z
    .boolean()
    .optional()
    .refine((value) => value !== false, {
      message: 'You must accept the terms of use to proceed.',
    }),
});

const appApiEntitlementRequestCurrenciesSchema = z.object({
  useCase: z
    .string()
    .min(1, 'A proper use case is required to get access to this API.')
    .max(500, ATMOST_N(500)),
  organizationNumber: z
    .string()
    .min(9, 'An organization number is required to get access to this API.'),
  termsOfUseAccepted: z.boolean().refine((value) => value !== false, {
    message: 'You must accept the terms of use to proceed.',
  }),
});

export default function RequestAccessToPublicApiModal({
  onClose,
  entitlement,
}: RequestProductionAccessModalProps): JSX.Element {
  const isCurrencies = entitlement.api.slug === CURRENCIES_SLUG;
  const { id: appId } = useParams();
  const [showSuccessModal, setShowSucessModal] = useState(false);
  const [showTermsOfuse, setShowTermsOfuse] = useState(false);

  const {
    controller: { formError },
    controller,
    handleSubmit,
    register,
    submitting,
  } = useEufemiaForm(
    isCurrencies
      ? appApiEntitlementRequestCurrenciesSchema
      : appApiEntitlementRequestSchema,
    {
      termsOfUseAccepted: entitlement.api.termsOfUse ? false : undefined,
    },
  );

  const onSubmit = handleSubmit(async (data) => {
    let orgData: BrregResponse | null = null;

    if ('organizationNumber' in data) {
      const sanitizedNumber = data.organizationNumber.replaceAll(/[ .]/g, '');
      const validOrganization = await fetchOrganizationData(sanitizedNumber);

      if (!validOrganization) {
        controller.setError(
          'organizationNumber',
          'The organization number is invalid. It cannot be found in the Brønnøysund Register Center.',
        );
        return;
      }
      orgData = validOrganization;
    }

    const orgComment = orgData
      ? `Name: ${orgData.navn}\nOrganization Number: ${orgData.organisasjonsnummer}\nOrganization Form: ${orgData.organisasjonsform.beskrivelse}\nLink: https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${orgData.organisasjonsnummer}`
      : '';

    const useCase =
      isCurrencies && orgData
        ? `${data.useCase}\n\n${orgComment}`
        : data.useCase;

    if (appId) {
      await requestLiveMode(appId, {
        apiId: entitlement.api.id,
        liveMode: true,
        scopeIds: [],
        termsOfUseAccepted: data.termsOfUseAccepted,
        useCase,
      });
      setShowSucessModal(true);
    }
  });

  const _onClose = () => {
    mutate(`/apps/${appId}`);
    setShowSucessModal(false);
    onClose();
  };

  if (showSuccessModal) {
    return <RequestSentSuccessModal onClose={_onClose} />;
  }

  return (
    <ModalPage
      actualModal
      onClose={onClose}
      subtitle="Please describe your use case"
      title="Live mode request"
    >
      {submitting && <LoadingModal />}
      <Container size="large">
        <FormSet on_submit={onSubmit} vertical>
          <FormRow>
            <FormLabel>
              We need a better understanding of your use case with the{' '}
              {entitlement.api.name} API. Please fill it in briefly below.
            </FormLabel>
            <Textarea
              placeholder="Use case"
              rows={5}
              stretch
              {...register.textarea('useCase')}
            />
          </FormRow>

          {isCurrencies && (
            <FormRow direction="vertical" top="small">
              <Input
                label="Organization VAT number"
                size="medium"
                stretch
                suffix={
                  <HelpButton
                    size="small"
                    title="Why Organization VAT number?"
                    tooltip="Why do I need Organization VAT number?"
                  >
                    The Currencies API is exclusively available to
                    Norwegian-registered corporations. Therefore, you must
                    supply a valid Norwegian organization VAT number.
                  </HelpButton>
                }
                {...register.input('organizationNumber')}
              />
            </FormRow>
          )}

          {entitlement.api.termsOfUse && (
            <FormRow top="small">
              <Checkbox
                label={
                  <>
                    I agree to the{' '}
                    <Button
                      id="terms-of-use"
                      on_click={() => setShowTermsOfuse(true)}
                      text={entitlement.api.name}
                      variant="tertiary"
                    />
                    <Dialog
                      omitTriggerButton
                      onClose={() => setShowTermsOfuse(false)}
                      openState={showTermsOfuse}
                      title={`Terms of use for the ${entitlement.api.name} API`}
                    >
                      {entitlement.api.termsOfUseUpdatedAt && (
                        <P bottom="small" size="small">
                          Last updated:{' '}
                          {new Date(
                            entitlement.api.termsOfUseUpdatedAt,
                          ).toUTCString()}
                        </P>
                      )}
                      <P>{entitlement.api.termsOfUse}</P>
                    </Dialog>{' '}
                    API terms of use
                  </>
                }
                {...register.checkbox('termsOfUseAccepted')}
              />
            </FormRow>
          )}
          <FormRow top="large">
            <Button icon={SendIcon} icon_position="left" type="submit">
              Send request
            </Button>
          </FormRow>
          {formError && (
            <FormRow top="small">
              <FormStatus>{formError}</FormStatus>
            </FormRow>
          )}
        </FormSet>
      </Container>
    </ModalPage>
  );
}
