import { Anchor, Button, Card, Dialog, Flex, Hr, Tag } from '@dnb/eufemia';
import { star as StarIcon, view as ViewIcon } from '@dnb/eufemia/icons';
import { EventDto } from '@portals/shared/portal/EventDto';
import { Link } from 'react-router-dom';

import style from './index.module.css';

interface EventCardProps {
  event: EventDto;
}

export default function EventCard({ event }: EventCardProps) {
  return (
    <Card className={style['Card']} stack>
      <Flex.Horizontal justify="space-between">
        <Anchor element={Link} target="_blank" to={`/events/${event.slug}`}>
          {event.name}
        </Anchor>
        <Button icon={StarIcon} />
      </Flex.Horizontal>
      <Flex.Item>
        <Tag text={event.classification} />
      </Flex.Item>
      <Flex.Item>
        <Hr className={style['Divider']} />
        <Dialog
          maxWidth="60rem"
          triggerAttributes={{
            text: 'Quick view',
            variant: 'tertiary',
            icon: ViewIcon,
          }}
        />
      </Flex.Item>
    </Card>
  );
}
