import type { Theme } from '@emotion/react';
import styled from '@emotion/styled';

type CompactProps = {
  compact?: boolean;
  expanded?: boolean;
  theme?: Theme;
};

export const ItemWrapper = styled.div<CompactProps>`
  .compact {
    .item-content {
      display: none;

      h3 {
        margin-top: 0;
      }

      &.expanded {
        display: block;
        border: 1px solid var(--color-black-8);
        background-color: ${(props) => props?.theme?.colors.backgroundCard2};
        padding: 1.6rem;
        border-top: 0;
        margin: -16px 0 2rem;
      }
    }
  }

  h3 {
    font-size: ${(props: CompactProps) =>
      props?.compact ? 'var(--font-size-medium)' : 'var(--font-size-large)'};
    line-height: var(--line-height-basis);
    margin: var(--spacing-large) 0 var(--spacing-small) 0;
    font-weight: var(--font-weight-regular);
  }
`;

export const Header = styled.h2<CompactProps>`
  padding: ${(props: CompactProps) => (props?.compact ? '1rem' : '0')};
  ${(props: CompactProps) =>
    props?.compact
      ? '  border: 1px solid var(--color-black-8);background-color: white;border-radius: 5px;'
      : ''};
  font-size: ${(props: CompactProps) =>
    props?.compact ? 'var(--font-size-small)' : 'var(--font-size-large)'};
  line-height: var(--line-height-basis);
  ${(props: CompactProps) =>
    props?.compact
      ? 'background-color: ' + props?.theme?.colors.backgroundCard
      : ''};
  font-weight: inherit;
  display: flex;
  position: relative;
  cursor: pointer;
  flex-direction: row;
  align-items: center;

  svg {
    position: absolute;
    right: 1.2rem;
  }

  &:hover {
    ${(props: CompactProps) =>
      props?.compact && !props?.expanded
        ? 'border-color: var(--color-sea-green);box-shadow: 0 0 0 2px var(--color-sea-green);'
        : ''}
  }

  span {
    font-weight: var(--font-weight-medium);
  }
`;
