import { Div, H2 } from '@dnb/eufemia';
import { isInternalUser } from '@portals/shared-frontend/utils';
import { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';

import FeedbackModule from '@/components/FeedbackModule';
import LoadingModal from '@/components/LoadingModal';
import Page from '@/components/Page';
import { TheDuck } from '@/components/TheDuck';
import { useUser } from '@/hooks/useUser';
import DebugDuckyLoginPrompt from '@/pages/debug-ducky/DebugDuckyLoginPrompt';

import './index.css'; // Import CSS file for styling

import { BreadCrumb } from './BreadCrumb.tsx';

const durationOfDuckMove = 1000;

export default function DebugDuckyPage() {
  const { user, isLoggedIn, isLoading } = useUser();
  const [position, setPosition] = useState({
    x: window.innerWidth - 140,
    y: window.innerHeight - 700,
  });
  const [isBreadcrumb, setIsBreacrumb] = useState(false);
  const handleMove = (event: { clientX: number; clientY: number }) => {
    setPosition({ x: event.clientX, y: event.clientY });
    setIsBreacrumb(true);
    setTimeout(() => setIsBreacrumb(false), durationOfDuckMove);
  };
  if (isLoading) return <LoadingModal />;

  if (!isLoggedIn || !isInternalUser(user?.email))
    return <DebugDuckyLoginPrompt />;

  return (
    <Div onClick={handleMove}>
      <Page title="The Life story of Debug Ducky">
        {isBreadcrumb && <BreadCrumb x={position.x} y={position.y} />}
        <div className="moveable-component">
          <TheDuck
            animate={!isBreadcrumb}
            durationOfDuckMove={durationOfDuckMove}
            x={position.x}
            y={position.y}
          />
        </div>
        <Div className="text-wrapper">
          <H2 bottom="small">Chapter. 1</H2>

          <TypeAnimation
            repeat={0}
            sequence={[
              `In the heart of the digital forest, where pixels danced and code whispered secrets, there lived a rubber duck named Quacky. His bright yellow feathers stood out against the monochrome landscape, and his round eyes held the wisdom of countless debugging sessions.\n
           Quacky had a peculiar talent: he could sense frustration. Whenever a developer hit a snag—a stubborn bug, a cryptic error message, or an elusive API endpoint—Quacky waddled over. His tiny beak quivered with anticipation.\n
           “Quack, quack!” he’d say, perching on the edge of the monitor. The developer would glance at him, eyebrows raised.\n
           “What’s bothering you, my friend?” Quacky would inquire, as if he understood every line of code. And perhaps he did. His rubbery presence seemed to unravel knots in logic.\n
           “This API call,” the developer would confess, “it’s driving me quackers!”\n
           Quacky would nod sagely. “Ah, APIs—they’re like riddles. But fear not! Every bug is a breadcrumb leading to a solution.”\n
           And so, Quacky became the unofficial mascot of the developer portal. His image adorned error pages, loading screens, and documentation. When a developer faced a syntax error, Quacky would quack encouragement:\n
           “Remember, even ducks stumble on their webbed feet sometimes!”\n
           His advice was simple yet profound. “Debugging,” he’d say, “is like chasing fireflies. Patience and persistence light the way.”\n
           Quacky’s fame spread. Soon, developers from distant servers sought his counsel. They’d send virtual breadcrumbs—tiny snippets of gratitude. Quacky collected them in a pixelated jar.\n
           “Quack, quack!” he’d reply, flapping his wings. “Keep coding, my friends! You’re building digital galaxies, one line at a time.”\n
           And so, Quacky’s quirky quest continued. He perched on error logs, quacked at infinite loops, and whispered encouragement to weary programmers. His legacy? Not lines of code, but smiles and camaraderie.\n
           “Why a rubber duck?” someone asked.\n
           Quacky tilted his head. “Because,” he replied, *“sometimes all you need is a little quack to conquer the code.”\n
           And that, my fellow adventurers, is how Quacky the Rubber Duck became a legend—a beacon of whimsy, a symbol of resilience, and the quackiest companion a developer could ask for.\n
           And so ends our tale. May your bugs be few, your logic flawless, and your rubber ducks ever vigilant. 🦆✨`,
            ]}
            style={{ whiteSpace: 'pre-line', fontSize: '20px' }}
          />
          <FeedbackModule />
        </Div>
      </Page>
    </Div>
  );
}
