import Cognito, {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  type IAuthenticationCallback,
} from 'amazon-cognito-identity-js';

import { request } from '../request';

export interface User extends CognitoUser {
  challengeName?: string;
  challengeParam?: string;
}

export interface Credentials {
  username: string;
  password: string;
  password_1?: string;
  password_2?: string;
  verification?: string;
}

export interface MfaInput {
  user: CognitoUser;
  code: string;
  type: 'RECOVERY_CODE' | 'MFA';
}

export const pool = new CognitoUserPool({
  ClientId: COGNITO_APP_CLIENT_ID,
  UserPoolId: COGNITO_USER_POOL_ID,
});

/**
 * Callbacks for cognito calls
 */

function getCallbacks(
  user: User,
  resolve: (value: User) => void,
  reject: (error: unknown) => void,
): IAuthenticationCallback {
  return {
    customChallenge(param) {
      user.challengeName = 'CUSTOM_CHALLENGE';
      user.challengeParam = param;
      resolve(user);
    },
    onSuccess() {
      delete user.challengeName;
      delete user.challengeParam;
      storeUser()
        .then(() => resolve(user))
        .catch(reject);
    },
    onFailure(err) {
      reject(err);
    },
  };
}

/**
 * Store user
 */

async function storeUser() {
  await isLoggedInWithCognito();
}

/**
 * Initiate login flow
 */

export async function login({
  username,
  password,
}: Credentials): Promise<User> {
  const user = new CognitoUser({
    Username: username,
    Pool: pool,
  });

  user.setAuthenticationFlowType('CUSTOM_AUTH');

  const details = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  // TODO: Remove after cognito migration
  await request('/cognito/migrate', 'post', { username, password });

  return new Promise((resolve, reject) =>
    user.authenticateUser(details, getCallbacks(user, resolve, reject)),
  );
}

/**
 * Verify MFA token
 */

export function verifyMfaLogin({ user, type, code }: MfaInput): Promise<User> {
  return new Promise((resolve, reject) =>
    user.sendCustomChallengeAnswer(code, getCallbacks(user, resolve, reject), {
      type,
    }),
  );
}

/**
 * Initate logout.
 */

export function logoutCognito(): Promise<void> {
  const user = pool.getCurrentUser();

  if (!user) {
    return Promise.resolve();
  }

  return new Promise((resolve) =>
    user.globalSignOut({
      onSuccess() {
        resolve();
      },
      onFailure() {
        user.signOut(resolve);
      },
    }),
  );
}

/**
 * Check if logged in
 */

export function isLoggedInWithCognito(): Promise<boolean> {
  const currentUser = pool.getCurrentUser();

  if (!currentUser) {
    return Promise.resolve(false);
  }

  return new Promise((resolve) =>
    pool.getCurrentUser()?.getSession(
      // Cognito types are weird, so need to define function signature manually
      (_error: Error | null, session: Cognito.CognitoUserSession | null) => {
        if (session?.isValid()) {
          return resolve(true);
        }
        resolve(false);
      },
    ),
  );
}

/**
 * Starts and completes a forgotten password flow for an unauthenticated user.
 */
export async function forgotPassword(username: string): Promise<void> {
  const user = new CognitoUser({
    Username: username,
    Pool: pool,
  });

  return new Promise((resolve, reject) =>
    user.forgotPassword({
      onFailure: reject,
      onSuccess() {
        resolve();
      },
    }),
  );
}

/**
 * This function is called to verify the new password and verification code for respective user.
 */

export function confirmPassword({
  username,
  password,
  verification,
}: Credentials): Promise<void> {
  if (!username || !password || !verification) {
    return Promise.reject(new Error('Invalid credentials'));
  }

  const user = new CognitoUser({
    Username: username,
    Pool: pool,
  });

  return new Promise((resolve, reject) =>
    user.confirmPassword(verification, password, {
      onSuccess() {
        resolve();
      },
      onFailure: reject,
    }),
  );
}

/**
 * Get cognito ID token
 */

export function getCognitoIdToken(): Promise<string | null> {
  const currentUser = pool.getCurrentUser();

  if (!currentUser) {
    return Promise.resolve(null);
  }

  return new Promise((resolve) =>
    currentUser.getSession(
      // Cognito types are weird, so need to define function signature manually
      (_error: Error, session: Cognito.CognitoUserSession | null) =>
        resolve(session?.getIdToken()?.getJwtToken() ?? null),
    ),
  );
}
