import styled from '@emotion/styled';

export const StatsBox = styled.div`
  border: 1px solid var(--color-black-8);
  height: 100%;
  padding: var(--spacing-small);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: var(--spacing-xx-small);
  display: flex;
  align-items: center;
  flex-direction: row;

  h4 {
    font-weight: var(--font-size-medium);
    font-size: 1em;
    margin-bottom: -0.5em;
  }

  h1 {
    font-size: 2.8em;
  }
`;

export const Icon = styled.div`
  width: 4.2em;
  height: 3.5em;
  background-color: var(--color-mint-green-25);
  color: var(--color-sea-green);
  border-radius: 3.5em;
  display: flex;
  margin-right: var(--spacing-medium);
  justify-content: center;
  align-items: center;

  path {
    stroke-width: 1px;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;

  .progress {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 0.8em;
    top: 0;

    .badge {
      padding: 0 8px;
      margin-right: 6px;
      border-radius: 8px;
      font-weight: 500;

      &.up {
        background-color: #ecfdf3;
        color: #007b5e;
      }

      &.down {
        background-color: #fef3f2;
        color: var(--color-fire-red);
      }

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
`;
