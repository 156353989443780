import { CurrentApiAndScopeEntitlementsDto } from '@portals/shared/portal/AppApiEntitlementDto';

import PortalApiAccessFormModal from './components/PortalApiAccessFormModal';
import ServiceNowApiAccessFormModal from './components/ServiceNowApiAccessFormModal';

interface Props {
  entitlement: CurrentApiAndScopeEntitlementsDto;
  onClose: () => void;
}

export default function RequestAccessToInternalApiModal({
  entitlement,
  onClose,
}: Props) {
  return entitlement.api.enableServiceNowRequest ? (
    <ServiceNowApiAccessFormModal entitlement={entitlement} onClose={onClose} />
  ) : (
    <PortalApiAccessFormModal entitlement={entitlement} onClose={onClose} />
  );
}
