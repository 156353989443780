// eslint-disable-next-line simple-import-sort/imports
import Prism from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-json';
import {
  isUnionSchema,
  resolveSchema,
  Schema,
  SchemaType,
} from '@portals/shared/common/open-api/openapi-helpers';
import style from './index.module.css';
import { Tabs } from '@dnb/eufemia';
import { parseExample } from '@portals/shared/common/open-api/example-resolver';

type ExampleItemProps = {
  schema: Schema;
  schemaType: SchemaType;
};

const schemaTypeDisplayName: Record<'anyOf' | 'oneOf', string> = {
  anyOf: 'any of',
  oneOf: 'one of',
};

const Example = ({
  schema: unresolvedSchema,
  schemaType,
}: ExampleItemProps) => {
  const schema = isUnionSchema(unresolvedSchema)
    ? unresolvedSchema
    : resolveSchema(unresolvedSchema);

  if (isUnionSchema(schema)) {
    return (
      <div className={style['union-container']}>
        <p className={style['union-type']}>
          {schemaTypeDisplayName[schema.type]}
        </p>
        <Tabs
          content_spacing={false}
          data={schema.schemas.map((subSchema, index) => ({
            title: subSchema.type,
            key: index.toString(), // If the key is a number then the contents of the first tab is not shown
            content: <Example schema={subSchema} schemaType={schemaType} />,
          }))}
          no_border
        />
      </div>
    );
  }

  const exampleData = parseExample(schema, schemaType);
  const lang = 'json';

  let prismCode;

  if (exampleData) {
    try {
      prismCode = Prism.highlight(
        JSON.stringify(exampleData, null, '\t') || '',
        Prism.languages[lang],
        lang,
      );
    } catch {
      prismCode = Prism.highlight(
        JSON.stringify(exampleData) || '',
        Prism.languages['txt'],
        'txt',
      );
    }
  }

  return prismCode ? (
    <div className="code">
      <pre className={`dnb-pre prism-code language-${lang}`}>
        <div dangerouslySetInnerHTML={{ __html: prismCode }} />
      </pre>
    </div>
  ) : null;
};

export default Example;
