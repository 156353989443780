import { Button, Tabs } from '@dnb/eufemia';
import {
  isIncluded,
  isObjectOrArraySchema,
  isUnionSchema,
  resolveSchema,
  Schema,
  SchemaType,
} from '@portals/shared/common/open-api/openapi-helpers';
import { useState } from 'react';

import NameAndRequired from './components/NameAndRequired';
import SchemaItemDescription from './components/SchemaItemDescription';

import * as Styled from './index.styles';

type SchemaItemProps = {
  schema: Schema;
  schemaType: SchemaType;
  name?: string;
  required?: boolean;
  isSubSchema?: boolean;
};

const schemaTypeDisplayName: Record<'anyOf' | 'oneOf', string> = {
  anyOf: 'any of',
  oneOf: 'one of',
};

const SchemaItem = ({
  schema: unresolvedSchema,
  schemaType,
  name,
  required,
  isSubSchema,
}: SchemaItemProps) => {
  const schema = isUnionSchema(unresolvedSchema)
    ? unresolvedSchema
    : resolveSchema(unresolvedSchema);

  const [opened, setOpened] = useState(false);

  return (
    <Styled.SchemaItem className={isSubSchema ? 'sub-schema' : ''}>
      {isUnionSchema(schema) ? (
        <div>
          <NameAndRequired name={name} required={required} />
          <Styled.SchemaItemType>
            {schemaTypeDisplayName[schema.type]}
          </Styled.SchemaItemType>
          <Tabs
            content_spacing={false}
            data={schema.schemas.map((subSchema, index) => ({
              title: subSchema.type,
              key: index.toString(), // If the key is a number then the contents of the first tab is not shown
              content: (
                <SchemaItem schema={subSchema} schemaType={schemaType} />
              ),
            }))}
            no_border
          />
        </div>
      ) : (
        <div>
          <SchemaItemDescription
            name={name}
            required={required}
            schema={schema}
          />
          {isObjectOrArraySchema(schema) && (
            <Button
              icon={opened ? 'chevron_down' : 'chevron_right'}
              on_click={() => setOpened(!opened)}
              variant="tertiary"
            >
              {schema.type === 'object' ? 'Properties' : 'Items'}
            </Button>
          )}
        </div>
      )}
      {opened &&
        isObjectOrArraySchema(schema) &&
        (schema.type === 'object' ? (
          <Styled.SchemaItemList>
            {Object.entries(schema.properties || [])
              .filter(([, subSchema]) => isIncluded(schemaType, subSchema))
              .map(([name, subSchema]) => (
                <li key={name}>
                  <SchemaItem
                    isSubSchema
                    name={name}
                    required={schema.required?.includes(name) || false}
                    schema={subSchema}
                    schemaType={schemaType}
                  />
                </li>
              ))}
          </Styled.SchemaItemList>
        ) : (
          schema.type === 'array' && (
            <SchemaItem schema={schema.items} schemaType={schemaType} />
          )
        ))}
    </Styled.SchemaItem>
  );
};

export default SchemaItem;
