import { Anchor, Card, Flex, H3, Icon, P } from '@dnb/eufemia';
import type { IconIcon } from '@dnb/eufemia/components/icon';
import { Link } from 'react-router-dom';

interface ExplorerCardProps {
  title: string;
  description: string;
  link: string;
  icon: IconIcon;
}

export default function ExplorerCard({
  title,
  description,
  icon,
  link,
}: ExplorerCardProps) {
  return (
    <Card>
      <Flex.Vertical
        align="center"
        space={{
          top: 'medium',
          bottom: 'medium',
          left: 'xx-large',
          right: 'xx-large',
        }}
      >
        <Icon color="var(--color-sea-green)" icon={icon} size="large" />
        <H3>{title}</H3>
        <P>{description}</P>
        <Anchor element={Link} to={link}>
          Explore
        </Anchor>
      </Flex.Vertical>
    </Card>
  );
}
