import 'chartjs-adapter-date-fns';

import type { LineChart } from '@portals/shared/admin/DashboardDto';
import type { ChartOptions } from 'chart.js';
import { Line } from 'react-chartjs-2';

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
);

import { Card, H3 } from '@dnb/eufemia';

import { pickColors } from '../colors';

export const defaultOptions: ChartOptions<'line'> = {
  responsive: true,
};

export default function LineChart({
  labels,
  datasets,
  title,
  options = {},
}: LineChart & { options?: ChartOptions<'line'> }) {
  const { selectedOpacityColors, selectedColors } = pickColors(datasets.length);
  const data = datasets.map((dataset, index) => ({
    backgroundColor: selectedOpacityColors[index],
    borderColor: selectedColors[index],
    ...dataset,
    fill: true,
  }));

  return (
    <Card>
      <H3>{title}</H3>
      <Line
        data={{ datasets: data, labels }}
        options={{
          ...defaultOptions,
          ...options,
        }}
      />
    </Card>
  );
}
