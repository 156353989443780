import { Children, type ReactNode, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useAuth from '@/hooks/useAuth';
import useReturnTo from '@/hooks/useReturnTo';

const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();
  useEffect(() => navigate(to, { replace: true }), [navigate, to]);
  return null;
};

type AuthTemplateProps = {
  children: ReactNode;
};
export default function AuthTemplate({
  children,
}: AuthTemplateProps): JSX.Element {
  const { isSignedIn, waiting } = useAuth();
  const [, setReturnTo] = useReturnTo();
  const location = useLocation();

  const { id: token } = useParams();
  if (location.pathname.startsWith('/subscriptions/manage') && !!token) {
    return <>{Children.only(children)}</>;
  }

  if (!waiting && !isSignedIn) {
    setReturnTo(location.pathname);
    return <Redirect to="/login" />;
  }

  return <>{Children.only(children)}</>;
}
