export const allIsAwesome = {
  colors: {
    primary: 'var(--color-mint-green-25)',
    background: 'var(--color-mint-green-25)',
    backgroundDocumentation: 'var(--black-3)',
    backgroundTableSecond: 'var(--color-mint-green-25)',
    backgroundMenu: 'var(--color-white)',
    backgroundCard: '#F2D7FF',
    backgroundCard2: '#23195A',
    backgroundInteractionColor: 'var(--color-mint-green-25)',
    interactionColor: '#6E2382',
    menuLinks: 'var(--color-ocean-green)',
    menuLinksHover: 'var(--color-mint-green-25)',
    menuLinksActive: 'var(--color-mint-green-50)',
    menuSecondary: 'var(--color-black-55)',
  },
};
