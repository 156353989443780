import { Card, H3 } from '@dnb/eufemia';
import {
  type BarChart,
  TitlePosition,
} from '@portals/shared/admin/DashboardDto';
import { Bar } from 'react-chartjs-2';

import { pickColors } from '../colors';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
};

export default function BarChart({
  labels,
  datasets,
  title,
  titlePosition = TitlePosition.top,
}: BarChart) {
  const { selectedColors } = pickColors(datasets.length);
  const data = datasets.map((dataset, index) => ({
    backgroundColor: selectedColors[index],
    ...dataset,
  }));
  return (
    <Card>
      <H3>{title}</H3>
      <Bar
        data={{ datasets: data, labels }}
        options={{
          ...options,
          plugins: {
            legend: {
              position: titlePosition,
            },
            title: { display: !!title, text: title },
          },
        }}
      />
    </Card>
  );
}
