import Chart from 'react-apexcharts';

import type { StatsType } from '../../Stats';

import * as styles from '../../Stats/index.styles';

const staticData = {
  options: {
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    fill: {
      colors: ['#14555A', '#00343E', '#28B482', '#A5E1D2', '#007272'],
    },
    colors: ['#14555A', '#00343E', '#28B482', '#A5E1D2', '#007272'],
    dataLabels: {
      enabled: false,
    },
    chart: {
      type: 'donut',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  },
};

const PieStat = ({ type = 'info', data }: StatsType) => {
  return (
    <styles.StatsBox key={type}>
      <Chart
        options={
          {
            ...staticData.options,
            labels: data.chart?.labels,
          } as ApexCharts.ApexOptions
        }
        series={data.chart?.series}
        type="donut"
      />
    </styles.StatsBox>
  );
};

export default PieStat;
