import BreadIllustration from '@/illustrations/Breadcrumb.svg?url';

type Position = {
  x: number;
  y: number;
};

export const BreadCrumb = ({ x, y }: Position) => {
  return (
    <img
      alt="breadcrumb"
      src={BreadIllustration}
      style={{
        width: '100px',
        height: '100px',
        position: 'fixed',
        left: x - 40,
        top: y - 50,
        zIndex: 99,
      }}
    />
  );
};
