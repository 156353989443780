import { Breadcrumb } from '@dnb/eufemia';
import {
  type ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * A drop-in replacement/wrapper for Eufemia's breadcrumb component
 * that intercepts click events on links.
 *
 * Eufemia uses a native anchor element that does not work with react-router.
 *
 * TODO: Remove when this is fixed in Eufemia
 */

export default function RouterBreadcrumb(
  props: ComponentPropsWithoutRef<typeof Breadcrumb>,
): JSX.Element {
  const wrapper = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const clickListener = useCallback(
    (event: MouseEvent) => {
      if (
        event.target instanceof HTMLAnchorElement &&
        wrapper.current?.contains(event.target)
      ) {
        event.preventDefault();
        navigate(new URL(event.target.href).pathname);
      }
    },
    [navigate],
  );

  useEffect(() => {
    document.body.addEventListener('click', clickListener);

    return () => {
      document.body.removeEventListener('click', clickListener);
    };
  }, [clickListener]);

  return (
    <span ref={wrapper}>
      <Breadcrumb {...props} />
    </span>
  );
}
