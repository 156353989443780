import type { UserDetailsDto } from '@portals/shared/portal/UserDto';
import { createContext } from 'react';

export interface UserContextValue {
  isLoading: boolean;
  user?: UserDetailsDto;
  reload: () => void;
}

const UserContext = createContext<UserContextValue>({
  reload: () => {},
  isLoading: false,
});

export default UserContext;
