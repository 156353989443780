import { Button, Dialog, P } from '@dnb/eufemia';
import { useState } from 'react';

interface TeromsOfUseProps {
  apiName: string;
  termsOfuseUpdatedAt: number | null;
  termsOfUse: string;
}

export default function TermsOfUseLabel({
  apiName,
  termsOfuseUpdatedAt,
  termsOfUse,
}: TeromsOfUseProps) {
  const [showTermsOfuse, setShowTermsOfuse] = useState(false);

  return (
    <>
      I agree to the{' '}
      <Button
        id="terms-of-use"
        on_click={() => setShowTermsOfuse(true)}
        text={apiName}
        variant="tertiary"
      />
      <Dialog
        omitTriggerButton
        onClose={() => setShowTermsOfuse(false)}
        openState={showTermsOfuse}
        title={`Terms of use for the ${apiName} API`}
      >
        {!!termsOfuseUpdatedAt && (
          <P bottom="small" size="small">
            Last updated: {new Date(termsOfuseUpdatedAt).toUTCString()}
          </P>
        )}
        <P>{termsOfUse}</P>
      </Dialog>{' '}
      API terms of use
    </>
  );
}
