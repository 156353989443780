/* eslint-disable max-lines-per-function */
import { type MutableRefObject, useCallback, useMemo, useRef } from 'react';
import type { z } from 'zod';

import { deepClone, deepGet, deepSet, mergePathSegments } from '../utils';
import type { DeepPartial, DeepPath, DeepValue } from '../utils/types';
import useSafeState from './useSafeState';

export type FormErrors = Record<string, string | undefined>;
export type FormValues<T extends Schema> = DeepPartial<z.input<T>>;

export type Schema = z.Schema<unknown>;
export type SchemaOutput<T extends Schema> = z.output<T>;
export type SchemaPath<T extends Schema, Key extends string> = DeepPath<
  FormValues<T>,
  Key
>;

export interface Controller<T extends Schema> {
  values: FormValues<T>;
  errors: FormErrors;
  formError?: string;
  valuesRef: MutableRefObject<FormValues<T>>;
  errorsRef: MutableRefObject<FormErrors>;
  setValues(values: FormValues<T>): void;
  setValue<Key extends string>(key: SchemaPath<T, Key>, value: unknown): void;
  getValue<Key extends string>(key: SchemaPath<T, Key>): unknown;
  setError(key: string, message?: string): void;
  setErrors(errors: FormErrors): void;
  setFormError(errors: string): void;
  getError<Key extends string>(key: SchemaPath<T, Key>): string | undefined;
  triggerValidation<Key extends string>(key: SchemaPath<T, Key>): void;
  validate(): SchemaOutput<T> | undefined;
}

/**
 * Handle form values, validation and submission
 */

export function useForm<T extends Schema>(
  initial: FormValues<T>,
  schema: T,
): Controller<T> {
  const clone = deepClone(initial);
  const valuesRef = useRef<FormValues<T>>(clone);
  const errorsRef = useRef<FormErrors>({});
  const formErrorRef = useRef<string>();
  const [values, _setValues] = useSafeState<FormValues<T>>(clone);
  const [errors, _setErrors] = useSafeState<FormErrors>({});
  const [formError, _setFormError] = useSafeState<string>();

  const getValue = <Key extends string>(path: SchemaPath<T, Key>) =>
    deepGet(valuesRef.current, path);

  const getError = <Key extends string>(path: SchemaPath<T, Key>) =>
    errors[path];

  const setValues = useCallback(
    (values: FormValues<T>) => {
      valuesRef.current = values;
      _setValues(values);
    },
    [_setValues],
  );

  const setErrors = useCallback(
    (errors: FormErrors) => {
      errorsRef.current = errors;
      _setErrors(errors);
    },
    [_setErrors],
  );

  const setFormError = useCallback(
    (error: string) => {
      formErrorRef.current = error;
      _setFormError(error);
    },
    [_setFormError],
  );

  const setError = useCallback(
    (name: string, message?: string) => {
      const copy = { ...errorsRef.current };

      if (message) {
        copy[name] = message;
      } else {
        delete copy[name];
      }

      setErrors(copy);
    },
    [setErrors],
  );

  const setValue = useCallback(
    <Key extends string>(key: SchemaPath<T, Key>, value: unknown) => {
      deepSet(valuesRef.current, key, value);
      setValues(deepClone(valuesRef.current));
    },
    [setValues],
  );

  const validate = useCallback(() => {
    // TODO: Address swallowed errors
    const result = schema.safeParse(valuesRef.current);

    if (result.success) {
      setErrors({});
      return result.data;
    }

    const errors: FormErrors = {};

    for (const { path, message } of result.error.issues) {
      const prop = mergePathSegments(path.map((s) => s.toString()));
      errors[prop] ??= message;
    }

    setErrors(errors);
    return;
  }, [schema, setErrors]);

  const triggerValidation = useCallback(
    (key: string) => {
      const result = schema.safeParse(valuesRef.current);

      if (result.success) {
        return setError(key);
      }

      const issue = result.error.issues.find(
        ({ path }) => mergePathSegments(path.map((s) => s.toString())) === key,
      );

      setError(key, issue?.message);
    },
    [setError, schema],
  );

  return {
    values,
    errors,
    formError,
    valuesRef,
    errorsRef,
    setValues,
    setValue,
    getValue,
    setError,
    setErrors,
    setFormError,
    getError,
    triggerValidation,
    validate,
  };
}

interface Item<T extends Schema, Key extends string> {
  index: number;
  value: ArrayElement<DeepValue<FormValues<T>, Key>>;
  remove: () => void;
}

interface FieldArrayController<T extends Schema, Key extends string> {
  push: PushFn<ArrayElement<DeepValue<FormValues<T>, Key>>>;
  remove(index: number): void;
  items: Item<T, Key>[];
}

interface PushFn<T> {
  (value: T): void;
}

type ArrayElement<T> = T extends Array<infer U> ? U : never;

/**
 * Handle nested form arrays
 */

export function useFieldArray<T extends Schema, Key extends string>(
  controller: Controller<T>,
  path: SchemaPath<T, Key>,
): FieldArrayController<T, Key> {
  const { setValue, valuesRef, values } = controller;

  const push = useCallback(
    (value: unknown) => {
      const array = deepGet(valuesRef.current, path);

      if (Array.isArray(array)) {
        setValue(path, [...deepClone(array), deepClone(value)]);
      }
    },
    [path, setValue, valuesRef],
  );

  const remove = useCallback(
    (index: number) => {
      const array = deepGet(valuesRef.current, path);

      if (Array.isArray(array)) {
        const value = deepClone(array).filter((_, i) => i !== index);
        setValue(path, value);
      }
    },
    [path, setValue, valuesRef],
  );

  const items = useMemo<Item<T, Key>[]>(() => {
    const array = deepGet(values, path);

    if (!Array.isArray(array)) {
      return [];
    }

    return array.map((value, index) => ({
      index,
      value,
      remove: () => remove(index),
    }));
  }, [path, values, remove]);

  return {
    push,
    remove,
    items,
  };
}
