import { Space } from '@dnb/eufemia';
import { copy as CopyIcon } from '@dnb/eufemia/icons';
import type { OpenAPIV3 } from 'openapi-types';

import CopyButton from '@/components/CopyButton';

import { ServerLink } from './index.styles';

type LinkProps = {
  action: string;
  path: string;
  servers: OpenAPIV3.ServerObject[] | undefined;
};

const Index = ({ action, path, servers }: LinkProps) => {
  const serverItems = servers ?? [{ url: '' }];

  return (
    <div>
      {serverItems?.map((server) => (
        <ServerLink key={`link-${action}-${server?.url}`}>
          {server?.description && (
            <Space bottom="small" className="desc">
              <strong>{server?.description}</strong>
            </Space>
          )}
          <div className="wrapper">
            <div className="link">
              <div>
                <em className={`${action}`}>{action}</em> {path}
              </div>
              <span>
                {server?.url}
                {path}
              </span>
            </div>
            <CopyButton
              icon={CopyIcon}
              icon_position="left"
              key="copy"
              toCopy={`${server?.url}${path}`}
              variant="secondary"
            >
              Copy
            </CopyButton>
          </div>
        </ServerLink>
      ))}
    </div>
  );
};

export default Index;
