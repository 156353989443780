import {
  Button,
  H2,
  H3,
  H4,
  H5,
  IconPrimary,
  Li,
  P,
  Pagination,
  Space,
  Span,
  Ul,
} from '@dnb/eufemia';
import type { SpacingElementProps } from '@dnb/eufemia/shared/types';
import type { ApiDto } from '@portals/shared/portal/ApiDto';
import type {
  ApiIncidentDto,
  StatusDto,
} from '@portals/shared/portal/StatusDto';
import { keyBy } from '@portals/shared-frontend/utils';
import classnames from 'classnames';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import Container from '@/components/Container';
import Markdown from '@/components/Markdown';
import Page from '@/components/Page';
import StatusWidget from '@/components/StatusWidget';
import useReturnTo from '@/hooks/useReturnTo';

import style from './Status.module.css';

import satelliteIllustrationUrl from './Satellite.svg?url';

/**
 * Dummy data for skeletons
 */

const DUMMY_STATUS = new Array(5).fill(0).map<StatusDto>((_, i) => ({
  apiSlug: `dummy-api-${i}`,
  inactiveIncidents: [],
  ongoingIncidents: [],
  upcomingIncidents: [],
  responseTimes: [],
  uptime: [],
  severity: 0,
}));

/**
 * Helpers
 */

function formatDateRange(start: string, end: string) {
  return `${formatDate(start)} – ${formatDate(end)}`;
}

function formatDate(date: string) {
  return format(new Date(date), 'MMMM d, yyyy, HH:mm, O');
}

/**
 * Incident card
 */

interface IncidentCardProps extends SpacingElementProps {
  incident: ApiIncidentDto;
}

function IncidentCard({ incident, ...props }: IncidentCardProps): JSX.Element {
  const title =
    incident.type === 'maintenance' && incident.resolvedAt
      ? formatDateRange(incident.reportedAt, incident.resolvedAt)
      : formatDate(incident.reportedAt);

  const maintenanceCompleted =
    incident.type === 'maintenance' &&
    incident.resolvedAt &&
    new Date(incident.resolvedAt).valueOf() < Date.now();

  return (
    <Space {...props} className={style['IncidentCard']}>
      <H4>{title}</H4>
      <P size="small" top="x-small">
        ID: {incident.id}
      </P>
      {incident.description && <Markdown>{incident.description}</Markdown>}
      {incident.updates.length > 0 && (
        <Ul inside top="small">
          {incident.updates.map((update) => (
            <Li key={update.id}>
              <H5>{formatDate(update.reportedAt)}</H5>
              {update.description && <Markdown>{update.description}</Markdown>}
            </Li>
          ))}
        </Ul>
      )}
      {incident.resolvedAt && incident.type !== 'maintenance' && (
        <Space className={style['IncidentCard-resolved']} top="small">
          <IconPrimary icon="check" right="x-small" />
          Resolved {formatDate(incident.resolvedAt)}
        </Space>
      )}
      {maintenanceCompleted && (
        <Space className={style['IncidentCard-resolved']} top="small">
          <IconPrimary icon="check" right="x-small" />
          Maintenance completed
        </Space>
      )}
    </Space>
  );
}

/**
 * Status card
 */

const INCCIDENTS_PER_PAGE = 4;
interface StatusCardProps extends SpacingElementProps {
  apiName: string;
  status: StatusDto;
}

const SEVERITY = ['Operational', 'Instabilities', 'Reduced'];

function StatusCard({
  apiName,
  status,

  ...props
}: StatusCardProps): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const [page, setPage] = useState(1);

  const numberOfPages = Math.ceil(
    status.inactiveIncidents.length / INCCIDENTS_PER_PAGE,
  );

  const currentPageData = useMemo(
    () =>
      status.inactiveIncidents.slice(
        (page - 1) * INCCIDENTS_PER_PAGE,

        page * INCCIDENTS_PER_PAGE,
      ),
    [page, status.inactiveIncidents],
  );

  const contentId = `status-card-content-${status.apiSlug}`;

  const hasIncidents =
    status.ongoingIncidents.length > 0 ||
    status.upcomingIncidents.length > 0 ||
    status.inactiveIncidents.length > 0;

  return (
    <Space
      className={classnames({
        [style['StatusCard']]: true,
        [style['StatusCard--expanded']]: expanded,
        [style[`StatusCard--severity-${status.severity}`]]: true,
      })}
      {...props}
    >
      <button
        aria-controls={contentId}
        aria-expanded={expanded}
        className={classnames([style['StatusCard-button'], 'dnb-tab-focus'])}
        onClick={() => setExpanded(!expanded)}
        type="button"
      >
        <IconPrimary icon="chevron_down" size="medium" />
        <H2 size="medium">{apiName}</H2>
        <Span className={style['StatusCard-severity']}>
          {SEVERITY[status.severity]}
        </Span>
      </button>

      <div className={style['StatusCard-content']} id={contentId}>
        {status.ongoingIncidents.length > 0 && (
          <>
            <H3 className={style['StatusCard-ongoing']} top="large">
              Ongoing
            </H3>
            {status.ongoingIncidents.map((incident) => (
              <IncidentCard incident={incident} key={incident.id} top="small" />
            ))}
          </>
        )}
        {status.upcomingIncidents.length > 0 && (
          <>
            <H3 top="large">Upcoming maintenance</H3>
            {status.upcomingIncidents.map((incident) => (
              <IncidentCard incident={incident} key={incident.id} top="small" />
            ))}
          </>
        )}

        {status.inactiveIncidents.length > 0 && (
          <Pagination
            current_page={page}
            on_change={({ pageNumber }) => setPage(pageNumber)}
            page_count={numberOfPages}
          >
            <>
              <H3 top="large">Past incidents</H3>
              {currentPageData.map((incident) => (
                <IncidentCard
                  incident={incident}
                  key={incident.id}
                  top="small"
                />
              ))}
            </>
          </Pagination>
        )}

        {!hasIncidents && (
          <P top="large">
            <i>No incidents</i>
          </P>
        )}
      </div>
    </Space>
  );
}

/**
 * Status page
 */

const WIDGET_TEXT = [
  'All APIs are operating normally',
  'We are looking into it. Please see below for updates.',
  'We are looking into it. Please see below for updates.',
];

export default function Status(): JSX.Element {
  const { data: apis, isValidating: apisLoading } = useSWR<ApiDto[]>('/api');
  const { data: status, isValidating: statusLoading } =
    useSWR<StatusDto[]>('/status');
  const [, setReturnTo] = useReturnTo();

  const apisBySlug = keyBy(apis ?? [], 'slug');

  const severity = status
    ? Math.max(...status.map(({ severity }) => severity))
    : 0;

  const hasLoaded = !apisLoading && !statusLoading && !!apis && !!status;

  return (
    <Page
      description="Status page for the DNB APIs. Here you will find an up-to-date status on
    the services and information about ongoing incidents."
      illustration={
        <img className={style['Illustration']} src={satelliteIllustrationUrl} />
      }
      skeleton={!hasLoaded}
      styleType="white"
      subtitle="API Status"
      title="Overview"
    >
      <Container size="medium">
        {severity > 0 ? (
          <StatusWidget content={WIDGET_TEXT} severity={severity} />
        ) : (
          <P className={style['Status-operational']} medium size="medium">
            All APIs are operating normally
          </P>
        )}

        <Space bottom="large" top="large">
          <Button element={Link} size="large" to="/subscriptions/status">
            Subscribe
          </Button>
          <Button
            element={Link}
            left="x-small"
            on_click={() => {
              setReturnTo('/status');
            }}
            size="large"
            to="/unsubscribe"
            variant="secondary"
          >
            Unsubscribe
          </Button>
        </Space>

        {(hasLoaded ? status : DUMMY_STATUS).map((status) => (
          <StatusCard
            apiName={apisBySlug[status.apiSlug]?.name ?? 'API not found'}
            key={status.apiSlug}
            status={status}
            top="small"
          />
        ))}
      </Container>
    </Page>
  );
}
