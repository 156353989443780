import styled from '@emotion/styled';

export const CopyButton = styled.div`
  position: absolute;
  right: 10px;
  top: 8px;
  width: 30px;
  height: 30px;
  padding: 10px;
  border-radius: 6px;
  opacity: 0;
  border: 1px solid var(--color-black-55);
  display: flex;
  justify-content: center;
  align-content: center;

  svg {
    margin-top: 4px;
    margin-left: 4px;
    width: 20px;
    height: 20px;
  }

  .copyIcon,
  .copySuccessIcon {
    position: absolute;
    top: 0;
    left: 0;
    fill: currentColor;
    opacity: inherit;
    width: inherit;
    height: inherit;
    transition: all 0.5s ease;
  }

  .copySuccessIcon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.33);
    opacity: 0;
    color: #00d600;
  }

  &.Copied .copyIcon {
    transform: scale(0.33);
    opacity: 0;
  }

  &.Copied .copySuccessIcon {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    transition-delay: 0.275s;
  }

  &:hover {
    .copy {
      cursor: pointer;
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
`;
