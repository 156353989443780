import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * useState hook that ignores state updates after unmount
 */

export type UseSafeState = typeof useState;

const useSafeState: UseSafeState = <T>(initial?: T) => {
  const mounted = useRef(true);
  const [state, setState] = useState(initial);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    [],
  );

  const safeSetState = useCallback((value: T) => {
    if (mounted.current) {
      setState(value);
    }
  }, []);

  return [state, safeSetState];
};

export default useSafeState;
