import { Div, Heading, Link, Logo, Section } from '@dnb/eufemia';
import type { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import style from './index.module.css';

interface FooterLink {
  title: string;
  href: string;
}

interface FooterSectionProps {
  title: string;
  links: FooterLink[];
}

function FooterSection({
  title,
  links,
}: PropsWithChildren<FooterSectionProps>): JSX.Element {
  return (
    <Div>
      <Heading bottom="x-small" size="medium">
        {title}
      </Heading>
      <ul className={style['FooterList']}>
        {links.map((link, i) => (
          <li key={i}>
            <Link
              className="dnb-anchor--no-underline"
              element={RouterLink}
              to={link.href}
            >
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
    </Div>
  );
}

export default function Footer(): JSX.Element {
  const sections: FooterSectionProps[] = [
    {
      title: 'Shortcuts',
      links: [
        { title: 'API Explorer', href: '/explorer/apis' },
        { title: 'Getting Started', href: '/getting-started' },
        { title: 'Careers', href: '/jobs' },
        { title: 'Digital Channels Performance', href: '/channel-statistics' },
        { title: 'Site Map', href: '/site-map' },
      ],
    },
    {
      title: 'Legal',
      links: [
        { title: 'Privacy Policy', href: '/privacy-policy' },
        { title: 'Terms of Use', href: '/terms-of-use' },
      ],
    },
    {
      title: 'Support',
      links: [
        { title: 'API Status', href: '/status' },
        { title: 'Help Center', href: '/help-center' },
      ],
    },
  ];

  return (
    <footer>
      <Section spacing="xx-large" style_type="emerald-green">
        <div className={style['FooterContent']}>
          <Logo bottom="medium" className={style['Logo']} size={92} />
          {sections.map((section, i) => (
            <FooterSection key={i} {...section} />
          ))}
        </div>
      </Section>
    </footer>
  );
}
