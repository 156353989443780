import styled from '@emotion/styled';

export const SchemaItem = styled.div`
  padding: var(--spacing-small);
  border: 1px solid var(--color-black-8);
  background-color: var(--color-white);

  &.sub-schema {
    border: none;
    background-color: inherit;
  }
`;

export const SchemaItemType = styled.p`
  color: var(--color-black-55);
  font-size: var(--font-size-basis);
  margin: 0;
`;

export const SchemaItemList = styled.ul`
  margin: 0;
  padding: 0;
  border: 1px solid var(--color-black-8);
  list-style: none;

  li:nth-of-type(odd) {
    background-color: var(--color-white);
  }

  li:nth-of-type(even) {
    background-color: var(--color-black-3);
  }
`;
