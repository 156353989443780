export const colors = [
  '#28B482',
  '#A5E1D2',
  '#007272',
  '#009688',
  '#FDBB31',
  '#DC2A2A',
  '#FF5400',
  '#6E2382',
  '#4BBED2',
  '#23195A',
  '#4285F4',
  '#E6781E',
  '#865CD6',
  '#D04A68',
  '#EED39D',
  '#795548',
  '#9C27B0',
  '#FFC3A0',
  '#DAC1B1',
  '#EE9DCC',
];
export const opacityColors = [
  '#28B48280',
  '#A5E1D280',
  '#00727280',
  '#00968880',
  '#FDBB3180',
  '#DC2A2A80',
  '#FF540080',
  '#6E238280',
  '#4BBED280',
  '#23195A80',
  '#4285F480',
  '#E6781E80',
  '#865CD680',
  '#D04A6880',
  '#EED39D80',
  '#79554880',
  '#9C27B080',
  '#FFC3A080',
  '#DAC1B180',
  '#EE9DCC80',
];

export function pickColors(x: number, pickRandom = false) {
  if (x <= 0) {
    return { selectedColors: [], selectedOpacityColors: [] };
  }

  const selectedColors: string[] = [];
  const selectedOpacityColors: string[] = [];
  let lastColorIndexs: number[] = [];

  for (let i = 0; i < x; i++) {
    let index: number;

    // Ensure that the same color is not used twice in a row
    if (colors.length === lastColorIndexs.length) {
      lastColorIndexs = [];
    }
    do {
      index = pickRandom ? Math.floor(Math.random() * colors.length) : i;
    } while (lastColorIndexs.includes(index));

    // Update lastColorIndex for the next iteration
    lastColorIndexs.push(index);
    // Push the color at the random index to the selectedColors array

    // @ts-ignore
    selectedColors.push(colors[index]);
    // @ts-ignore
    selectedOpacityColors.push(opacityColors[index]);
  }
  return { selectedColors, selectedOpacityColors };
}
