/**
 * Classification descriptions
 */

import type { ApiDto } from '@portals/shared/portal/ApiDto';

export const CLASSIFICATION_DESCRIPTIONS = {
  system:
    'System API: API service that encapsulates an IT system. Should only be used by other applications within the same domain, as it may be proprietary and non-standardized.',
  domain:
    'Domain API: Standardized and reusable API service that may be consumed by any DNB application or channel. It exposes a service domain, such as Customer or Payments.',
  experience:
    'Experience API: API service to be used by one or more DNB channels.',
  partner: 'Partner API: API service only available for selected partners.',
  technical:
    'Technical API: API service for technical tools and platforms (e.g. the Developer Portal).',
  public: null,
};

export function getApiClassificationDescriptionByFilter(filter: string) {
  switch (filter) {
    case INTERNAL_FILTER.Domain: {
      return CLASSIFICATION_DESCRIPTIONS[INTERNAL_FILTER.Domain];
    }
    case INTERNAL_FILTER.Experience: {
      return CLASSIFICATION_DESCRIPTIONS[INTERNAL_FILTER.Experience];
    }
    case INTERNAL_FILTER.System: {
      return CLASSIFICATION_DESCRIPTIONS[INTERNAL_FILTER.System];
    }
    case INTERNAL_FILTER.Technical: {
      return CLASSIFICATION_DESCRIPTIONS[INTERNAL_FILTER.Technical];
    }
    default: {
      return null;
    }
  }
}

export enum PUBLIC_FILTER {
  All = 'all',
  Corporate = 'corporate',
  Regulatory = 'regulatory',
}

export enum INTERNAL_FILTER {
  All = 'all',
  Domain = 'domain',
  System = 'system',
  Technical = 'technical',
  Experience = 'experience',
}

export const INTERNAL_CLASSIFICATIONS = new Set<ApiDto['classification']>([
  'domain',
  'system',
  'technical',
  'experience',
]);

export const EXTERNAL_CLASSIFICATIONS = new Set<ApiDto['classification']>([
  'partner',
  'public',
]);

export enum API_EXPLORER_TABS {
  Internal = '/explorer/apis/internal',
  External = '/explorer/apis',
  Review = '/explorer/apis/review',
  Commercial = '/explorer/apis/commercial',
  Regulatory = 'explorer/apis/regulatory',
}

export const API_EXPLORER_TAB_DATA = (hasReviewFlag: boolean) =>
  hasReviewFlag
    ? [
        { title: 'Internal', key: API_EXPLORER_TABS.Internal },
        { title: 'External', key: API_EXPLORER_TABS.External },
        { title: 'Review', key: API_EXPLORER_TABS.Review },
      ]
    : [
        { title: 'Internal', key: API_EXPLORER_TABS.Internal },
        { title: 'External', key: API_EXPLORER_TABS.External },
      ];
