import { FormSet, FormStatus } from '@dnb/eufemia';
import { PUBLISHED_STATUS } from '@portals/shared/admin/ContentDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import slugify from 'slugify';

import { createOrUpdatePage } from '@/api/content';
import LoadingModal from '@/components/LoadingModal';
import ModalPage from '@/components/ModalPage';
import { useUser } from '@/hooks/useUser';
import { ApiError } from '@/request';

import AddPageForm from '../AddPageForm';
import { mapFromVisibility, schema, VISIBLE_TO } from '../constants';

export default function AddNewPage() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { teamId } = useParams();
  const { user, isLoading: loadingUser } = useUser();
  const navigate = useNavigate();
  const { controller, handleSubmit, submitting } = useEufemiaForm(schema, {
    content: 'Some content',
    publishedStatus: PUBLISHED_STATUS.DRAFT,
    visibleTo: VISIBLE_TO.DNB,
  });

  const onSubmit = handleSubmit(
    async ({ title, publishedStatus, visibleTo }) => {
      if (teamId) {
        const visibility = mapFromVisibility(
          visibleTo,
          user?.organizationId,
          teamId,
        );

        const slug = slugify(title.toLocaleLowerCase(), {
          lower: true,
          trim: true,
        });

        const data = {
          title: title,
          slug: slug,
          isPublic: visibility.isPublic ?? false,
          blob: { content: '' },
          publishedStatus: publishedStatus,
          teams: [teamId],
          organizations: visibility.organizationId
            ? [visibility.organizationId]
            : [],
          sortIndex: 100,
          apiId: null,
          teamId: teamId,
          path: `/tpp/${teamId}/${slug}`,
          parent: `/tpp/${teamId}`,
          parentId: null,
        };
        try {
          const result = await createOrUpdatePage(teamId, data);
          navigate(`/team/${teamId}/pages/${result.id}`, { replace: true });
        } catch (error) {
          ApiError.isApiError(error)
            ? setErrorMessage(error.message)
            : setErrorMessage('Something went wrong, please try again later');
        }
      }
    },
  );

  return (
    <ModalPage
      actualModal
      onClose={() => navigate(`..`)}
      skeleton={loadingUser}
      title="Add new page"
    >
      {submitting && <LoadingModal />}
      <FormSet direction="vertical" on_submit={onSubmit}>
        <AddPageForm controller={controller} hideDescription />
        {errorMessage && <FormStatus text={errorMessage} top="medium" />}
      </FormSet>
    </ModalPage>
  );
}
