import { Button } from '@dnb/eufemia';
import { log_in_medium as LogInMediumIcon } from '@dnb/eufemia/icons';
import { Link as RouterLink } from 'react-router-dom';

import Container from '@/components/Container';
import ModalPage from '@/components/ModalPage';

export default function SignUpSuccess(): JSX.Element {
  return (
    <ModalPage subtitle="Sign up" title="Account successfully created">
      <Container>
        <Button
          element={RouterLink}
          icon={LogInMediumIcon}
          icon_position="left"
          icon_size="medium"
          size="large"
          to="/login"
        >
          Log in
        </Button>
      </Container>
    </ModalPage>
  );
}
