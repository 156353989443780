import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useFeatureFlags from '@/hooks/useFeatureFlags';

import ApiPage from '../../components/ApiPage';
import { API_EXPLORER_TABS } from '../../content';

export default function CommercialApis() {
  const { featureFlags, isLoading } = useFeatureFlags();
  const navigate = useNavigate();

  //TODO: remove this when featureflag is disabled and is set as default
  useEffect(() => {
    if (!featureFlags.ENABLE_NEW_REDESIGN && !isLoading) {
      navigate('/explorer/apis/', { replace: true });
    }
  }, [featureFlags.ENABLE_NEW_REDESIGN, isLoading, navigate]);

  return (
    <ApiPage apiType={API_EXPLORER_TABS.Commercial} title="Commercial APIs" />
  );
}
