import { Flex, Input, ToggleButton } from '@dnb/eufemia';
import { EventClassification, EventDto } from '@portals/shared/portal/EventDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { useEffect } from 'react';
import { literal, object } from 'zod';
import { nativeEnum, string } from 'zod';

interface EventFilterProps {
  events: EventDto[];
  onChange: (filteredEvents: EventDto[]) => void;
}

const filterSchema = object({
  classification: nativeEnum(EventClassification).or(literal('all')),
  searchQuery: string(),
});

export default function EventFilter({ events, onChange }: EventFilterProps) {
  const {
    register,
    controller: {
      values: { classification, searchQuery },
    },
  } = useEufemiaForm(filterSchema, { classification: 'all' });

  useEffect(() => {
    if (events.length === 0) return;
    if (!classification && !searchQuery) {
      onChange(events);
      return;
    }
    const filteredEvents = events.filter((event) => {
      if (
        classification !== 'all' &&
        !(classification === event.classification)
      )
        return false;
      return searchQuery
        ? event.name.toLowerCase().includes(searchQuery.toLowerCase())
        : true;
    });
    onChange(filteredEvents);
  }, [events, onChange, classification, searchQuery]);

  return (
    <Flex.Stack>
      <ToggleButton.Group {...register.toggleButtonGroup('classification')}>
        <ToggleButton text="All" value="all" />
        <ToggleButton text="Domain" value={EventClassification.Domain} />
        <ToggleButton
          text="Experience"
          value={EventClassification.Experience}
        />
        <ToggleButton text="System" value={EventClassification.System} />
      </ToggleButton.Group>
      <Input
        bottom="small"
        clear
        icon="loupe"
        placeholder="Search"
        {...register.input('searchQuery')}
      />
    </Flex.Stack>
  );
}
