import { Dropdown, Space, Tabs } from '@dnb/eufemia';
import { SchemaType } from '@portals/shared/common/open-api/openapi-helpers';
import type { OpenAPIV3 } from 'openapi-types';
import { useEffect, useState } from 'react';

import type { DereferencedSchemaObject } from '@/pages/api-documentation/constants/types';

import Example from '../Example';
import SchemaItem from './components/SchemaItem';

type SchemaProps = {
  data: Record<string, OpenAPIV3.MediaTypeObject>;
  schemaType: SchemaType;
};

const getData = (data: OpenAPIV3.MediaTypeObject) => {
  if (!data) {
    return null;
  }

  const section = data['schema'] as DereferencedSchemaObject;
  if (Object.keys(data).includes('example')) {
    section['example'] = data['example'];
  }

  return section;
};

const Schema = ({ data, schemaType }: SchemaProps) => {
  const [selected, setSelected] = useState(Object.keys(data)[0]);
  const [tab, selectTab] = useState('schema');

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setSelected(Object.keys(data)[0]);
    }
  }, [data]);

  const section = getData(data[selected]);

  return (
    <div>
      {Object.keys(data).length > 0 && (
        <Space bottom="small" top="small">
          <Dropdown
            data={Object.keys(data).map((item, index) => ({
              selected_key: `key_${index}`,
              content: item,
            }))}
            value="key_0"
          />
        </Space>
      )}

      {section && (
        <>
          <Tabs
            data={[
              { title: 'Schema', key: 'schema' },
              { title: 'Example', key: 'example' },
            ]}
            no_border
            on_change={({ key }) => selectTab(key)}
            selected_key={tab}
          />

          {tab === 'schema' ? (
            <SchemaItem schema={section} schemaType={schemaType} />
          ) : (
            <Example schema={section} schemaType={schemaType} />
          )}
        </>
      )}
    </div>
  );
};

export default Schema;
