import { request } from '../request';

export async function getApiMetadata<T>(
  apiSlug: string,
  environmentSlug: string,
  version = '@latest',
): Promise<T> {
  return request<T>(
    `/api/${apiSlug}/environments/${environmentSlug}/releases/${version}/metadata`,
    'get',
  );
}
