import { hasOwnProperty } from '@portals/shared-frontend/utils';
import type { OpenAPIV3 } from 'openapi-types';

import { SecurityTypeMap } from '@/pages/api-documentation/constants/types';

import { InlineCodeElementsWithLabel } from './components/InlineCodeElementsWithLabel';

import * as Styled from './index.styles';
import {
  H3S,
  H4S,
} from '@/pages/api-documentation/styles/Api.Reference.styles';

type SecurityProps = {
  data: {
    security: OpenAPIV3.SecurityRequirementObject[] | undefined;
    itemSecurity: OpenAPIV3.SecurityRequirementObject[] | undefined;
    schemas:
      | {
          [p: string]:
            | OpenAPIV3.SecuritySchemeObject
            | OpenAPIV3.ReferenceObject;
        }
      | undefined;
  };
};

const Authentication = ({ data }: SecurityProps) => {
  const { security, schemas, itemSecurity } = data;

  return (
    <div>
      {itemSecurity ? (
        <div>
          <H3S>Authentication</H3S>
          <Styled.AuthenticationList>
            {itemSecurity.map((securityItem) => (
              <>
                {Object.keys(securityItem).map((itemKey) => {
                  const orgSchema = schemas ? schemas[itemKey] : null;

                  return orgSchema && hasOwnProperty(orgSchema, 'type') ? (
                    <li
                      key={`item-schema-security${Object.keys(securityItem)}`}
                    >
                      <div className="schema-item">
                        <div className="id">
                          <div>
                            <strong>{itemKey}</strong>
                          </div>
                          <div>{SecurityTypeMap[orgSchema.type]}</div>
                        </div>
                        <div className="desc">
                          {hasOwnProperty(orgSchema, 'in') && ( // apiKey
                            <InlineCodeElementsWithLabel
                              content={orgSchema.in}
                              label="In"
                            />
                          )}
                          {securityItem[itemKey].length > 0 && (
                            <InlineCodeElementsWithLabel
                              content={securityItem[itemKey]}
                              label="Scopes"
                            />
                          )}
                        </div>
                      </div>
                    </li>
                  ) : null;
                })}
              </>
            ))}
          </Styled.AuthenticationList>
        </div>
      ) : security ? (
        <div>
          <H3S>Authentication</H3S>
          {security.map((securityMethod, methodIndex) => {
            return (
              <>
                {security.length !== 1 && <H4S>Method {methodIndex + 1}:</H4S>}
                <Styled.AuthenticationList>
                  {schemas && (
                    <>
                      {Object.keys(securityMethod).map((securityKey) => {
                        const schemaData = schemas[
                          securityKey
                        ] as OpenAPIV3.SecuritySchemeObject;

                        return (
                          <li key={`schema-item${securityKey}`}>
                            <div className="schema-item">
                              <div className="id">
                                <div>
                                  <strong>{securityKey}</strong>
                                </div>
                                <div>{SecurityTypeMap[schemaData.type]}</div>
                              </div>
                              <div className="desc">
                                {securityMethod[securityKey].length > 0 && (
                                  <InlineCodeElementsWithLabel
                                    content={securityMethod[securityKey]}
                                    label="Scopes"
                                  />
                                )}
                                {schemaData.type === 'apiKey' && (
                                  <InlineCodeElementsWithLabel
                                    content={schemaData.name}
                                    label="Header"
                                  />
                                )}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </>
                  )}
                </Styled.AuthenticationList>
              </>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default Authentication;
