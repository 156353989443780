export const darkTheme = {
  colors: {
    primary: 'black',
    background: 'black',
    backgroundTableSecond: '#333333',
    backgroundDocumentation: 'black',
    backgroundMenu: 'black',
    backgroundInteractionColor: 'var(--color-ocean-green)',
    interactionColor: 'var(--color-mint-green)',
    backgroundCard: '#2C2C2E',
    backgroundCard2: 'var(--black)',
    menuLinks: 'var(--color-white)',
    menuLinksHover: '#2C2C2E',
    menuLinksActive: '#2C2C2E',
    menuSecondary: '#8E8E93',
  },
};
