import { CurrentApiAndScopeEntitlementsDto } from '@portals/shared/portal/AppApiEntitlementDto';

import RequestAccessToInternalApiModal from './components/RequestAccessToInternalApiModal';
import RequestAccessToPublicApiModal from './components/RequestAccessToPublicApiModal';

interface Props {
  entitlement: CurrentApiAndScopeEntitlementsDto;
  onClose: () => void;
}

export default function RequestApiAccessModal({ entitlement, onClose }: Props) {
  return entitlement.api.isPublic ? (
    <RequestAccessToPublicApiModal
      entitlement={entitlement}
      onClose={onClose}
    />
  ) : (
    <RequestAccessToInternalApiModal
      entitlement={entitlement}
      onClose={onClose}
    />
  );
}
