/* eslint-disable max-lines */
import {
  Button,
  Checkbox,
  FormRow,
  FormSet,
  FormStatus,
  H4,
  Input,
  P,
  Skeleton,
  Table,
  Td,
  Tr,
} from '@dnb/eufemia';
import styled from '@emotion/styled';
import { CurrentApiAndScopeEntitlementsDto } from '@portals/shared/portal/AppApiEntitlementDto';
import type {
  CiamClientDto,
  ClientDto,
} from '@portals/shared/portal/ClientDto';
import { UpdateOAuthClientRequest } from '@portals/shared/portal/OAuthClientDto';
import { useEufemiaForm, useFieldArray } from '@portals/shared-frontend/hooks';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import { useCallback, useState } from 'react';
import { z } from 'zod';

import { updateClient } from '@/api/app';
import Card from '@/components/Card';
import LoadingModal from '@/components/LoadingModal';
import Toast from '@/components/Toast';

export type ClientProps = {
  isAdmin: boolean;
  client: ClientDto;
  ciamClient?: CiamClientDto;
  currentApiAndScopeEntitlements: CurrentApiAndScopeEntitlementsDto[];
  onClientUpdated: () => void;
  ciamClientsLoading: boolean;
};

const scopesFormSchema = z.object({
  scopes: z.array(z.string()).default([]),
  crq: z.string(),
});

export default function ClientEdit({
  isAdmin,
  client,
  ciamClient,
  currentApiAndScopeEntitlements,
  onClientUpdated,
  ciamClientsLoading,
}: ClientProps): JSX.Element | null {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<null | string>(null);

  const { controller, handleSubmit, register, submitting } = useEufemiaForm(
    scopesFormSchema,
    {
      scopes: ciamClient?.scopes || [],
      crq: client.liveMode ? undefined : '',
    },
  );

  const { push, remove } = useFieldArray(controller, 'scopes');

  const onScopeChanged = (scopeName: string, checked: boolean) => {
    if (checked) {
      if (!controller.values?.scopes?.includes(scopeName)) push(scopeName);
    } else {
      const indexToRemove = controller.values?.scopes?.indexOf(scopeName);
      if (indexToRemove != undefined && indexToRemove >= 0) {
        remove(indexToRemove);
      }
    }
  };

  const onSubmit = handleSubmit(
    useCallback(async ({ scopes, crq }) => {
      try {
        const data: UpdateOAuthClientRequest = {
          scopes,
          liveMode: client.liveMode,
          redirectUris: [],
        };
        if (client.liveMode) {
          data.crq = crq;
        }
        await updateClient(client.appId, client.clientId, data);
        setErrorMessage(null);
        setSuccessMessage('Client updated successfully');
        onClientUpdated();
        controller.setValue('crq', undefined);
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000);
      } catch (error: unknown) {
        if (ApiError.isApiError(error)) {
          setErrorMessage(error.body.message);
        } else {
          throw error;
        }
      }
    }, []),
  );

  return (
    <FormSet left="0" on_submit={onSubmit} right="0">
      <Skeleton show={ciamClientsLoading}>
        {submitting && <LoadingModal />}
        {!isAdmin && (
          <FormRow>
            <FormStatus
              bottom="large"
              text="You need to be an admin to edit this client"
              top="medium"
            />
          </FormRow>
        )}
        {currentApiAndScopeEntitlements.map((entitlement) => (
          <SpacedCard key={entitlement.api.id}>
            <H4>{entitlement.api.name}</H4>
            <Table>
              <tbody>
                {entitlement.scopeEntitlements
                  .filter((s) => s.liveMode === ciamClient?.liveMode)
                  .map((scopeEntitlement) => {
                    return (
                      <Tr key={scopeEntitlement.scopeName}>
                        <Td>
                          <Checkbox
                            checked={controller.values?.scopes?.includes(
                              scopeEntitlement.scopeName,
                            )}
                            disabled={!isAdmin}
                            label={
                              <BreakOnSpecialChars
                                data-content={scopeEntitlement.scopeName.replaceAll(
                                  /[.-_]/g,
                                  '$&\u200B',
                                )}
                              />
                            }
                            onChange={({ checked }) =>
                              onScopeChanged(
                                scopeEntitlement.scopeName,
                                checked,
                              )
                            }
                          />
                        </Td>
                      </Tr>
                    );
                  })}
              </tbody>
            </Table>
          </SpacedCard>
        ))}
        {isAdmin && client.liveMode && (
          <SpacedCard>
            <>
              <P bold>ServiceNow CRQ</P>
              <Table>
                <tbody>
                  <Tr>
                    <Td>
                      <Input
                        label="CRQ must be in implementation window."
                        label_direction="vertical"
                        right="small"
                        size="large"
                        stretch
                        value=""
                        {...register.input('crq')}
                      />
                    </Td>
                  </Tr>
                </tbody>
              </Table>
            </>
          </SpacedCard>
        )}
        <FormStatus bottom="large" text={errorMessage} top="medium" />
        <FormRow>
          {isAdmin && (
            <Button
              left="small"
              size="large"
              text="Update client"
              top="small"
              type="submit"
            />
          )}
        </FormRow>
      </Skeleton>
      {successMessage && (
        <Toast message={successMessage} position="bottom" variant="success" />
      )}
    </FormSet>
  );
}

const SpacedCard = styled(Card)`
  margin-top: 1rem;
`;

const BreakOnSpecialChars = styled.span`
  word-break: break-word;
  white-space: normal;

  /* Replace known separators with zero-width space for breaking */
  &::before {
    content: attr(data-content);
    word-break: break-word;
  }
`;
