import type { AdvancedContentFormData } from '@portals/shared/admin/ContentDto';
import { ContentDto } from '@portals/shared/portal/ContentDto';

import { request } from '@/request';

type InputData = Omit<AdvancedContentFormData, 'sortIndex'> & {
  sortIndex: number;
};

export async function createOrUpdatePage(
  teamId: string,
  content: InputData,
): Promise<ContentDto> {
  return request(`/team/${teamId}/team-pages`, 'put', content);
}

export async function deletePage(
  teamId: string,
  pageId: string,
): Promise<void> {
  return request(`/team/${teamId}/team-pages/${pageId}`, 'delete');
}
