import { Card, Icon, Link, P } from '@dnb/eufemia';
import FlexItem from '@dnb/eufemia/components/flex/Item';
import { IconIcon } from '@dnb/eufemia/components/Icon';
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

import style from './index.module.css';

const CenteredText = styled(P)`
  text-align: center;
`;
interface TileProps {
  title: string;
  description: string;
  linkTo: string;
  icon?: IconIcon;
}

export default function Tile({
  title,
  description,
  linkTo,
  icon,
}: TileProps): JSX.Element {
  return (
    <Card
      align="stretch"
      className={style['tile']}
      id="tile"
      innerSpace={false}
      justify="center"
      rowGap="small"
    >
      <Link
        className={
          style['anchor'] +
          ' dnb-anchor--no-style dnb-anchor--no-animation dnb-anchor--no-underline'
        }
        element={RouterLink}
        to={linkTo}
      >
        {icon && (
          <FlexItem alignSelf="center">
            <Icon color="seagreen" icon={icon} size="x-large" />
          </FlexItem>
        )}
        <FlexItem alignSelf="center">
          <P modifier="basis medium" size="medium">
            {title}
          </P>
        </FlexItem>
        <FlexItem alignSelf="center">
          <CenteredText>{description}</CenteredText>
        </FlexItem>
      </Link>
    </Card>
  );
}
