import { Dialog, ProgressIndicator } from '@dnb/eufemia';

export default function LoadingModal(): JSX.Element {
  return (
    <Dialog
      alignContent="centered"
      hideCloseButton
      maxWidth="14rem"
      noAnimation
      omitTriggerButton
      openState
      preventClose
      spacing={false}
    >
      <ProgressIndicator
        bottom="large"
        label_direction="vertical"
        show_label
        size="medium"
        top="large"
        type="linear"
      />
    </Dialog>
  );
}
