import styled from '@emotion/styled';

export const defaultTheme = {
  colors: {
    primary: 'var(--color-mint-green-25)',
    background: 'var(--color-mint-green-25)',
    backgroundTableSecond: 'var(--color-mint-green-25)',
    backgroundDocumentation: 'var(--color-black-3)',
    backgroundMenu: 'var(--color-white)',
    backgroundCard: 'var(--color-white)',
    backgroundCard2: 'var(--color-black-3)',
    backgroundInteractionColor: 'var(--color-mint-green-25)',
    interactionColor: 'var(--color-ocean-green)',
    menuLinks: 'var(--color-ocean-green)',
    menuLinksHover: 'var(--color-mint-green-25)',
    menuLinksActive: 'var(--color-mint-green-50)',
    menuSecondary: 'var(--color-black-55)',
  },
};

export const ThemeMapping = styled.div`
  .eufemia-theme__prop-mapping--theme-dark {
    .CardWrapper {
      background-color: #2c2c2e;
    }

    .dark-mode {
      .dnb-table {
        border-radius: var(--tokens-radial-radial-s, 0.25rem);
        border-collapse: separate;

        border: 4px solid var(--ux-black-80, #333);

        thead th {
          background-color: var(--color-black);
        }

        tbody > tr {
          background-color: #333333;

          &:nth-of-type(2n) {
            background-color: var(--color-black);
          }
        }
      }
      .dnb-card {
        background-color: #2c2c2e !important;
      }
      .dnb-section {
        background-color: black !important;
      }
      .dnb-form-label {
        color: white;
      }
      .settings {
        background-color: black !important;
      }
      .dnb-tag {
        &__group {
          .dnb-button {
            background-color: black !important;
          }
        }
      }
      .dnb-accordion {
        &__header {
          background-color: #2c2c2e;
          color: var(--color-white);
          &:hover {
            background-color: #2c2c2e !important;
            box-shadow: 0 0 0 var(--border-width) var(--color-mint-green) !important;
            color: var(--color-mint-green) !important;
          }
        }
      }
      .dnb-dialog {
        background: black;
        color: white;

        a {
          color: var(--color-mint-green);
        }
      }

      .dnb-section {
        &--mint-green-25 {
          background-color: black;
          color: white;
        }

        &--white {
          background-color: black;
          color: white;
        }

        &--pistachio {
          background-color: black;
          color: white;
        }
      }

      .dnb-input {
        &__placeholder {
          color: var(--color-black-20);
        }

        &__shell {
          background-color: black;
          color: white;
          --border-color: var(--color-mint-green);
        }
      }

      .dnb-anchor {
        color: var(--color-mint-green);
      }

      .dnb-tabs__button__title {
        color: white;
      }

      .dnb-code {
        background: var(--color-ocean-green);
        color: var(--color-mint-green);
      }

      .dnb-icon {
        svg {
          color: var(--color-mint-green);
        }
      }

      .dnb-button--secondary {
        background-color: #1c1c1e;
        --border-color: var(--color-mint-green);
        color: var(--color-mint-green);
      }

      .dnb-button--tertiary {
        color: var(--color-mint-green);

        svg {
          color: var(--color-mint-green);
        }
      }

      .dnb-dropdown {
        button {
          background-color: black;
          color: var(--color-mint-green-25);
        }
      }

      .dnb-info-card {
        background-color: #2c2c2e;
      }

      .dnb-tabs {
        .selected {
          &:after {
            background-color: var(--color-mint-green);

            .dnb-tabs__button {
              background-color: var(--color-mint-green);
            }
          }

          .dnb-tabs__button__title {
            color: var(--color-mint-green);
          }
        }

        &__button {
          :hover {
            background-color: var(--color-emerald-green);

            :after {
              background-color: white;
              height: 1px;
            }
          }
        }

        .dnb-tabs__button__title {
          color: var(--color-mint-green-25);
        }
      }
    }
  }

  .eufemia-theme__prop-mapping--theme-awesome {
    .dnb-section--mint-green-25 {
      background: linear-gradient(
        135.12deg,
        rgba(253, 1, 1, 0.04) 8.01%,
        rgba(253, 141, 1, 0.04) 24.54%,
        rgba(255, 230, 1, 0.04) 42.27%,
        rgba(40, 180, 130, 0.04) 59.99%,
        rgba(37, 95, 200, 0.04) 77.24%,
        rgba(194, 46, 220, 0.04) 95.45%
      );
      color: #23195a;
    }
    .dnb-input {
      &__placeholder {
        color: var(--color-black-20);
      }
      &__shell {
        background-color: black;
        color: white;
        --border-color: var(--color-mint-green);
      }
    }

    .dnb-anchor {
      color: var(--color-mint-green);
    }
    .dnb-tabs__button__title {
      color: white;
    }
    .dnb-code {
      background: var(--color-ocean-green);
      color: var(--color-mint-green);
    }
    .dnb-section--white {
      background-color: black;
      color: white;
    }
    .dnb-icon {
      svg {
        color: var(--color-mint-green);
      }
    }
    .dnb-button--secondary {
      background-color: #1c1c1e;
      --border-color: var(--color-mint-green);
      color: var(--color-mint-green);
    }

    .dnb-button--tertiary {
      color: var(--color-mint-green);

      svg {
        color: var(--color-mint-green);
      }
    }
    .dnb-dropdown {
      button {
        background-color: black;
        color: var(--color-mint-green-25);
      }
    }
    .dnb-tabs {
      .selected {
        &:after {
          background-color: var(--color-mint-green);
          .dnb-tabs__button {
            background-color: var(--color-mint-green);
          }
        }
        .dnb-tabs__button__title {
          color: var(--color-mint-green);
        }
      }
      .dnb-tabs__button__title {
        color: var(--color-mint-green-25);
      }
    }
  }
`;
