import { Img, P } from '@dnb/eufemia';
import styled from '@emotion/styled';

import NoDataFly from './NoDataFly.svg?url';
interface NoDataProps {
  message: string;
}
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export default function NoData({ message }: NoDataProps) {
  return (
    <Wrapper>
      <Img alt="A fly and no data" src={NoDataFly} width={40} />
      <P top="medium">{message}</P>
    </Wrapper>
  );
}
