import {
  Button,
  Div,
  H1,
  Link,
  P,
  Section,
  Skeleton,
  Space,
  Span,
  Tag,
} from '@dnb/eufemia';
import type { ApiDto } from '@portals/shared/portal/ApiDto';
import type { OperationDto } from '@portals/shared/portal/ReleaseDto';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { ApiClassification } from './components/ApiClassification';

import style from './QuickLook.module.css';
/**
 * Dummy data for skeletons
 */

const DUMMY_OPERATIONS = new Array(10).fill(null).map<OperationDto>((_, i) => ({
  summary: `Some summary ${i}`,
  method: 'get',
}));

interface QuickLookProps {
  api: ApiDto;
}

function documentationUrl(slug: string) {
  return `/documentation/${slug}/@default/@latest`;
}

export function QuickLook({ api }: QuickLookProps): JSX.Element {
  const [showAll, setShowAll] = useState(false);

  const { data: operations, isValidating: operationsLoading } = useSWR<
    OperationDto[]
  >(`/api/${api.slug}/environments/@default/releases/@latest/endpoints`);
  const hasLoaded = !operationsLoading;

  const truncatedOperations = useMemo(() => {
    if (!hasLoaded) {
      return DUMMY_OPERATIONS;
    } else if (showAll) {
      return operations;
    } else {
      return operations?.slice(0, 10);
    }
  }, [hasLoaded, operations, showAll]);

  const shouldDisplayShowAllButton =
    !truncatedOperations ||
    !operations ||
    (truncatedOperations.length < operations.length && !showAll);

  return (
    <Section style_type="white">
      <header className={style['QuickLook-header']}>
        <H1 size="medium">{api.name}</H1>
        <ApiClassification classification={api.classification} />
      </header>

      <Space className={style['QuickLook-content']} top="x-small">
        {api.tags.length > 0 && (
          <Tag.Group label="API tags">
            {api.tags.map((tag) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </Tag.Group>
        )}

        <P top="small">{api.description}</P>

        {truncatedOperations && (
          <Skeleton show={!hasLoaded} top="medium">
            <ul className={style['QuickLook-operations']}>
              {truncatedOperations.map(({ summary, method }, i) => (
                <li key={i}>
                  <Div
                    className={classNames(
                      style['QuickLook-method'],
                      style[`QuickLook-method--${method}`],
                    )}
                  >
                    {method}
                  </Div>
                  <Span>{summary}</Span>
                </li>
              ))}
            </ul>

            {shouldDisplayShowAllButton && (
              <Button
                icon="chevron_down"
                on_click={() => setShowAll(true)}
                top="x-small"
                variant="tertiary"
              >
                Show all operations ({operations?.length})
              </Button>
            )}
          </Skeleton>
        )}

        <Space className={style['QuickLook-actions']} top="small">
          {api.productPageUrl && (
            <Link element={RouterLink} to={api.productPageUrl}>
              Product page
            </Link>
          )}
          <Button
            element={RouterLink}
            icon="chevron_right"
            icon_position="right"
            target="blank"
            to={documentationUrl(api.slug)}
            variant="secondary"
          >
            Documentation
          </Button>
        </Space>
      </Space>
    </Section>
  );
}
