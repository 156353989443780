/* eslint-disable max-lines */
import {
  Accordion,
  Avatar,
  Button,
  Div,
  Dropdown,
  Flex,
  Icon,
  P,
  Span,
} from '@dnb/eufemia';
import {
  chevron_down,
  chevron_up,
  close_medium,
  log_in_medium,
  log_out_medium,
  person_medium,
} from '@dnb/eufemia/icons';
import { useMedia } from '@dnb/eufemia/shared';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { logout } from '@/auth/helpers';
import useMenuEnterPress from '@/hooks/useMenuEnterPress';
import useReturnTo from '@/hooks/useReturnTo';
import { useUser } from '@/hooks/useUser';

import { openMenu } from '..';

import style from './index.module.css';

interface MenuLink {
  title: string;
  to: string;
  underline?: boolean;
  subTitle?: string;
  isVisible?: boolean;
  isProfileMenu?: boolean;
}

interface NavigationButtonElementProps {
  props: any;
  showChevron?: boolean;
}

export interface NavigationButtonProps {
  title: string;
  links?: MenuLink[];
  isProfileMenu?: boolean;
  icon?: (props: any) => any;
  setWhichMenuIsOpen?: React.Dispatch<React.SetStateAction<openMenu>>;
}

export default function NavigationButton({
  title,
  links,
  isProfileMenu = false,
  icon,
  setWhichMenuIsOpen,
}: NavigationButtonProps) {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { isLarge } = useMedia();
  const [, setReturnTo] = useReturnTo();
  const { user, isLoggedIn } = useUser();

  const handleTabulationNavigation = (e: KeyboardEvent) => {
    const target = e.target as HTMLDivElement;

    const accordionOrDropdown =
      target?.id?.startsWith('option-menu') ||
      target?.id?.startsWith('linkItem') ||
      target?.id?.startsWith('option-profile');
    if (accordionOrDropdown) {
      if (e.key === 'myProfile' || target?.textContent === 'My profile') {
        navigate('/profile/teams');
        setDropdownOpen(false);
      }
      if (e.key === 'logout' || target.textContent === 'Log out') {
        logout();
        setDropdownOpen(false);
      }

      if (e && e.target) {
        const linkNavigate = links?.find((link) =>
          target.textContent?.includes(link.title),
        );

        if (links && linkNavigate && linkNavigate.to && accordionOrDropdown) {
          navigate(linkNavigate.to);
          setDropdownOpen(false);
        }
      }
    }
  };

  const linkRef = useMenuEnterPress(handleTabulationNavigation);

  const LinkItem = (link: MenuLink) => {
    return (
      <Div
        id="linkItem"
        key={link.to}
        onPointerUp={() => navigate(link.to)}
        ref={linkRef}
        tabIndex={0}
      >
        <Flex.Horizontal
          align="center"
          className={
            link.isProfileMenu
              ? style['dropdownLinkProfile']
              : style['dropdownLink']
          }
          justify="space-between"
        >
          <Div className={style['dropdownTextWrapper']}>
            <P className={style['dropdownTitle']}>{link.title}</P>
            {link.subTitle && (
              <P className={style['dropdownSubtitle']} modifier="x-small">
                {link.subTitle}
              </P>
            )}
          </Div>
        </Flex.Horizontal>
      </Div>
    );
  };

  const buttonActiveClasses = dropdownOpen
    ? classNames(style['navigationButton'], style['navigationButtonActive'])
    : style['navigationButton'];

  const profileMenuVisibleClasses = dropdownOpen
    ? classNames(style['profileDropdown'], style['profileDropdownVisible'])
    : classNames(style['profileDropdown'], style['profileDropdownHidden']);

  const NavigationButtonElement = ({
    props,
    showChevron,
  }: NavigationButtonElementProps) => {
    return (
      <Div className={style['buttonContainer']} key={title} ref={linkRef}>
        <button {...props} className={style['navigationButton']} type="button">
          <Flex.Horizontal align="center" justify="center">
            <Div className={style['navigationIcon']}>
              <Icon icon={icon} size={24} />
            </Div>
            <Span className={style['navigationButtonText']}>{title}</Span>
          </Flex.Horizontal>
          {showChevron && (
            <div>
              <Icon icon={chevron_down} size={24} />
            </div>
          )}
        </button>
      </Div>
    );
  };

  return (
    <Div>
      {links?.length === 1 ? (
        <Div>
          {links.map((link) => (
            <NavigationButtonElement
              key={link.title}
              props={{
                onClick: () => {
                  navigate(link.to);
                },
                key: link.title,
              }}
            />
          ))}
        </Div>
      ) : isProfileMenu ? (
        <div className={style['profileAvatarWrapper']}>
          {isLoggedIn && isLarge ? (
            <Dropdown
              action_menu
              align_dropdown="right"
              data={() => [
                <div
                  className={isLarge ? '' : style['buttonContainer']}
                  id="profile"
                  key="myProfile"
                  onClick={() => navigate('/profile/teams')}
                  ref={linkRef}
                  tabIndex={0}
                >
                  <div className={isLarge ? '' : style['navigationButton']}>
                    <Flex.Horizontal
                      align="center"
                      className={style['dropdownLinkProfile']}
                    >
                      <Flex.Item grow>
                        {isLarge ? (
                          <P modifier="basis">My profile</P>
                        ) : (
                          <Span className={style['navigationButtonText']}>
                            My profile
                          </Span>
                        )}
                      </Flex.Item>
                      <Icon icon={person_medium} />
                    </Flex.Horizontal>
                  </div>
                </div>,
                <div
                  className={isLarge ? '' : style['buttonContainer']}
                  id="logout"
                  key="logout"
                  onClick={logout}
                  ref={linkRef}
                  tabIndex={0}
                >
                  <div className={isLarge ? '' : style['navigationButton']}>
                    <Flex.Horizontal
                      align="center"
                      className={style['dropdownLinkProfile']}
                    >
                      <Flex.Item grow>
                        {isLarge ? (
                          <P modifier="basis">Log out</P>
                        ) : (
                          <Span className={style['navigationButtonText']}>
                            Log out
                          </Span>
                        )}
                      </Flex.Item>
                      <Icon icon={log_out_medium} size={24} />
                    </Flex.Horizontal>
                  </div>
                </div>,
              ]}
              icon_position="right"
              id="profile"
              on_select={handleTabulationNavigation}
              skip_portal
              triangle_position="right"
              trigger_element={(props) => (
                <button
                  {...props}
                  className={style['Avatar']}
                  role="button"
                  tabIndex={0}
                >
                  <Avatar size="medium" variant="secondary">
                    {user?.firstName}
                  </Avatar>
                </button>
              )}
              variant="tertiary"
            />
          ) : isLoggedIn && !isLarge ? (
            <Div className={style['profileMenuWrapper']}>
              <Div className={style['avatarWrapper']}>
                {dropdownOpen && !isLarge ? (
                  <Button
                    icon={close_medium}
                    icon_position="top"
                    left
                    on_click={() => {
                      setDropdownOpen(false);
                      setWhichMenuIsOpen!(openMenu.none);
                    }}
                    size="large"
                    tabIndex={0}
                    text="Close"
                    variant="tertiary"
                  />
                ) : (
                  <button
                    className={style['Avatar']}
                    onClick={() => {
                      setDropdownOpen(!dropdownOpen);
                      setWhichMenuIsOpen!(openMenu.ProfileDropDown);
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <Avatar size="medium" variant="secondary">
                      {user?.firstName}
                    </Avatar>
                  </button>
                )}
              </Div>
              <div className={profileMenuVisibleClasses}>
                <div className={style['arrowUp']} />
                <div className="dnb-unstyled-list" id="profileDropdownList">
                  <div
                    className={style['buttonContainer']}
                    onClick={() => navigate('/profile/teams')}
                  >
                    <div
                      className={style['navigationButton']}
                      id="linkItem-myProfile"
                      ref={linkRef}
                      tabIndex={0}
                    >
                      <Flex.Horizontal
                        align="center"
                        className={style['dropdownLinkProfile']}
                      >
                        <Flex.Item grow>
                          <Span className={style['navigationButtonText']}>
                            My profile
                          </Span>
                        </Flex.Item>
                        <Icon icon={person_medium} />
                      </Flex.Horizontal>
                    </div>
                  </div>
                  <div className={style['buttonContainer']} onClick={logout}>
                    <div
                      className={style['navigationButton']}
                      id="linkItem-logout"
                      ref={linkRef}
                      tabIndex={0}
                    >
                      <Flex.Horizontal
                        align="center"
                        className={style['dropdownLinkProfile']}
                      >
                        <Flex.Item grow>
                          <Span className={style['navigationButtonText']}>
                            Log out
                          </Span>
                        </Flex.Item>
                        <Icon icon={log_out_medium} size={24} />
                      </Flex.Horizontal>
                    </div>
                  </div>
                </div>
              </div>
            </Div>
          ) : (
            <Button
              element={RouterLink}
              icon={log_in_medium}
              icon_position="top"
              on_click={() => {
                setReturnTo(location.pathname);
              }}
              size="large"
              to="/login"
              variant="tertiary"
            >
              Log in
            </Button>
          )}
        </div>
      ) : isLarge ? (
        <Dropdown
          action_menu
          data={() => {
            return links?.map((link) => (
              <LinkItem
                key={link.title}
                subTitle={link.subTitle}
                title={link.title}
                to={link.to}
              />
            ));
          }}
          icon_position="right"
          id="menu"
          on_select={handleTabulationNavigation}
          skip_portal
          triangle_position="left"
          trigger_element={(props) => (
            <button type="button" {...props} className={buttonActiveClasses}>
              <Div className={style['navigationIcon']}>
                <Icon icon={icon} size={24} />
              </Div>
              <Span className={style['navigationButtonText']}>{title}</Span>

              <Icon
                className={
                  dropdownOpen
                    ? style['chevron']
                    : classNames(style['chevron'], style['chevronRotate'])
                }
                icon={chevron_up}
              />
            </button>
          )}
          variant="tertiary"
        />
      ) : (
        <Accordion
          element={(props: any) => (
            <NavigationButtonElement props={props} showChevron />
          )}
          id="burgerMenuAccordion"
          left_component={<Icon icon={icon} size={24} />}
        >
          {links?.map((link) => (
            <LinkItem
              key={link.title}
              subTitle={link.subTitle}
              title={link.title}
              to={link.to}
            />
          ))}
        </Accordion>
      )}
    </Div>
  );
}
