import { EventDto } from '@portals/shared/portal/EventDto';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import Page from '@/components/Page';

export default function EventPage() {
  const { slug } = useParams();
  const { data: event, isValidating } = useSWR<EventDto>(
    slug && `/v2/events/${slug}`,
  );

  return <Page skeleton={isValidating} spacing={false} title={event?.name} />;
}
