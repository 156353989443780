import { Space } from '@dnb/eufemia';
import type { SpacingElementProps } from '@dnb/eufemia/shared/types';
import classNames from 'classnames';
import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react';

import style from './index.module.css';

export type ContainerProps = ComponentPropsWithoutRef<'div'> &
  SpacingElementProps &
  PropsWithChildren<{
    size?: 'small' | 'medium' | 'large' | 'x-large';
    centered?: boolean;
  }>;

export default function Container({
  size = 'large',
  centered = false,
  className,
  ...props
}: ContainerProps): JSX.Element {
  return (
    <Space
      className={classNames({ [style['Container--centered']]: centered }, [
        style['Container'],
        style[`Container--size-${size}`],
        className,
      ])}
      {...props}
    />
  );
}
