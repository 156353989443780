import { Card, Div, Flex, H1, H4, Icon } from '@dnb/eufemia';
import { IconIcon } from '@dnb/eufemia/components/Icon';
import {
  accounting as AccountingIcon,
  arrow_down as ArrowDownIcon,
  arrow_up as ArrowUpIcon,
  bank as BankIcon,
  filter as FilterIcon,
  person_medium as PersonIcon,
  speedometer as SpeedOMeterIcon,
} from '@dnb/eufemia/icons';
import type { ReactNode } from 'react';

import type { StatsType } from '../../Stats';

import * as styles from '../../Stats/index.styles';
const icons: Record<string, ReactNode> = {
  arrow_down: ArrowDownIcon,
  arrow_up: ArrowUpIcon,
  bank: BankIcon,
  filter: FilterIcon,
  person_medium: PersonIcon,
  speedometer: SpeedOMeterIcon,
  undefined: AccountingIcon, // will render as defualt if no icon is provided
};

const InfoStat = ({ type = 'info', data }: StatsType) => {
  const { icon } = data;
  const i = (
    icon && typeof icon === 'string' ? icons[icon] : (icon as IconIcon)
  ) as IconIcon; // Hack
  return (
    <Card key={type}>
      <Flex.Container>
        {data?.icon && (
          <styles.Icon>
            <Icon icon={i} size="large" />
          </styles.Icon>
        )}
        <styles.Content>
          <H4>{data.title}</H4>
          <H1>{data.text}</H1>
          {data.progress && data.progress.progress && (
            <Div className="progress">
              <Div
                className={`badge${
                  data?.progress?.progress >= 0 ? ' up' : ' down'
                }`}
              >
                {' '}
                <Icon
                  icon={
                    data?.progress?.progress >= 0 ? ArrowUpIcon : ArrowDownIcon
                  }
                  size="small"
                />{' '}
                {data?.progress?.progress}
                {data?.progress?.type === 'process' ? '%' : ''}
              </Div>{' '}
              {data?.progress?.text}
            </Div>
          )}
        </styles.Content>
      </Flex.Container>
    </Card>
  );
};

export default InfoStat;
