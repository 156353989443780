import type { Dereferenced } from '@portals/shared/common/open-api/types';
import type { OpenAPIV3 } from 'openapi-types';
import type { ReactNode } from 'react';

export const SecurityTypeMap: Record<string, string> = {
  apiKey: 'API key',
  oauth2: 'OAuth 2',
  http: 'HTTP basic',
  openIdConnect: 'OpenID Connect',
};

export type DereferencedDocument = Dereferenced<OpenAPIV3.Document>;
export type DereferencedTag = Dereferenced<OpenAPIV3.TagObject>;
export type DereferencedMediaTypeObject =
  Dereferenced<OpenAPIV3.MediaTypeObject>;
export type DereferencedSchemaObject = Dereferenced<OpenAPIV3.SchemaObject>;
export type DereferencedPathsObject = Dereferenced<OpenAPIV3.PathsObject>;
export type DereferencedPathItemObject = Dereferenced<OpenAPIV3.PathItemObject>;
export type DereferencedOperationObject =
  Dereferenced<OpenAPIV3.OperationObject>;
export type DereferencedParameterObject =
  Dereferenced<OpenAPIV3.ParameterObject>;
export type DereferencedResponseObject = Dereferenced<OpenAPIV3.ResponseObject>;
export type Server = OpenAPIV3.ServerObject;
export type DereferencedSecuritySchemeObject =
  Dereferenced<OpenAPIV3.SecuritySchemeObject>;
export type DereferencedSecurityRequirementObject =
  Dereferenced<OpenAPIV3.SecurityRequirementObject>;
export type DereferencedComponentsObject =
  Dereferenced<OpenAPIV3.ComponentsObject>;

export type Methods = Pick<
  DereferencedPathItemObject,
  'get' | 'post' | 'put' | 'patch' | 'delete' | 'trace' | 'options' | 'head'
>;

export const methods: Array<keyof Methods> = [
  'get',
  'post',
  'put',
  'patch',
  'delete',
  'trace',
  'options',
  'head',
  'trace',
];

export interface SelectionType {
  category: string;
  operation: string | null;
  path?: string;
}

export interface TagType {
  name: string;
  href: string;
  description?: string | null;
}

export type SearchItem = {
  name: string;
  description?: string | null;
};

export interface LinkType {
  path: string;
  operator: string | null;
  name: string;
  description?: string;
}

export type DropdownOption = {
  key: string;
  content: ReactNode | string;
  selected_value?: string;
};

export interface APIType {
  reference?: OpenAPIV3.Document | null;
  guide?: string | null;
}

export type MenuItemType = {
  title: string;
  key: string;
};

export type TabsType = {
  active: string;
  items: MenuItemType[];
};
