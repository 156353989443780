import { useRef } from 'react';

interface Thunk<T, U> {
  (id: U): T;
}

type Dependencies = readonly unknown[];

interface CallbackMap<K, V> {
  get(key: K): [V, Dependencies] | undefined;
  set(key: K, value: [V, Dependencies]): void;
}

function _useMemoizedThunk<T, U>(
  thunk: Thunk<T, U>,
  deps: Dependencies,
  map: CallbackMap<U, T>,
) {
  const callbacks = useRef(map);

  const register = (id: U) => {
    const exists = callbacks.current.get(id);

    if (exists) {
      const [callback, previousDeps] = exists;

      if (
        deps.length === previousDeps.length &&
        deps.every((dep, idx) => dep === previousDeps[idx])
      ) {
        return callback;
      }
    }

    const callback = thunk(id);
    callbacks.current.set(id, [callback, deps]);
    return callback;
  };

  return register;
}

export function useMemoizedThunk<T, U>(thunk: Thunk<T, U>, deps: Dependencies) {
  return _useMemoizedThunk(thunk, deps, new Map<U, [T, Dependencies]>());
}

export function useMemoizedThunkWeak<T, U extends object>(
  thunk: Thunk<T, U>,
  deps: Dependencies,
) {
  return _useMemoizedThunk(thunk, deps, new WeakMap<U, [T, Dependencies]>());
}
