import { Dialog, Link, P } from '@dnb/eufemia';
import { useAsync } from '@portals/shared-frontend/hooks';
import { useEffect, useState } from 'react';

import { acceptTermsOfUse } from '@/api/user';
import { logout } from '@/auth/helpers';
import { useUser } from '@/hooks/useUser';

export default function TermsOfUseAlert(): JSX.Element {
  const { reload: reloadUser } = useUser();
  const [hasError, setHasError] = useState(false);

  const onAcceptTermsOfUse = useAsync(async () => {
    await acceptTermsOfUse();
    reloadUser();
  }, [reloadUser]);

  const onCancel = useAsync(async () => await logout(), []);

  useEffect(() => {
    if (onAcceptTermsOfUse.error) {
      setHasError(true);
    }
  }, [onAcceptTermsOfUse.error]);

  return hasError ? (
    <Dialog
      confirmText="Go to Landing"
      hideCloseButton
      hideDecline
      icon="information_medium"
      onConfirm={onCancel.execute}
      openState
      title="Updated terms of use"
      triggerAttributes={{ hidden: true }}
      variant="confirmation"
    >
      <P bold bottom="small">
        Something went wrong
      </P>
      <P>
        Please try again. If that does not work, please report the issue{' '}
        <Link to="/help-center/technical">here</Link>.
      </P>
    </Dialog>
  ) : (
    <Dialog
      confirmText="I agree"
      icon="information_medium"
      onConfirm={onAcceptTermsOfUse.execute}
      onDecline={onCancel.execute}
      openState
      title="Updated terms of use"
      triggerAttributes={{ hidden: true }}
      variant="confirmation"
    >
      <P>
        We have updated our <Link href="/terms-of-use">terms of use</Link>.
        Please agree to the updated terms of use before proceeding further.
      </P>
    </Dialog>
  );
}
