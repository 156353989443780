import { Button, GlobalError, Img, Section, Space } from '@dnb/eufemia';
import { Link } from 'react-router-dom';

import Container from '@/components/Container';
import Footer from '@/components/Footer';
import MenuBar from '@/components/MenuBar';

import style from './NotFound.module.css';

import WomanSearchingLight from '@/illustrations/WomanSearchingLight.svg?url';

export default function NotFound(): JSX.Element {
  return (
    <>
      <Section style_type="white">
        <MenuBar />
      </Section>
      <Container
        bottom="xx-large large"
        centered
        className={style['Content']}
        size="small"
      >
        <GlobalError code={null} />
        <Img
          alt="A woman searching in a box"
          src={WomanSearchingLight}
          width={320}
        />
        <Space>
          <Button element={Link} size="large" to="/" top="large">
            Go to landing page
          </Button>
        </Space>
      </Container>
      <Footer />
    </>
  );
}
