import { useContext } from 'react';

import UserContext from './UserContext';

export function useUser() {
  const { user, isLoading, reload } = useContext(UserContext);
  return {
    user,
    isLoading: isLoading,
    isLoggedIn: !!user,
    reload,
  };
}
