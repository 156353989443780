/* eslint-disable max-lines */
import {
  Checkbox,
  Div,
  Drawer,
  Flex,
  FormLabel,
  FormRow,
  FormSet,
  Grid,
  Icon,
  InfoCard,
  Input,
  P,
  Tag,
} from '@dnb/eufemia';
import { filter as filterIcon } from '@dnb/eufemia/icons';
import { useMedia } from '@dnb/eufemia/shared';
import type { ApiDto } from '@portals/shared/portal/ApiDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { isInternalUser } from '@portals/shared-frontend/utils';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { object } from 'zod';

import HeroPage from '@/components/HeroPage';
import { createDummyApi } from '@/dummyData';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useReturnTo from '@/hooks/useReturnTo';
import { useUser } from '@/hooks/useUser';

import {
  API_EXPLORER_TABS,
  EXTERNAL_CLASSIFICATIONS,
  INTERNAL_CLASSIFICATIONS,
} from '../../content';
import ApiCard from '../ApiCard';
import CommonRepoApiInfoCard from '../CommonRepoApiInfoCard';

import style from './index.module.css';

const DUMMY_APIS = new Array(10).fill(null).map((_, id) => createDummyApi(id));

type ApiExplorerProps = PropsWithChildren<{
  title: string;
  showClassificationFilter?: boolean;
  showApiCategoryFilter?: boolean;
  apiType?: API_EXPLORER_TABS;
}>;

export default function ApiPage({
  title,
  showClassificationFilter = false,
  showApiCategoryFilter = false,
  apiType = API_EXPLORER_TABS.Commercial,
  children,
}: ApiExplorerProps): JSX.Element {
  const { featureFlags } = useFeatureFlags();
  const { isLarge } = useMedia();
  const location = useLocation();
  const [, setReturnTo] = useReturnTo();
  const { user, isLoading: userLoading } = useUser();
  const navigate = useNavigate();
  const [tagFilter, setTagFilter] = useState<string[]>([]);
  const [totalApiCount, setTotalApiCount] = useState<number>(0);
  const [classificationFilter, setClassificationFilter] = useState<string[]>(
    [],
  );
  const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
  const [query, setQuery] = useState('');
  const {
    data: apis,
    mutate,
    isValidating: apisLoading,
  } = useSWR<ApiDto[]>('/api');

  enum categories {
    InternalApis = 'Internal APIs',
    RegulatoryApis = 'Regulatory APIs',
    CorporateApis = 'Corporate APIs',
  }

  const isDnbUser = isInternalUser(user?.email);

  const filteredApis = useMemo(() => {
    if (!apis) {
      return DUMMY_APIS;
    }

    const lowerCasedQuery = query.toLowerCase();
    const tagSet = new Set(tagFilter);
    const classificationSet = new Set(classificationFilter);

    const basis: ApiDto[] = apis
      .filter(
        (api) =>
          !lowerCasedQuery || api.name.toLowerCase().includes(lowerCasedQuery),
      )
      .filter(
        (api) =>
          tagFilter.length === 0 || api.tags.some((tag) => tagSet.has(tag)),
      )
      .filter(
        (api) =>
          classificationFilter.length === 0 ||
          classificationSet.has(api.classification),
      );

    switch (apiType) {
      case API_EXPLORER_TABS.External: {
        return basis.filter((api) =>
          EXTERNAL_CLASSIFICATIONS.has(api.classification),
        );
      }
      case API_EXPLORER_TABS.Internal: {
        return basis.filter((api) =>
          INTERNAL_CLASSIFICATIONS.has(api.classification),
        );
      }
      case API_EXPLORER_TABS.Regulatory: {
        return basis
          .filter((api) => EXTERNAL_CLASSIFICATIONS.has(api.classification))
          .filter(({ tags }) => tags.includes('PSD2'));
      }
      case API_EXPLORER_TABS.Commercial: {
        return basis
          .filter((api) => EXTERNAL_CLASSIFICATIONS.has(api.classification))
          .filter(({ tags }) => !tags.includes('PSD2'));
      }
      case API_EXPLORER_TABS.Review: {
        return basis.filter((api) => api.stage === 'review');
      }
    }
  }, [apis, query, tagFilter, apiType, classificationFilter]);

  useEffect(() => {
    filteredApis && setTotalApiCount(filteredApis.length);
  }, [filteredApis]);

  useEffect(() => {
    if (userLoading || isDnbUser || apiType !== API_EXPLORER_TABS.Internal) {
      return;
    }

    if (user) {
      // TODO: Set global 404 stuff
      navigate('/404', { replace: true });
    } else {
      setReturnTo(location.pathname);
      navigate('/login', { replace: true });
    }
  }, [
    location.pathname,
    navigate,
    setReturnTo,
    userLoading,
    apiType,
    isDnbUser,
    user,
  ]);

  function updateFilter(array: string[], value: string) {
    return array.filter((item) => item !== value);
  }

  const tagCounts = useMemo(() => {
    if (!filteredApis) {
      return {};
    }
    return filteredApis
      .flatMap((api) => api.tags)
      .reduce<Record<string, number>>((result, tag) => {
        result[tag] = result[tag] ? ++result[tag] : 1;
        return result;
      }, {});
  }, [filteredApis]);

  const classificationCount = useMemo(() => {
    if (!filteredApis) {
      return {};
    }
    return filteredApis
      .flatMap((api) => api.classification)
      .reduce<Record<string, number>>((result, classification) => {
        result[classification] = result[classification]
          ? ++result[classification]
          : 1;
        return result;
      }, {});
  }, [filteredApis]);

  const { handleSubmit } = useEufemiaForm(object({}), {});

  const tags = useMemo(() => {
    const set = new Set<string>();

    for (const { tags } of apis || []) {
      for (const tag of tags) {
        set.add(tag);
      }
    }

    return Array.from(set);
  }, [apis]);

  const classifications = useMemo(() => {
    const set = new Set<string>();

    for (const { classification } of apis || []) {
      set.add(classification);
    }

    return Array.from(set);
  }, [apis]);

  return (
    <HeroPage
      heroIllustration={require('@/illustrations/DNB_Supergraphics_Duo_summer_emerald.avif?url')}
      noContainer
      skeleton={apisLoading && !apis}
      title={title}
    >
      <Grid.Container columnGap rowGap>
        {children && (
          <Grid.Item span={{ small: 'full', large: [5, 12] }}>
            <Div>{children}</Div>
          </Grid.Item>
        )}
        <Grid.Item
          className={style['activeFilterBox']}
          span={{ small: 'full', medium: 'full', large: [1, 4] }}
        >
          <Flex.Vertical space="small">
            <FormRow top="x-small">
              <Drawer
                id="tagFilterDrawer"
                title="Filters"
                trigger={(props) => (
                  <Flex.Horizontal
                    {...props}
                    align="center"
                    className={style['filterTitle']}
                    gap="small"
                    justify="flex-start"
                  >
                    <Icon icon={filterIcon} />
                    <P modifier="medium">Filters</P>
                  </Flex.Horizontal>
                )}
              >
                <Div className={style['flyoutOptionWrapper']}>
                  <FormSet on_submit={handleSubmit(() => {})} vertical>
                    <FormRow top="medium">
                      <FormLabel>Search for API type or filter</FormLabel>
                      <Input
                        clear
                        icon="loupe"
                        on_change={({ value }) => setQuery(value)}
                        placeholder="i,e Domain, Payments etc."
                        stretch
                        value={query}
                      />
                    </FormRow>
                    {showClassificationFilter && (
                      <FormRow top="medium">
                        <P modifier="medium">API type</P>
                        {classifications.map((type) => (
                          <Checkbox
                            checked={classificationFilter.includes(type)}
                            className={style['checkBox']}
                            key={type}
                            label={`${type} (${
                              classificationCount[type] ?? 0
                            })`}
                            onChange={({ checked }) =>
                              checked
                                ? setClassificationFilter([type])
                                : setClassificationFilter(
                                    updateFilter(classificationFilter, type),
                                  )
                            }
                            value={type}
                          />
                        ))}
                      </FormRow>
                    )}
                    {showApiCategoryFilter && (
                      <FormRow top="medium">
                        <P modifier="medium">API Categories</P>
                        {Object.values(categories).map((category) => (
                          <Checkbox
                            checked={categoryFilter.includes(category)}
                            className={style['checkBox']}
                            key={category}
                            label={category}
                            onChange={({ checked }) =>
                              checked
                                ? setCategoryFilter([category])
                                : setCategoryFilter([])
                            }
                            value={category}
                          />
                        ))}
                      </FormRow>
                    )}

                    <FormRow top="medium">
                      <P modifier="medium">Tags</P>
                      {tags.map((tag) => (
                        <Checkbox
                          checked={tagFilter.includes(tag)}
                          className={style['checkBox']}
                          key={tag}
                          label={`${tag} (${tagCounts[tag] ?? 0})`}
                          onChange={({ checked }) =>
                            checked
                              ? setTagFilter([...tagFilter, tag])
                              : setTagFilter(updateFilter(tagFilter, tag))
                          }
                          value={tag}
                        />
                      ))}
                    </FormRow>
                  </FormSet>
                </Div>
              </Drawer>
            </FormRow>

            {isLarge && <P modifier="medium">Active filters</P>}
            <Tag.Group innerSpace="0" label="Filters" top="small">
              {tagFilter.length > 0 &&
                tagFilter.map((tag) => (
                  <Tag
                    key={tag}
                    onDelete={() =>
                      setTagFilter(
                        tagFilter.filter((candidate) => candidate !== tag),
                      )
                    }
                  >
                    {tag}
                  </Tag>
                ))}
              {classificationFilter.length > 0 &&
                showClassificationFilter &&
                classificationFilter.map((classification) => (
                  <Tag
                    key={classification}
                    onDelete={() =>
                      setClassificationFilter(
                        classificationFilter.filter(
                          (candidate) => candidate !== classification,
                        ),
                      )
                    }
                  >
                    {classification}
                  </Tag>
                ))}

              {isLarge &&
                tagFilter.length === 0 &&
                classificationFilter.length === 0 && <P>No filters chosen</P>}
            </Tag.Group>
          </Flex.Vertical>
        </Grid.Item>

        <Grid.Item span={{ small: 'full', large: [5, 12] }}>
          <FormRow bottom="small">
            <Input
              clear
              icon="loupe"
              on_change={({ value }) => setQuery(value)}
              placeholder="Search"
              stretch
              value={query}
            />
          </FormRow>
          <P modifier="medium" space={{ top: '1rem' }}>
            Showing {filteredApis.length} of {totalApiCount} apis
          </P>
          <Flex.Vertical
            align="stretch"
            gap="xx-small"
            space={{ top: 'small', bottom: 'small' }}
          >
            {filteredApis.length > 0 ? (
              <div>
                {filteredApis.map((api) => (
                  <ApiCard
                    api={api}
                    key={api.id}
                    onApiFavouriteChange={(apiId, isFavorite) =>
                      mutate((apis) => {
                        if (apis) {
                          const updatedApis = [...apis];
                          const index = updatedApis.findIndex(
                            (api) => api.id === apiId,
                          );
                          updatedApis[index].isFavorite = isFavorite;
                          return [...updatedApis];
                        }
                        return;
                      }, false)
                    }
                  />
                ))}
              </div>
            ) : featureFlags.ENABLE_COMMON_REPO_API ? (
              <CommonRepoApiInfoCard />
            ) : (
              <InfoCard text="No APIs match the selected filters." />
            )}
          </Flex.Vertical>
        </Grid.Item>
      </Grid.Container>
    </HeroPage>
  );
}
