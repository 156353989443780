import { throttle } from '@portals/shared-frontend/utils';
import { useCallback, useEffect, useRef, useState } from 'react';

export function useScrollSpy(ids: string[]) {
  const first = ids.length > 0 ? ids[0] : null;
  const activeIdRef = useRef(first);
  const [activeId, setActiveId] = useState(first);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    throttle(() => {
      const list = ids
        .map((id) => document.getElementById(id))
        .filter((element): element is HTMLElement => element != null)
        .map((element, index) => ({
          element,
          index,
          offset: element.getBoundingClientRect().top,
        }))
        .sort((a, b) => a.offset - b.offset)
        // 10px leeway because of zoom weirdness in Chrome
        .filter(({ offset }) => offset <= 10);

      if (list.length === 0) {
        activeIdRef.current = ids.length > 0 ? ids[0] : null;
      } else if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight
      ) {
        activeIdRef.current = ids.at(-1) || null;
      } else {
        activeIdRef.current = list.at(-1)?.element.id || null;
      }

      if (activeIdRef.current !== activeId) {
        setActiveId(activeIdRef.current);
      }
    }, 50),
    [activeId, ids, setActiveId],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return activeId;
}
