import { Button, Icon } from '@dnb/eufemia';
import { star as FavouriteIcon } from '@dnb/eufemia/icons';
import styled from '@emotion/styled';

const FavouriteFilledIcon = styled(FavouriteIcon)`
  fill: var(--color-accent-yellow);
  &.favourite-button [stroke] {
    stroke: var(--color-accent-yellow);
  }
`;

interface FavouriteProps {
  onClick: () => void;
  isFavourite: boolean;
  isLoading: boolean;
}

export default function FavouriteButton({
  onClick,
  isFavourite,
  isLoading = false,
}: FavouriteProps) {
  return (
    <Button
      bottom="small"
      disabled={isLoading}
      icon={
        isFavourite ? (
          <Icon
            icon={<FavouriteFilledIcon className="favourite-button" />}
            size="small"
          />
        ) : (
          <Icon icon={FavouriteIcon} size="small" />
        )
      }
      onClick={onClick}
      size="small"
    />
  );
}
