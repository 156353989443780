import type { CurrentApiAndScopeEntitlementsDto } from '@portals/shared/portal/AppApiEntitlementDto';

export const SELECT_OPTION_ERROR = 'Please select an option.';

export enum Purpose {
  'Consent' = 'Consent',
  'Necessary to fulfill a contract' = 'Necessary to fulfill a contract',
  'Necessary to fulfill a legal obligation' = 'Necessary to fulfill a legal obligation',
  'Necessary to protect vital interests' = 'Necessary to protect vital interests',
  'Necessary to perform a task in the public interest or exercise official authority' = 'Necessary to perform a task in the public interest or exercise official authority',
  'Necessary to pursue legitimate interests - balancing of interests' = 'Necessary to pursue legitimate interests - balancing of interests',
}

export enum SubscriberType {
  process = 'Process',
  data = 'Data',
  user_interface = 'User Interface',
}

export enum SubscriberSubType {
  process_configuration = 'Process configuration',
  foundation_data = 'Foundation data',
  configuration_items = 'Configuration items',
  events = 'Events',
  reporting = 'Reporting',
  sys_log = 'Sys log',
}

export type ServiceNowRequestFormModalProps = {
  onClose: () => void;
  entitlement: CurrentApiAndScopeEntitlementsDto;
};
