import {
  cloud,
  file_contract,
  file_loupe,
  layout_card,
  news,
  office_buildings,
  play,
} from '@dnb/eufemia/icons';
import { isInternalUser } from '@portals/shared-frontend/utils';

import HeroPage from '@/components/HeroPage';
import Tile from '@/components/Tile';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { useUser } from '@/hooks/useUser';

import ApiStatusWidget from './ApiStatusWidget';
import InformationBox from './InformationBox/InformationBox';

import style from './Landing.module.css';

import Inbox from '@/illustrations/Inbox.svg?url';

export default function LandingNew(): JSX.Element {
  const { user } = useUser();
  const isDnbUser = isInternalUser(user?.email);
  const { featureFlags } = useFeatureFlags();
  return (
    <HeroPage
      heroChildren={<ApiStatusWidget />}
      heroIllustration={require('@/illustrations/DNB_Supergraphics_front_page.avif?url')}
      noContainer
      title="Welcome to the developer portal"
    >
      <div className={style['LandingContent']} id="LandingContent">
        <div className={style['LandingContentFlex']}>
          <Tile
            description="PSD2"
            icon={file_contract}
            linkTo="/explorer/apis/regulatory"
            title="Regulatory APIs"
          />
          <Tile
            description="Non-regulatory APIs"
            icon={cloud}
            linkTo="/explorer/apis/commercial"
            title="Commercial APIs"
          />
          {isDnbUser && (
            <Tile
              description="APIs available for internals"
              icon={office_buildings}
              linkTo="/explorer/apis/internal"
              title="Internal APIs"
            />
          )}
          <Tile
            description="Start using APIs"
            icon={play}
            linkTo="/getting-started"
            title="Get started"
          />
          {isDnbUser && (
            <>
              {/*TODO: Remove conditional rendering when we are gonna show events*/}
              {false && (
                <Tile
                  description="Discover events"
                  icon={news}
                  linkTo="/explorer/events"
                  title="Events"
                />
              )}
              {featureFlags.ENABLE_COMMON_REPO_API && (
                <Tile
                  description="DNB common contract repo"
                  icon={layout_card}
                  linkTo="/api-contracts"
                  title="API contracts"
                />
              )}
              {featureFlags.ENABLE_API_REVIEW_PAGE && (
                <Tile
                  description="Visible to API custodians only."
                  icon={file_loupe}
                  linkTo="/explorer/apis/review"
                  title="APIs in review"
                />
              )}
            </>
          )}
        </div>
      </div>
      <InformationBox
        buttonText="News page"
        description="Keep up with new features and changes to the developer portal."
        illustration={Inbox}
        title="Check out our news page"
      />
    </HeroPage>
  );
}
