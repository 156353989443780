import { Button, Flex, Icon, P } from '@dnb/eufemia';
import {
  check as CheckIcon,
  close as CloseIcon,
  exclamation_circled as ExclamationCircled,
  exclamation_triangle as ExclamationTriangleIcon,
} from '@dnb/eufemia/icons';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';

import style from './index.module.css';

const variants = {
  success: { style: 'toast-success', icon: <CheckIcon /> },
  warn: { style: 'toast-warn', icon: <ExclamationTriangleIcon /> },
  error: { style: 'toast-error', icon: <ExclamationCircled /> },
};

const positions = {
  top: 'toast-top',
  'top-left': 'toast-top-left',
  'top-right': 'toast-top-right',
  bottom: 'toast-bottom',
  'bottom-left': 'toast-bottom-left',
  'bottom-right': 'toast-bottom-right',
};

type Position = keyof typeof positions;
type Variant = keyof typeof variants;

interface ToastProps {
  position?: Position;
  variant?: Variant;
  message: string;
  duration?: number;
}
export default function Toast({
  position = 'bottom',
  variant = 'success',
  duration = 5000,
  message,
}: ToastProps) {
  const [visible, setVisible] = useState(true);

  const onClose = useCallback(() => setVisible(false), []);

  useEffect(() => {
    if (visible) {
      setTimeout(() => setVisible(false), duration);
    }
  }, [duration, visible]);

  const { style: _variant, icon } = variants[variant];

  const animateFrom = position.startsWith('bottom') ? 'bottom' : 'top';
  return (
    <motion.div
      animate={{ [animateFrom]: 10, opacity: visible ? 1 : 0 }}
      className={classNames(
        style[`toast`],
        style[positions[position]],
        style[_variant],
      )}
      initial={{ opacity: 0, [animateFrom]: -40 }}
      transition={{
        duration: 1,

        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <Flex.Container
        align="center"
        direction="horizontal"
        justify="space-between"
        stretch
        wrap={false}
      >
        <Flex.Horizontal alignSelf="baseline" left="x-small" stretch>
          <Icon icon={icon} size="medium" />
          <P className={style['message']}>{message}</P>
        </Flex.Horizontal>

        <Flex.Item>
          <Button
            custom_content={
              <Icon color="white" icon={<CloseIcon />} size="medium" />
            }
            onClick={onClose}
            style={{
              color: variant === 'warn' ? 'var(--color-black-80)' : 'white',
            }}
            variant="tertiary"
          />
        </Flex.Item>
      </Flex.Container>
    </motion.div>
  );
}
