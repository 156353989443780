import { Icon } from '@dnb/eufemia';
import {
  chevron_down_medium as ChevronDownIcon,
  chevron_right_medium as ChevronRightIcon,
} from '@dnb/eufemia/icons';
import type { OpenAPIV3 } from 'openapi-types';

import type {
  LinkType,
  SelectionType,
  TagType,
} from '@/pages/api-documentation/constants/types';

import { Action } from '@/pages/api-documentation/styles/Api.styles';

type MenuProps = {
  paths: OpenAPIV3.PathsObject;
  navigateTo: (hash: string, replace: boolean, jump: boolean) => void;
  menuItems: Record<string, LinkType[]> | undefined;
  components: OpenAPIV3.ComponentsObject | undefined;
  tags?: TagType[];
  search?: string[];
  mobile: boolean;
  selected?: SelectionType;
  compact: boolean;
};

interface ItemProps extends LinkType {
  key: string;
  action: string;
  className: string;
}

const Menu = ({
  selected,
  navigateTo,
  menuItems,
  search,
  components,
  tags,
  mobile,
  paths,
  compact,
}: MenuProps) => {
  const renderItem = (item: ItemProps) => {
    return item ? (
      <li className={item.className} key={`nav-${item.key}`}>
        <a
          onClick={() => {
            navigateTo(
              `#tags/${item.action ? item.action + '/' : ''}operation/${
                item.path
              }`,
              false,
              true,
            );
          }}
        >
          <Action small type={item.operator}>
            {item.operator}
          </Action>
          <div>
            {compact
              ? '/' + item.path.split('/').slice(1).join('/')
              : item.name || item.path}
          </div>
        </a>
      </li>
    ) : null;
  };

  return (
    <div>
      {(search !== undefined && (
        <div>
          {menuItems && (
            <ul>
              {Object.keys(menuItems).map((key) => (
                <>
                  {menuItems[key].map((sub) => {
                    return search.includes(sub.path)
                      ? renderItem({
                          ...sub,
                          key: `${key}/operator/${sub.path}`,
                          action: key,
                          className: `${
                            selected?.operation === sub.path ? 'active' : ''
                          }`,
                        })
                      : null;
                  })}
                </>
              ))}
            </ul>
          )}
        </div>
      )) || (
        <>
          <ul className="introduction">
            <li>
              <a
                onClick={() => {
                  navigateTo(`#introduction`, false, true);
                }}
              >
                Introduction
              </a>
            </li>
          </ul>

          <ul>
            <li className="header">REFERENCE</li>
            {components?.securitySchemes && (
              <>
                <li
                  className={
                    ('security-schemes' === selected?.category && 'active') ||
                    ''
                  }
                  key="left-ref-security-schemes"
                >
                  <a
                    onClick={() => {
                      navigateTo('#security-schemes', false, true);
                    }}
                  >
                    Security schemes
                  </a>
                  <Icon
                    color="var(--color-sea-green)"
                    icon={
                      ('security-schemes' === selected?.category &&
                        ChevronDownIcon) ||
                      ChevronRightIcon
                    }
                  />
                </li>
                {Boolean(
                  'security-schemes' === selected?.category || mobile,
                ) && (
                  <>
                    {Object.keys(components?.securitySchemes).map(
                      (securitySchema) => (
                        <li
                          className={`sub ${
                            selected?.operation === securitySchema && 'active'
                          }`}
                          key={`nav-security-schemes/${securitySchema}`}
                        >
                          <a
                            onClick={() => {
                              navigateTo(
                                `#security-schemes/${securitySchema}`,
                                false,
                                true,
                              );
                            }}
                          >
                            <div>{securitySchema}</div>
                          </a>
                        </li>
                      ),
                    )}
                  </>
                )}
              </>
            )}
            {tags && (
              <>
                {tags.map((link) => (
                  <>
                    <li
                      className={
                        (link.href === selected?.category && 'active') || ''
                      }
                      key={`left-ref-${link}`}
                    >
                      <a
                        onClick={() => {
                          navigateTo(`#tags/${link.href}`, false, true);
                        }}
                      >
                        {link.name}
                      </a>
                      <Icon
                        color="var(--color-sea-green)"
                        icon={
                          (link.href === selected?.category &&
                            ChevronDownIcon) ||
                          ChevronRightIcon
                        }
                      />
                    </li>

                    {Boolean(link.href === selected?.category || mobile) &&
                      menuItems &&
                      menuItems[link.href] && (
                        <>
                          {menuItems[link.href].map((sub) => (
                            <>
                              {renderItem({
                                ...sub,
                                key: `${link.href}/operator/${sub.path}`,
                                action: link.href,
                                className: `${
                                  selected?.operation === sub.path
                                    ? 'active'
                                    : ''
                                }`,
                              })}
                            </>
                          ))}
                        </>
                      )}
                  </>
                ))}
                {tags?.length !== 0 && paths && menuItems && (
                  <>
                    {Object.keys(menuItems).map((key) => {
                      return key.length === 0
                        ? menuItems[''].map((sub) => {
                            return renderItem({
                              ...sub,
                              key: `${key}/operator/${sub.path}`,
                              action: key,
                              className: `${
                                selected?.operation === sub.path ? 'active' : ''
                              }`,
                            });
                          })
                        : null;
                    })}
                  </>
                )}
              </>
            )}
            {Boolean(!tags || tags.length === 0) && paths && menuItems && (
              <>
                {menuItems[Object.keys(menuItems)[0]].map((sub) => (
                  <>
                    {renderItem({
                      ...sub,
                      key: `${Object.keys(menuItems)[0]}/operator/${sub.path}`,
                      action: Object.keys(menuItems)[0],
                      className: `${
                        selected?.operation === sub.path ? 'active' : ''
                      }`,
                    })}
                  </>
                ))}
              </>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default Menu;
