import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from '@/components/Container';
import HeroPage from '@/components/HeroPage';
import PageMarkdownContent from '@/components/PageMarkdownContent';
import useFeatureFlags from '@/hooks/useFeatureFlags';

export default function GettingsStartedAPIConsumers() {
  const navigate = useNavigate();
  const { featureFlags } = useFeatureFlags();
  //TODO: remove this when featureflag is disabled and is set as default
  useEffect(() => {
    if (!featureFlags.ENABLE_NEW_REDESIGN) {
      navigate('/404', { replace: true });
    }
  }, [featureFlags.ENABLE_NEW_REDESIGN, navigate]);
  return (
    <HeroPage
      heroIllustration={require('@/illustrations/DNB_Supergraphics_front_page.avif?url')}
      noContainer
      subtitle="Follow these steps to start consuming APIs"
      title="Getting started for DNB internal API consumers"
    >
      <Container centered>
        <PageMarkdownContent
          path={`/content/tpp/${DEVELOPER_PORTAL_TEAM_ID}/consuming-apis`}
        />
      </Container>
    </HeroPage>
  );
}
