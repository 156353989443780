import { Icon } from '@dnb/eufemia';
import { IconIcon } from '@dnb/eufemia/components/Icon';
import classNames from 'classnames';
import type { PropsWithChildren } from 'react';

import style from './index.module.css';
export type AlertBannerProps = PropsWithChildren<{
  type?: 'warning' | 'error';
  icon?: IconIcon;
}>;

export default function AlertBanner({
  type = 'warning',
  icon,
  children,
}: AlertBannerProps): JSX.Element {
  const iconColor =
    type === 'warning' ? 'var(--color-accent-yellow)' : 'var(--color-fire-red)';
  return (
    <div className={classNames(style['Container'], style[`Type--${type}`])}>
      {icon && (
        <Icon color={iconColor} icon={icon} right="small" size="large" />
      )}
      {children}
    </div>
  );
}
