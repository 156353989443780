import { useEffect, useRef } from 'react';

const useEnterPress = (callback: (e: KeyboardEvent) => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleEnterPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && ref.current) {
        callback(event);
      }
    };
    document.addEventListener('keyup', handleEnterPress);

    return () => {
      document.removeEventListener('keyup', handleEnterPress);
    };
  }, [callback]);

  return ref;
};

export default useEnterPress;
