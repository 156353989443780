import { boolean, object, string, z } from 'zod';

import { UUID_MATCHER } from '../common/matchers';
import { INVALID_UUID } from '../common/messages';
export interface ApiEnvironmentDto {
  id: string;
  apiId: string;
  createdAt: string;
  updatedAt: string;
  slug: string;
  name: string;
  isDefault: boolean;
  isInternal: boolean;
}

export const apiEnvironmentInputSchema = object({
  id: string().regex(UUID_MATCHER, INVALID_UUID).optional(),
  apiId: string().regex(UUID_MATCHER, INVALID_UUID).optional(),
  slug: string().min(1),
  name: string().min(1),
  isInternal: boolean(),
  isDefault: boolean(),
});

export type ApiEnvironmentInputDto = z.infer<typeof apiEnvironmentInputSchema>;
