import { Card, H3, Link, P, Section, Space, Switch } from '@dnb/eufemia';
import {
  email_medium as EmailIcon,
  shield_lock_medium as ShieldLockIcon,
} from '@dnb/eufemia/icons';
import type { ApiWithStatusAndScopesDto } from '@portals/shared/portal/ApiDto';
import type { ChangelogDto } from '@portals/shared/portal/ChangelogDto';
import type { ApiSubscribersDto } from '@portals/shared/portal/SubscriptionDto';
import { Dashboard } from '@portals/shared-frontend/components';
import { useLocalStorage } from '@portals/shared-frontend/hooks';
import { anyTrue } from '@portals/shared-frontend/utils';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import Divider from '@/components/Divider';
import Markdown from '@/components/Markdown';
import Page from '@/components/Page';

import AdditionalInformation from '../components/AdditionalInformation';
import ApiDetails from '../components/ApiDetails';
import PublishApiSection from '../components/PublishApiSection';
import ScopeCard from '../components/ScopeCard';
import ScopeRequestCard from '../components/ScopeRequestCard';
import { SubscribersContainer } from '../components/SubscribersContainer';
import { enableApiFaq, enableApiStatus, enableChangelogs } from './constants';

export default function ApiStatistics() {
  const { appId, apiId } = useParams();

  const [displayHelpText, setDisplayHelpText] = useLocalStorage(
    'displayHelpText',
    false,
  );

  const { data: api, isValidating: loadingApi } =
    useSWR<ApiWithStatusAndScopesDto>(`/apps/${appId}/apis/${apiId}`);

  const { data: dashboard, isValidating: loadingDashboard } = useSWR(
    api?.stage === 'launched' ? `/dashboard/apps/${appId}/apis/${apiId}` : null,
  );
  const { data: changelogs, isValidating: loadingChangelogs } = useSWR<
    ChangelogDto[]
  >(api ? `/api/${api.slug}/environments/@default/changelogs` : null);

  const { data: subs, isValidating: loadingSubscribers } =
    useSWR<ApiSubscribersDto>(api ? `/subscriptions/apis/${api.id}` : null);

  if (!api) {
    return null;
  }

  const isLoading = anyTrue(
    loadingDashboard,
    loadingApi,
    loadingChangelogs,
    loadingSubscribers,
  );

  return (
    <Page
      description={
        <ApiDetails
          apiClassification={api.classification}
          apiDescription={api.description}
          slug={api.slug}
          stage={api.stage}
        />
      }
      skeleton={isLoading}
      styleType="black-3"
      title={api.name}
    >
      <BackButton bottom="x-large" to="./../..">
        Back to app page
      </BackButton>

      {api.stage === 'launched' ? (
        <Dashboard data={dashboard ?? []} />
      ) : (
        <PublishApiSection api={api} appId={appId!} />
      )}

      <Section
        backgroundColor="black-3"
        innerSpace={{
          left: 'large',
          right: 'large',
          bottom: 'large',
          top: 'large',
        }}
      >
        <H3 bottom="small">
          <ShieldLockIcon /> Additional information
        </H3>
        <Card stack>
          <Switch
            bottom="small"
            checked={Boolean(displayHelpText)}
            label="Display help message"
            onChange={(e) => setDisplayHelpText(e.checked)}
          />

          <AdditionalInformation
            content="API is attachable"
            displayHelpText={displayHelpText}
            statusMessage={<StatusMessage />}
            value={api.attachable}
          />
          <Divider />
          <AdditionalInformation
            content="Access management enabled"
            displayHelpText={displayHelpText}
            statusMessage={<StatusMessage />}
            value={api.enableServiceNowRequest}
          />
          <Divider />

          <AdditionalInformation
            codeBlock={
              <Markdown>
                {enableChangelogs(api.slug, appId!, '@default', '@latest')}
              </Markdown>
            }
            content="Changelogs enabled"
            displayHelpText={displayHelpText}
            statusMessage={
              <StatusMessage
                context="Changelogs"
                helpMessage={false}
                link="https://developer.dnb.no/documentation/developer-portal-api/prod/@latest/reference#tags/Changelog"
              />
            }
            value={!!(changelogs && changelogs.length > 0)}
          />

          <Divider />

          <AdditionalInformation
            codeBlock={<Markdown>{enableApiStatus(api.slug, appId!)}</Markdown>}
            content="Status reporting enabled"
            displayHelpText={displayHelpText}
            statusMessage={
              <StatusMessage
                context="status reporting"
                helpMessage={false}
                link="https://developer.dnb.no/documentation/developer-portal-api/prod/@latest/reference#tags/APIs/operation/patch/apis/api-slug"
              />
            }
            value={api.enableStatus}
          />
          <Divider />

          <AdditionalInformation
            codeBlock={<Markdown>{enableApiFaq(api.slug, appId!)}</Markdown>}
            content="FAQ enabled"
            displayHelpText={displayHelpText}
            statusMessage={
              <StatusMessage
                context="FAQ"
                helpMessage={false}
                link="https://developer.dnb.no/documentation/developer-portal-api/prod/@latest/guide"
              />
            }
            value={api.enableFaq}
          />
        </Card>
      </Section>

      <Section
        backgroundColor="black-3"
        innerSpace={{ left: 'large', right: 'large', bottom: 'large' }}
      >
        <H3 bottom="small">
          <ShieldLockIcon /> Scopes
        </H3>
        <Card direction="vertical">
          {api.scopes && api.scopes.length > 0 ? (
            api.scopes?.map((scope, index) => (
              <Space key={scope.id} stretch top={index === 0 ? 0 : 'small'}>
                <ScopeCard
                  environments={[]}
                  scopeEntitlement={{
                    scopeId: scope.id,
                    scopeName: scope.name,
                    scopeDescription: scope.description,
                    liveMode: false,
                  }}
                  showAccessSection={false}
                />
              </Space>
            ))
          ) : (
            <P>Scopes has not been created for API.</P>
          )}
        </Card>
      </Section>

      <Section
        backgroundColor="black-3"
        innerSpace={{ left: 'large', right: 'large', bottom: 'large' }}
      >
        <H3 bottom="small">
          <ShieldLockIcon /> Scope requests
        </H3>
        <Card direction="vertical">
          {api.scopeRequests && api.scopeRequests.length > 0 ? (
            api.scopeRequests.map((request, index) => (
              <Space key={request.id} stretch top={index === 0 ? 0 : 'small'}>
                <ScopeRequestCard scopeRequest={request} />
              </Space>
            ))
          ) : (
            <P>Scopes have not been requested for this API.</P>
          )}
        </Card>
      </Section>

      <Section
        backgroundColor="black-3"
        innerSpace={{ left: 'large', right: 'large', bottom: 'large' }}
      >
        <H3 bottom="small">
          <EmailIcon /> Subscribers
        </H3>
        <SubscribersContainer
          subscribers={subs?.releaseSubscribers ?? []}
          title={`Subscribers to new releases: ${subs?.releaseSubscribers?.length}`}
        />
        <Space top="small" />
        <SubscribersContainer
          subscribers={subs?.statusSubscribers ?? []}
          title={`Subscribers to status updates: ${subs?.statusSubscribers?.length}`}
        />
      </Section>
    </Page>
  );
}

type StatusMessageProps = {
  link?: string;
  context?: string;
  helpMessage?: boolean;
};
function StatusMessage({
  link,
  context,
  helpMessage = true,
}: StatusMessageProps) {
  return helpMessage ? (
    <>
      Contact the portals team to get started. You can reach them on the
      following slack channel:{' '}
      <Link
        href="https://dnb.enterprise.slack.com/archives/CDXF91B7E"
        target="_blank"
      >
        #developer-portal-help
      </Link>
    </>
  ) : (
    <>
      Get started with {context} for your API, by using the{' '}
      <Link href={link} target="_blank">
        developer portal API
      </Link>
    </>
  );
}
