import type { ErrorDto } from '@portals/shared/common/ErrorDto';
import { hasOwnProperty } from '@portals/shared-frontend/utils';

import { getAzureToken, isSignedInWithAzure } from './auth/azure';
import { getCognitoIdToken, isLoggedInWithCognito } from './auth/cognito';

type Method = 'get' | 'post' | 'put' | 'patch' | 'delete';

export class ApiError extends Error {
  constructor(public status: number, public body: ErrorDto) {
    super(body.message);
  }

  static isApiError(error: unknown): error is ApiError {
    return hasOwnProperty(error, 'body') && hasOwnProperty(error, 'status');
  }
}

export async function request<T>(
  url: string,
  method: Method,
  body?: object,
): Promise<T> {
  const headers = new Headers();

  if (isSignedInWithAzure()) {
    const token = await getAzureToken();

    if (!token) {
      throw new Error('Failed to fetch token');
    }

    headers.set('Authorization', `Bearer ${token}`);
  } else if (await isLoggedInWithCognito()) {
    const token = await getCognitoIdToken();

    if (!token) {
      throw new Error('Failed to fetch token');
    }

    headers.set('Authorization', `Bearer ${token}`);
  }

  const result = await fetch(`/service${url}`, {
    method: method.toUpperCase(),
    headers,
    body: JSON.stringify(body),
  });

  if (result.status > 299) {
    let body: ErrorDto;

    try {
      body = await result.json();
    } catch {
      throw new Error('Unknown error');
    }

    throw new ApiError(result.status, body);
  }

  return result.status === 204 ? null : result.json();
}
