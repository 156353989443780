import { Button, H2, Img, Section, Space } from '@dnb/eufemia';
import { Link as RouterLink } from 'react-router-dom';

import Container from '@/components/Container';
import Footer from '@/components/Footer';
import MenuBar from '@/components/MenuBar';

import style from './LoginFailure.module.css';

import WomanSearchingLight from '@/illustrations/WomanSearchingLight.svg?url';

export default function LoginFailure(): JSX.Element {
  return (
    <>
      <Section style_type="white">
        <MenuBar />
      </Section>
      <Container
        bottom="xx-large large"
        centered
        className={style['Content']}
        size="small"
        top="xx-large large"
      >
        <Img
          alt="A woman searching in a box"
          src={WomanSearchingLight}
          width={320}
        />
        <H2 top="large">Login failed, please try again.</H2>
        <Space className={style['Buttons']}>
          <Button element={RouterLink} size="large" to="/">
            Go to landing page
          </Button>
          <Button
            className={style['SecondaryButton']}
            element={RouterLink}
            size="large"
            to="/login"
            variant="secondary"
          >
            Go back to login
          </Button>
        </Space>
      </Container>
      <Footer />
    </>
  );
}
