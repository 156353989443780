import { Div, FormStatus, P, Span } from '@dnb/eufemia';
import { check as CheckIcon, close as CloseIcon } from '@dnb/eufemia/icons';
import type { ReactNode } from 'react';

import style from './index.module.css';

type AdditionalInformationProps = {
  content: string;
  displayHelpText: boolean;
  value: boolean;
  statusMessage: ReactNode;
  codeBlock?: ReactNode;
};
export default function AdditionalInformation({
  codeBlock,
  content,
  displayHelpText,
  value,
  statusMessage,
}: AdditionalInformationProps) {
  return (
    <div className={style['Container']}>
      <div className={style['CardRow']}>
        <P>{content}</P>
        <P>
          <Span right="small">{value ? <CheckIcon /> : <CloseIcon />}</Span>
          {value ? 'Yes' : 'No'}
        </P>
      </div>
      {displayHelpText && !value && (
        <Div className={style['CodeBlock']} top="small">
          <FormStatus state="info">{statusMessage}</FormStatus>
          {codeBlock && (
            <>
              <P top="small">Example request</P>
              {codeBlock}
            </>
          )}
        </Div>
      )}
    </div>
  );
}
