import { Flex } from '@dnb/eufemia';
import { EventDto } from '@portals/shared/portal/EventDto';

import EventCard from '@/pages/explorer/events/EventCard';

interface EventListProps {
  events: EventDto[];
}

export default function EventList({ events }: EventListProps) {
  return (
    <Flex.Stack>
      {events.map((event) => (
        <EventCard event={event} key={event.slug} />
      ))}
    </Flex.Stack>
  );
}
