import { PropsWithChildren } from 'react';

import HeroPage from '@/components/HeroPage';

import style from './index.module.css';

type ServicePageProps = PropsWithChildren<{
  title: string;
}>;

export default function ServicePage({ title, children }: ServicePageProps) {
  return (
    <HeroPage
      heroIllustration={require('@/illustrations/DNB_Supergraphics_Duo_summer_emerald.avif?url')}
      noContainer
      title={title}
    >
      <div className={style['ServicePageContent']} id="LandingContent">
        <div className={style['TileContainer']}>{children}</div>
      </div>
    </HeroPage>
  );
}
