import { InfoCard, Link, P } from '@dnb/eufemia';
import { Link as RouterLink } from 'react-router-dom';

export default function CommonRepoApiInfoCard(): JSX.Element | null {
  return (
    <InfoCard
      bottom="medium"
      text={
        <>
          <P bottom="small">
            There are apis which are not published in developer portal but are
            available via common gitlab repository.
          </P>
          <Link element={RouterLink} to="/api-contracts">
            Click here to view them.
          </Link>
        </>
      }
      title={
        <>
          <P bottom="small">No APIs match the selected filters.</P>
          <P>Looking for unpublished APIs?</P>
        </>
      }
    />
  );
}
