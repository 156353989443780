import { Icon, P, Space } from '@dnb/eufemia';
import {
  cloud as CloudIcon,
  hourglass as HourglassIcon,
  stop as StopIcon,
} from '@dnb/eufemia/icons';
import { Decision } from '@portals/shared/portal/AppApiEntitlementDto';
import type {
  ReviewStatusType,
  ScopeRequestDto,
} from '@portals/shared/portal/ScopeRequestDto';
import classNames from 'classnames';

import Card from '@/components/Card';
import Divider from '@/components/Divider';
import { humanDatetime } from '@/utils';

import style from './index.module.css';

type ScopeRequestCardProps = {
  scopeRequest: ScopeRequestDto;
};
const getIcon = (status: ReviewStatusType) => {
  switch (status) {
    case Decision.PENDING: {
      return <HourglassIcon />;
    }
    case Decision.APPROVED: {
      return <CloudIcon />;
    }
    case Decision.DENIED: {
      return <StopIcon />;
    }
    default: {
      return <HourglassIcon />;
    }
  }
};

export default function ScopeRequestCard({
  scopeRequest,
}: ScopeRequestCardProps) {
  return (
    <Card>
      <Space bottom="small" className={style['Container']}>
        <div className={style['Container-Scope']}>
          <span
            className={classNames(
              style['Icon'],
              style[`Icon--${scopeRequest.reviewStatus.toLowerCase()}`],
            )}
          >
            <Icon icon={getIcon(scopeRequest.reviewStatus)} />
          </span>
          <div className={style['Container-ScopeDetails']}>
            <P>{scopeRequest.scopeName}</P>
            <P size="x-small">{scopeRequest.scopeDescription}</P>
          </div>
        </div>
        <div>
          <P size="small">Status: {scopeRequest.reviewStatus}</P>
          <P size="small" top="x-small">
            Type: {scopeRequest.requestType}
          </P>
        </div>
      </Space>

      <Divider />
      <Space className={style['Container']} top="small">
        {scopeRequest.reviewStatus === Decision.DENIED && (
          <div>
            <P size="x-small">
              Reviewed: {humanDatetime(scopeRequest.reviewedAt)}
            </P>
            <P size="x-small">Reason: {scopeRequest?.reviewComment}</P>
          </div>
        )}
        <P size="x-small">Requested: {humanDatetime(scopeRequest.createdAt)}</P>
      </Space>
    </Card>
  );
}
