export interface EventDto {
  appId: string;
  name: string;
  slug: string;
  classification: EventClassification;
}

export enum EventClassification {
  Domain = 'DOMAIN',
  System = 'SYSTEM',
  Experience = 'EXPERIENCE',
}
