import { Div, Flex } from '@dnb/eufemia';
import { useMedia } from '@dnb/eufemia/shared';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import DeveloperLogo from '../DeveloperLogo';
import MobileBurgerButton from './MobileBurgerButton';
import NavigationButton from './NavigationButton';
import NavigationButtonMenu from './NavigationButtonMenu';

import style from './index.module.css';

export enum openMenu {
  ProfileDropDown = 'ProfileDropDown',
  BurgerNavigationMenu = 'BurgerNavigationMenu',
  none = '',
}

export default function NavigationBar() {
  const { isLarge } = useMedia();
  const [whichMenuIsOpen, setWhichMenuIsOpen] = useState<openMenu>(
    openMenu.none,
  );

  const showProfileMenu = whichMenuIsOpen !== openMenu.BurgerNavigationMenu;
  const showBurgerNavigationMenu = whichMenuIsOpen !== openMenu.ProfileDropDown;
  const showDivider = whichMenuIsOpen !== openMenu.BurgerNavigationMenu;

  return (
    <div className={style['HeaderWrapper']}>
      <Div className={style['NavigationHeader']}>
        <Div className={style['NavigationBar']}>
          <Flex.Container
            align="center"
            className={style['NavigationMenu']}
            justify="center"
          >
            <Div className={style['Logo']}>
              <RouterLink className="dnb-anchor--no-style" to="/">
                <DeveloperLogo />
              </RouterLink>
            </Div>
            {isLarge && <NavigationButtonMenu />}
          </Flex.Container>
          <Div className={style['ActionMenu']}>
            <Div>
              {showProfileMenu && (
                <NavigationButton
                  isProfileMenu
                  setWhichMenuIsOpen={setWhichMenuIsOpen}
                  title="LoginButton"
                />
              )}
            </Div>
            {!isLarge && showBurgerNavigationMenu && (
              <>
                {showDivider && <div className={style['ProfileDivider']} />}
                <MobileBurgerButton setWhichMenuIsOpen={setWhichMenuIsOpen} />
              </>
            )}
          </Div>
        </Div>
      </Div>
    </div>
  );
}
