import { Button, Div } from '@dnb/eufemia';
import { close_medium, hamburger_medium } from '@dnb/eufemia/icons';
import { useState } from 'react';

import useOutsideClick from '@/hooks/useOutsideClick';

import { openMenu } from '..';
import NavigationButtonMenu from '../NavigationButtonMenu';

interface MobileBurgerButton {
  setWhichMenuIsOpen: React.Dispatch<React.SetStateAction<openMenu>>;
}

export default function MobileBurgerButton({
  setWhichMenuIsOpen,
}: MobileBurgerButton) {
  const [open, setOpen] = useState(false);

  const handleClickOutsideDropdown = () => {
    setOpen(false);
    setWhichMenuIsOpen(openMenu.none);
  };

  const ref = useOutsideClick(handleClickOutsideDropdown);

  return (
    <Div ref={ref}>
      <Div>
        <Button
          icon={open ? close_medium : hamburger_medium}
          icon_position="top"
          left
          on_click={() => {
            setOpen(!open);
            open
              ? setWhichMenuIsOpen(openMenu.none)
              : setWhichMenuIsOpen(openMenu.BurgerNavigationMenu);
          }}
          size="large"
          text={open ? 'Close' : 'Menu'}
          variant="tertiary"
        />
      </Div>
      {open && <NavigationButtonMenu />}
    </Div>
  );
}
