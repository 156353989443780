import { useCallback, useRef } from 'react';

type UseReturnTo = [string | null, (url: string) => void, () => void];

export default function useReturnTo(): UseReturnTo {
  const returnTo = useRef(sessionStorage.getItem('returnTo'));

  const clearReturnTo = useCallback(() => {
    sessionStorage.removeItem('returnTo');
    returnTo.current = null;
  }, []);

  const setReturnTo = useCallback((url: string) => {
    sessionStorage.setItem('returnTo', url);
    returnTo.current = url;
  }, []);

  return [returnTo.current, setReturnTo, clearReturnTo];
}
