import { Button, FormRow, FormSet, Input } from '@dnb/eufemia';
import { shield_medium as ShieldMediumIcon } from '@dnb/eufemia/icons';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { verifyEmail } from '@/api/user';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import ModalPage from '@/components/ModalPage';

const verifyEmailFormSchema = z.object({
  code: z.string().min(6).max(6),
});

type VerifyEmailProps = {
  email: string;
};

export default function VerifyEmail({ email }: VerifyEmailProps): JSX.Element {
  const navigate = useNavigate();

  const {
    controller: { formError },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(verifyEmailFormSchema, {});

  const onSubmit = handleSubmit(
    useCallback(
      async ({ code }) => {
        await verifyEmail({ email: email, verificationCode: code });
        navigate('/sign-up/success');
      },
      [email, navigate],
    ),
  );

  return (
    <ModalPage
      description="You have now received an email with a verification code.
  Fill it in below to verify your account and complete sign up."
      subtitle="Sign up"
      title="You got mail!"
    >
      {submitting && <LoadingModal />}
      <Container size="small">
        <FormSet on_submit={onSubmit} vertical>
          <FormRow>
            <Input
              autoFocus
              label="Confirmation code"
              placeholder="xxxxxx"
              size="large"
              stretch
              {...register.input('code')}
            />
          </FormRow>

          <FormRow top="large">
            <Button
              disabled={submitting}
              icon={ShieldMediumIcon}
              icon_position="left"
              icon_size="medium"
              size="large"
              status={formError}
              type="submit"
            >
              Verify account
            </Button>
          </FormRow>
        </FormSet>
      </Container>
    </ModalPage>
  );
}
