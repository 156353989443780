import { literal, object, string, union, z } from 'zod';

import type { ApiEnvironmentDto } from './ApiEnvironmentDto';
import type { OrganizationDto } from './OrganizationDto';
import type { ScopeDto } from './ScopeDto';
import type { ScopeRequestDto } from './ScopeRequestDto';
import type { TeamDto } from './TeamDto';

type ApiStage =
  | 'launched'
  | 'upcoming'
  | 'lab'
  | 'deprecated'
  | 'unpublished'
  | 'review';

export enum SeverityClassification {
  OPEN = 'open',
  INTERNAL = 'internal',
  CONFIDENTIAL = 'confidential',
  STRICTLY_CONFIDENTIAL = 'strictly-confidential',
}

export interface ApiInfoDto {
  id: string;
  slug: string;
  ordering: number;
  description: string | null;
  availableInLiveMode: boolean;
  attachable: boolean;
  name: string;
  icon: string;
  stage: ApiStage;
  approvalStage: 'test' | 'live' | 'public';
  documentationAnchor: string | null;
  restricted: boolean;
  productPageUrl: string | null;
  enableStatus: boolean;
  isPublic: boolean;
  classification:
    | 'system'
    | 'domain'
    | 'experience'
    | 'technical'
    | 'public'
    | 'partner';
  tags: string[];
  termsOfUse: string | null;
  termsOfUseUpdatedAt: number | null;
  deprecated: boolean;
  accessTypes?: Array<'API_KEYS' | 'O_AUTH_SYSTEM_TO_SYSTEM'> | null;
  serviceNowIdentifier: string | null;
  enableServiceNowRequest: boolean | null;
  piiExposed: boolean | null;
  gdprCompliant: boolean | null;
  severityClassification: SeverityClassification | null;
}

export interface ApiDto {
  id: string;
  slug: string;
  ordering: number;
  description: string | null;
  availableInLiveMode: boolean;
  attachable: boolean;
  name: string;
  icon: string;
  stage: ApiStage;
  approvalStage: 'test' | 'live' | 'public';
  documentationAnchor: string | null;
  restricted: boolean;
  productPageUrl: string | null;
  enableStatus: boolean;
  isPublic: boolean;
  classification:
    | 'system'
    | 'domain'
    | 'experience'
    | 'technical'
    | 'public'
    | 'partner';
  tags: string[];
  environments: ApiEnvironmentDto[];
  termsOfUse: string | null;
  termsOfUseUpdatedAt: number | null;
  organization: OrganizationDto | null;
  teams: TeamDto[];
  currentVersion: string | null;
  upcomingVersion: string | null;
  deprecated: boolean;
  scopes?: ScopeDto[];
  accessTypes?: Array<'API_KEYS' | 'O_AUTH_SYSTEM_TO_SYSTEM'> | null;
  serviceNowIdentifier: boolean;
  enableServiceNowRequest: boolean;
  enableFaq: boolean;
  piiExposed: boolean | null;
  severityClassification: SeverityClassification | null;
  isFavorite?: boolean;
  isOwner?: boolean;
}

export interface ApiWithStatusDto
  extends Omit<
    ApiDto,
    | 'environments'
    | 'organization'
    | 'teams'
    | 'currentVersion'
    | 'upcomingVersion'
    | 'scopes'
  > {
  grantStatus: string | null;
  useCase: string | null;
  attached: boolean;
}

export interface ApiWithStatusAndScopesDto
  extends Omit<
    ApiDto,
    | 'environments'
    | 'organization'
    | 'teams'
    | 'currentVersion'
    | 'upcomingVersion'
  > {
  grantStatus: string | null;
  useCase: string | null;
  attached: boolean;
  scopeRequests: ScopeRequestDto[];
}

export const uploadPublicKeyInputSchema = object({
  mode: union([literal('sandbox'), literal('production')]),
  publicKey: string().min(1),
});

export type UploadPublicKeyInputDto = z.infer<
  typeof uploadPublicKeyInputSchema
>;

export interface ApiPlainDto {
  id: string;
  slug: string;
  ordering: number;
  description: string | null;
  availableInLiveMode: boolean;
  attachable: boolean;
  name: string;
  icon: string;
  stage: ApiStage;
  approvalStage: 'test' | 'live' | 'public';
  documentationAnchor: string | null;
  productPageUrl: string | null;
  enableStatus: boolean;
  isPublic: boolean;
  classification:
    | 'system'
    | 'domain'
    | 'experience'
    | 'technical'
    | 'public'
    | 'partner';
  tags: string[];
  deprecated: boolean;
  accessTypes?: Array<'API_KEYS' | 'O_AUTH_SYSTEM_TO_SYSTEM'> | null;
  serviceNowIdentifier: boolean;
  enableServiceNowRequest: boolean;
}

export const OAuthIntegrationInfoSchema = z.object({
  serviceNowIdentifier: string().min(3).max(255),
  severityClassification: string().and(
    z.enum([
      SeverityClassification.CONFIDENTIAL,
      SeverityClassification.INTERNAL,
      SeverityClassification.OPEN,
      SeverityClassification.STRICTLY_CONFIDENTIAL,
    ]),
  ),
  piiExposed: z.boolean(),
  gdprCompliant: z.boolean(),
  approvalStageTest: z.boolean().default(false),
  approvalStageLive: z.boolean().default(true),
});

export type ServiceNowInfoInputDto = z.infer<typeof OAuthIntegrationInfoSchema>;

export const upsertApiFavouriteInputSchema = object({
  isFavourite: z.boolean(),
});

export type upsertApiFavouriteInputDto = z.infer<
  typeof upsertApiFavouriteInputSchema
>;
