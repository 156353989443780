import { Button, Dropdown, P, Space } from '@dnb/eufemia';
import { email as MailIcon } from '@dnb/eufemia/icons';
import { useNavigate } from 'react-router-dom';

import type { DropdownOption } from '../constants/types';

import { Environment } from '../styles/Api.styles';

export interface HeaderType {
  environments?: DropdownOption[];
  releases?: DropdownOption[];
  selectedVersion: string;
  selectedEnvironment: string;
  setEnv: (type: 'environment' | 'release', key: string) => void;
  selected?: string;
  description?: string;
  canSubscribe: boolean;
}

const Header = ({
  environments,
  selectedEnvironment,
  releases,
  selectedVersion,
  setEnv,
  selected,
  description,
  canSubscribe,
}: HeaderType) => {
  const navigate = useNavigate();

  return (
    <>
      <P>{description}</P>
      {canSubscribe && (
        <Space bottom="medium" stretch top="medium">
          <Button
            icon={MailIcon}
            icon_position="left"
            on_click={() => navigate(`/subscriptions/releases/${selected}`)}
          >
            Subscribe to releases
          </Button>
        </Space>
      )}
      <Space stretch top="small">
        <Environment>
          {environments?.length !== 0 && (
            <Dropdown
              data={environments}
              on_change={({ data }) => {
                window.location.hash = '';
                setEnv('environment', data.key);
              }}
              title="Production"
              value={environments?.findIndex(
                (option) => option.key === selectedEnvironment,
              )}
            />
          )}

          {releases?.length !== 0 && (
            <Dropdown
              data={releases}
              on_change={({ data }) => {
                window.location.hash = '';
                setEnv('release', data.key);
              }}
              value={releases?.findIndex(
                (option) => option.key === selectedVersion,
              )}
            />
          )}
        </Environment>
      </Space>
    </>
  );
};

export default Header;
