import ApiPage from '../../components/ApiPage';
import { API_EXPLORER_TABS } from '../../content';

export default function InternalApis() {
  return (
    <ApiPage
      apiType={API_EXPLORER_TABS.Internal}
      showClassificationFilter
      title="Internal APIs"
    />
  );
}
