import { PUBLISHED_STATUS } from '@portals/shared/admin/ContentDto';
import type { ContentDto } from '@portals/shared/portal/ContentDto';
import { z } from 'zod';

export interface ContentPage extends ContentDto {
  children: ContentPage[];
}

export enum VISIBLE_TO {
  TEAM = 'TEAM',
  PUBLIC = 'PUBLIC',
  DNB = 'DNB',
}

export const schema = z.object({
  title: z.string().min(1),
  content: z.string().min(1),
  publishedStatus: z.nativeEnum(PUBLISHED_STATUS),
  visibleTo: z.nativeEnum(VISIBLE_TO),
});

export type Action = 'add-root' | 'edit' | 'add-child' | 'edit-main';

export function mapFromVisibility(
  visibility: VISIBLE_TO,
  organizationId?: string | null,
  teamId?: string,
) {
  switch (visibility) {
    case VISIBLE_TO.DNB: {
      return { organizationId };
    }
    case VISIBLE_TO.TEAM: {
      return { teamId };
    }
    case VISIBLE_TO.PUBLIC: {
      return { isPublic: true };
    }
    default: {
      return { teamId };
    }
  }
}
export function mapToVisibility(activePage?: ContentPage) {
  if (!activePage) {
    return VISIBLE_TO.TEAM;
  }
  if (activePage.isPublic) {
    return VISIBLE_TO.PUBLIC;
  } else if (activePage.organizations.length > 0) {
    return VISIBLE_TO.DNB;
  } else {
    return VISIBLE_TO.TEAM;
  }
}

export const LAST_SEGMENT_IN_PATH = /[^/]+$/;
export const organizePages = (pages: ContentDto[]): ContentPage[] => {
  const findRootPages = (): ContentPage[] => {
    const rootPages = pages.filter((page) => page.parentId === null);
    return rootPages.map((rootPage) => {
      const children = organizePagesHelper(rootPage.id);
      return { ...rootPage, children };
    });
  };

  const organizePagesHelper = (parentId: string): ContentPage[] => {
    const children = pages
      .filter((page) => page.parentId === parentId)
      .map((childPage) => {
        const nestedChildren = organizePagesHelper(childPage.id);
        return { ...childPage, children: nestedChildren };
      });

    return children.length > 0 ? children : [];
  };

  return findRootPages();
};
