import type { OpenAPIV3 } from 'openapi-types';

import { DereferencedSchemaObject } from '@/pages/api-documentation/constants/types';

import SchemaItem from '../Schema/components/SchemaItem';

import * as Styled from './index.styles';
import { H4S } from '@/pages/api-documentation/styles/Api.Reference.styles';

type ParametersProps = {
  data: OpenAPIV3.ParameterObject[];
};

const Parameters = ({ data }: ParametersProps) => {
  const hasHead = data.filter((item) => item.in === 'header');
  const hasPath = data.filter((item) => item.in === 'path');
  const hasQuery = data.filter((item) => item.in === 'query');
  const hasCookie = data.filter((item) => item.in === 'cookie');

  return (
    <>
      {hasHead?.length !== 0 && (
        <>
          <H4S>Header parameters</H4S>
          <Styled.ParameterList>
            {hasHead.map((item, index) => (
              <li key={`path-param-${item?.name}-${index}`}>
                <SchemaItem
                  isSubSchema
                  name={item?.name}
                  required={item?.required}
                  schema={
                    {
                      ...item.schema,
                      description: item?.description,
                    } as DereferencedSchemaObject
                  }
                  schemaType="request"
                />
              </li>
            ))}
          </Styled.ParameterList>
        </>
      )}
      {hasPath?.length !== 0 && (
        <>
          <H4S>Path parameters</H4S>
          <Styled.ParameterList>
            {hasPath.map((item) => (
              <li key={`path-param-${item?.name}`}>
                <SchemaItem
                  isSubSchema
                  name={item?.name}
                  required={item?.required}
                  schema={
                    {
                      ...item.schema,
                      description: item?.description,
                    } as DereferencedSchemaObject
                  }
                  schemaType="request"
                />
              </li>
            ))}
          </Styled.ParameterList>
        </>
      )}
      {hasQuery?.length !== 0 && (
        <>
          <H4S>Query parameters</H4S>
          <Styled.ParameterList>
            {hasQuery.map((item) => (
              <li key={`query-param-${item?.name}`}>
                <SchemaItem
                  isSubSchema
                  name={item?.name}
                  required={item?.required}
                  schema={
                    {
                      ...item.schema,
                      description: item?.description,
                    } as DereferencedSchemaObject
                  }
                  schemaType="request"
                />
              </li>
            ))}
          </Styled.ParameterList>
        </>
      )}
      {hasCookie?.length !== 0 && (
        <>
          <H4S>Cookie parameters</H4S>
          <Styled.ParameterList>
            {hasCookie.map((item) => (
              <li key={`query-param-${item?.name}`}>
                <SchemaItem
                  isSubSchema
                  name={item?.name}
                  required={item?.required}
                  schema={
                    {
                      ...item.schema,
                      description: item?.description,
                    } as DereferencedSchemaObject
                  }
                  schemaType="request"
                />
              </li>
            ))}
          </Styled.ParameterList>
        </>
      )}
    </>
  );
};

export default Parameters;
